// Import package components
import { createContext, useState } from "react";
import { useCookies } from "react-cookie";

import AccessoryService from "../services/accessory.service";

export const AccessoryContext = createContext();
export const AccessoryProvider = ({ children }) => {
  const [isNew, setIsNew] = useState(false);
  const [accessory, setAccessory] = useState(undefined);
  const [accessories, setAccessories] = useState([]);
  const [selectedTab, setSelectedTab] = useState(undefined);
  const [tags, setTags] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isProcessing, setProcessing] = useState(false);

  const contextAccessory = {
    isNew,
    setIsNew,
    accessory,
    setAccessory,
    selectedTab,
    setSelectedTab,
    total,
    accessories,
    tags,
    loading,
    isProcessing,
    setProcessing,

    initAccessory: () => {
      setAccessory({
        account_id: 0,
        label: "",
        category: "",
        description: "",
        specifications: "",
        price: "",
        availability: "99999",
        date_required: false,
        quantity_required: false,
        quantity_minimum: -1,
        quantity_maximum: -1,
        special_instructions_required: false,
        special_instructions: "",
        variants: [],
        ordering: [],
        attachments: [],
        tags: [],
      });
    },

    retrieve: async () => {
      setProcessing(true);
      try {
        const res = await AccessoryService.retrieve();
        setAccessories(res.data);
        setProcessing(false);
        return res.data;
      } catch (err) {
        console.log(err);
        setProcessing(false);
      }
    },

    insertAccessory: async (obj) => {
      setProcessing(true);
      return AccessoryService.insertAccessory(obj)
        .then((res) => {
          setProcessing(false);
          setAccessories((prev) => [...prev, res.data]);
          return [res.data, null];
        })
        .catch((err) => {
          setProcessing(false);
          return [null, err];
        });
    },

    updateAccessory: async (obj) => {
      setProcessing(true);
      return AccessoryService.updateAccessory(obj)
        .then((res) => {
          setProcessing(false);
          const updatedItem = res.data;
          setAccessory({ ...accessory, ...updatedItem });

          const idx = accessories.findIndex(
            (item) => item.id.toString() === updatedItem.id.toString(),
          );

          if (idx > -1) {
            let updated = [...accessories];
            updated[idx] = updatedItem;
            setAccessories(updated);
          }
          return [res.data, null];
        })
        .catch((err) => {
          setProcessing(false);
          console.log(err);
          return [null, err];
        });
    },

    deleteAccessory: async (id) => {
      setLoading(true);
      return AccessoryService.deleteAccessory(id)
        .then((res) => {
          setAccessories((prev) => prev.filter((item) => item.id !== id));
          setLoading(false);
          return ["success", null];
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          return [null, err];
        });
    },

    updateTabOrder: async (id, ordering) => {
      setLoading(true);
      let resp = await AccessoryService.updateTabOrder(id, ordering);

      return resp.data;
    },
  };

  return (
    <AccessoryContext.Provider value={contextAccessory}>
      {children}
    </AccessoryContext.Provider>
  );
};
