// Import package components
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Grid, Image } from "uikit-react";
import { AutoComplete, Input, Select } from "antd";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import clsx from "clsx";
import * as yup from "yup";
import debounce from "lodash.debounce";
import moment from "moment";
import UIkit from "uikit";

import { AppContexts } from "../../../providers";
import Tags from "../../common/Tags";
import ImagePreview from "../../common/ImagePreview";

const schema = yup.object().shape({
  // description: yup.string().required(),
  summary: yup.string().required(),
  // status: yup.string().required(),
  // priority: yup.string().required(),
});

const WorkOrderAdd = (props) => {
  const { grId, msgId, msg_gr } = props;

  const workOrderContext = useContext(AppContexts.WorkOrderContext);
  const rentalContext = useContext(AppContexts.RentalsContext);
  const reservationContext = useContext(AppContexts.ReservationsContext);
  const utilContext = useContext(AppContexts.UtilContext);

  const { isNew, workOrder, insertWorkOrder, updateWorkOrder, isProcessing } =
    workOrderContext;

  const [guestKey, setGuestKey] = useState(null);
  const [propertyKey, setPropertyKey] = useState(null);

  const [property, setProperty] = useState(null);
  const [guest, setGuest] = useState(null);

  const [assignList, setAssigns] = useState([]);
  const [attachmentList, setAttachments] = useState([]);

  const propertyOptions = rentalContext.rentals.map((rental) => {
    return {
      value: rental.rental_id,
      label: rental.pms_id
        ? `${rental.pms_id}, ${rental.address}`
        : `${rental.address}`,
    };
  });
  const guestOptions = reservationContext.listReservations.map(
    (reservation) => {
      return {
        value: reservation.guest_reservation_id,
        label: `${reservation.first_name} ${reservation.last_name}`,
      };
    }
  );
  const assignerOptions = useMemo(
    () => [
      {
        label: "Staff",
        options: (workOrderContext.attributes.assigners.recipients || [])
          .sort((a, b) => (a.first_name > b.first_name ? 1 : -1))
          .map((recipient) => {
            return {
              label: `${recipient.first_name} ${recipient.last_name}`,
              value: `recipient_${recipient.recipient_id}`,
            };
          }),
      },
      {
        label: "Contact",
        options: (workOrderContext.attributes.assigners.contacts || [])
          .sort((a, b) => (a.first_name > b.first_name ? 1 : -1))
          .map((contact) => {
            return {
              label: `${contact.first_name} ${contact.last_name}`,
              value: `contact_${contact.id}`,
            };
          }),
      },
    ],
    [workOrderContext.attributes]
  );

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    return () => {
      debounceSearchGuest.cancel();
      debounceSearchRental.cancel();
      handleClearForm();
    };
  }, []);

  useEffect(() => {
    if (!isNew && workOrder) {
      const fields = [
        "summary",
        "description",
        "priority",
        "status",
        "guest_reservation_id",
        "rental_id",
        "tags",
        "category",
      ];
      fields.forEach((field) => {
        setValue(field, workOrder[field]);
      });

      const attachments = workOrder?.attachments?.filter(
        (attachment) => attachment.type === "image"
      );
      setAttachments(attachments);

      const assigners = (workOrder?.work_order_assignees || []).map(
        (assigner) => `${assigner.assignee_type}_${assigner.assignee_id}`
      );
      const options = assignerOptions
        .map((obj) => [...obj.options])
        .flat()
        .filter((option) => assigners.indexOf(option.value) > -1);
      setAssigns(options);

      if (workOrder.guest_reservation) {
        setGuest(workOrder.guest_reservation);
      }
      if (workOrder.rental) {
        setProperty(workOrder.rental);
      }
    }

    if (isNew) {
      handleClearForm();
      if (grId) {
        const reservation = reservationContext.listReservations.find(
          (gr) => gr.guest_reservation_id === grId
        );
        setValue("guest_reservation_id", reservation.guest_reservation_id);
        setGuest(reservation);

        if (reservation.rental_id) {
          setValue("rental_id", reservation.rental_id);
          setProperty({
            pms_id: reservation.pms_id,
            name: reservation.rental_name,
            address: reservation.rental_address,
          });
        }
      }

      if (msgId) {
        setValue("message_id", msgId);
        if (msg_gr) {
          setValue("guest_reservation_id", msg_gr.guest_reservation_id);
          setGuest(msg_gr);

          if (msg_gr.rental_id) {
            setValue("rental_id", msg_gr.rental_id);
            setProperty({
              pms_id: msg_gr.pms_id,
              name: msg_gr.rental_name,
              address: msg_gr.pms_address,
            });
          }
        }
      }
    }
  }, [workOrder, isNew, grId, msgId]);

  const convertedGuest = useMemo(() => {
    if (guest?.check_in?.includes("T")) {
      return guest;
    } else {
      return {
        ...guest,
        check_in: guest?.check_in + "T00:00:00.000Z",
        check_out: guest?.check_out + "T00:00:00.000Z",
      };
    }
  }, [guest]);

  const tags = useMemo(() => getValues("tags") || [], [watch("tags")]);

  const handleSearchGuests = (evt) => {
    reservationContext.searchReservation({
      search_term: evt.target.value,
      filters: "all",
      preset: "soon",
      start_date: "2000-01-01",
      end_date: "2050-12-31",
      end_type: "check_in",
      page: 0,
      checked_in: false,
      checked_out: false,
      categories: "",
    });
  };

  const debounceSearchGuest = useMemo(
    () => debounce(handleSearchGuests, 500),
    []
  );

  const handleSearchRentals = (evt) => {
    rentalContext.searchRentals({
      search_term: evt.target.value,
    });
  };

  const debounceSearchRental = useMemo(
    () => debounce(handleSearchRentals, 500),
    []
  );

  const handleSelectGuest = (option) => {
    const reservation = reservationContext.listReservations.find(
      (gr) => gr.guest_reservation_id === option.value
    );
    setValue("guest_reservation_id", reservation.guest_reservation_id);
    setGuest(reservation);
    setGuestKey(null);
    reservationContext.setReservations([]);

    const property = rentalContext.rentals.find(
      (pr) => pr.pms_id === reservation.pms_id
    );
    setValue("rental_id", property?.rental_id);
    setProperty(property);
    setPropertyKey(null);
  };

  const handleSelectProperty = (option) => {
    const property = rentalContext.rentals.find(
      (pr) => pr.rental_id === option.value
    );
    setValue("rental_id", property?.rental_id);
    setProperty(property);
    setPropertyKey(null);
    // setGuest(null);
  };

  const handleClearForm = () => {
    setGuestKey(null);
    setPropertyKey(null);
    setProperty(null);
    setGuest(null);
    setAssigns([]);
    setAttachments([]);
    reset();
  };

  const handleRegister = useCallback(
    async (data) => {
      try {
        if (assignList) {
          const assigners = assignList.map((sl) => {
            if (typeof sl === "string") {
              return {
                type: sl.split("_")[0],
                id: sl.split("_")[1],
              };
            } else {
              return {
                type: sl.value.split("_")[0],
                id: sl.value.split("_")[1],
              };
            }
          });

          data["assigners"] = assigners;
        }

        if (attachmentList) {
          const files = [...attachmentList].filter((a) => !a?.url);

          const urls = await Promise.all(
            files.map((file) =>
              utilContext.uploadFile(
                file,
                `workorders/attachments/${utilContext.genUUID()}`
              )
            )
          );

          const newAssets = files.map((file, idx) => {
            return {
              type: "image",
              name: file.name,
              original_name: file.name,
              url: urls[idx],
            };
          });

          let attachments = attachmentList.map((a) => {
            if (a?.url) return a;
            return newAssets.shift();
          });

          data["attachments"] = attachments;
        }

        const result = await (isNew
          ? insertWorkOrder(data)
          : updateWorkOrder(workOrder.id, { ...data }));

        UIkit.modal("#work-order-add").hide();
        if (!result) {
          UIkit.notification("Work Order Add Failed", "danger");
        } else {
          UIkit.notification(
            isNew === true
              ? "Work Order Added Successfully"
              : "Work Order Updated Successfully",
            "success"
          );
          workOrderContext.initWorkOrder();
          handleClearForm();
        }
      } catch (err) {
        UIkit.notification("Failed", "error");
      }
    },
    [isNew, workOrder, attachmentList, assignList]
  );

  const handleError = (err) => {
    console.log(err);
  };

  const removeImage = (idx) => {
    let attachments = [...attachmentList];
    attachments.splice(idx, 1);
    setAttachments(attachments);
  };

  return (
    <>
      <div
        id="work-order-add"
        className="uk-modal-full hms-modals uk-modal uk-open uk-dark"
        uk-offcanvas="flip: true; overlay: true"
      >
        <div className="uk-offcanvas-bar">
          <div className="hms-modals-main">
            <button
              className="uk-offcanvas-close uk-close-large"
              type="button"
              data-uk-close
            ></button>
            <div className="uk-modal-header">
              <h2 className="uk-modal-title">
                {isNew ? "Add a" : "Edit"} Work Order
              </h2>
            </div>
            <div className="uk-modal-body uk-flex">
              <div className="wo-model-form-section">
                <form
                  data-uk-grid
                  onSubmit={handleSubmit(handleRegister, handleError)}
                >
                  <div className="wo-model-w-550 uk-grid" data-uk-grid>
                    <div className="uk-width-1-1">
                      <label className="uk-form-label">Summary*</label>
                      <div className="uk-form-controls">
                        <input
                          className={clsx("uk-input", {
                            "uk-form-danger": errors.summary,
                          })}
                          type="text"
                          placeholder=""
                          {...register("summary")}
                          // value="Coffee Order Title"
                        />
                      </div>
                    </div>
                    <div className="uk-width-1-1">
                      <div className="uk-form-label">Description</div>
                      <div className="uk-form-controls">
                        <textarea
                          className="uk-textarea"
                          rows="5"
                          {...register("description")}
                        ></textarea>
                      </div>
                    </div>
                    <div className="uk-width-1-1">
                      <label className="uk-form-label">Guest Name</label>
                      <div className="uk-form-controls">
                        <div className="uk-search uk-search-default w-100">
                          <AutoComplete
                            style={{ width: "100%" }}
                            value={guestKey}
                            options={guestOptions}
                            filterOption={(inputValue, option) =>
                              option.label
                                .toUpperCase()
                                .indexOf(inputValue.toUpperCase()) !== -1
                            }
                            onChange={(value, option) => setGuestKey(value)}
                            onSelect={(value, option) =>
                              handleSelectGuest(option)
                            }
                          >
                            <Input.Search
                              size="medium"
                              onChange={debounceSearchGuest}
                            ></Input.Search>
                          </AutoComplete>
                        </div>
                      </div>
                    </div>

                    {guest && (
                      <div className="hms-search-result uk-width-1-1">
                        <div className="search-result-wrapper">
                          <button
                            className="uk-button search-close-btn"
                            onClick={() => {
                              setGuest(null);
                              setValue("guest_reservation_id", null);
                            }}
                          >
                            <span uk-icon="icon: close; ratio: 0.9"></span>
                          </button>
                          <div className="uk-flex uk-flex-middle search-result-content">
                            <div className="search-result-key">Guest</div>
                            <div className="search-result-value">
                              {`${guest.first_name} ${guest.last_name}`}
                            </div>
                          </div>
                          <div className="uk-flex uk-flex-middle search-result-content">
                            <div className="search-result-key">Arrival</div>
                            <div className="search-result-value">
                              {`${moment(convertedGuest.check_in)
                                .utc()
                                .format("MMM DD, YYYY")}`}
                            </div>
                          </div>
                          <div className="uk-flex uk-flex-middle search-result-content">
                            <div className="search-result-key">Departure</div>
                            <div className="search-result-value">
                              {`${moment(convertedGuest.check_out)
                                .utc()
                                .format("MMM DD, YYYY")}`}
                            </div>
                          </div>
                          <div className="uk-flex uk-flex-middle search-result-content">
                            <div className="search-result-key">Email</div>
                            <div className="search-result-value">
                              {guest.email}
                            </div>
                          </div>
                          <div className="uk-flex uk-flex-middle search-result-content">
                            <div className="search-result-key">Phone</div>
                            <div className="search-result-value">
                              {guest.phone}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="uk-width-1-1">
                      <label className="uk-form-label">Property ID</label>
                      <div className="uk-form-controls">
                        <div className="uk-search uk-search-default w-100">
                          <AutoComplete
                            style={{ width: "100%" }}
                            value={propertyKey}
                            options={propertyOptions}
                            filterOption={(inputValue, option) =>
                              option.label
                                .toUpperCase()
                                .indexOf(inputValue.toUpperCase()) !== -1
                            }
                            onChange={(value, option) => setPropertyKey(value)}
                            onSelect={(value, option) =>
                              handleSelectProperty(option)
                            }
                            placeholder="Guest Name or Property ID"
                          >
                            <Input.Search
                              size="medium"
                              onChange={debounceSearchRental}
                            ></Input.Search>
                          </AutoComplete>
                        </div>
                      </div>
                    </div>
                    {property && (
                      <div className="hms-search-result uk-width-1-1">
                        <div className="search-result-wrapper">
                          <button
                            className="uk-button search-close-btn"
                            onClick={() => {
                              setProperty(null);
                              setValue("rental_id", null);
                            }}
                          >
                            <span uk-icon="icon: close; ratio: 0.9"></span>
                          </button>
                          <div className="uk-flex uk-flex-middle search-result-content">
                            <div className="search-result-key">Property ID</div>
                            <div className="search-result-value">
                              {property.pms_id}
                            </div>
                          </div>
                          <div className="uk-flex uk-flex-middle search-result-content">
                            <div className="search-result-key">Name</div>
                            <div className="search-result-value">
                              {property.pms_id}
                            </div>
                          </div>
                          <div className="uk-flex uk-flex-middle search-result-content">
                            <div className="search-result-key">Address</div>
                            <div className="search-result-value">
                              {property.address}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {/* <div className="uk-width-1-1">
                      <label className="uk-form-label">
                        Best time to enter Premise
                      </label>
                      <div className="uk-form-controls">
                        <div uk-form-custom="target: > * > span:first-child">
                          <select {...register("premise")}>
                            <option value="1:00 PM - 4:00 PM">
                              1:00 PM - 4:00 PM
                            </option>
                            <option value="1:00 PM - 5:00 PM">
                              1:00 PM - 5:00 PM
                            </option>
                          </select>
                          <button
                            className="uk-button uk-button-default"
                            type="button"
                            tabIndex="-1"
                          >
                            <span></span>
                            <span
                              uk-icon="icon: chevron-down"
                              className="hms-align-right"
                            ></span>
                          </button>
                        </div>
                      </div>
                    </div> */}
                    <div className="uk-width-1-2">
                      <label className="uk-form-label">Category</label>
                      <div className="uk-form-controls">
                        <div uk-form-custom="target: > * > span:first-child">
                          <select {...register("category")}>
                            <option value="">Select a Category</option>
                            <option value="air">Air Conditioner</option>
                            <option value="appliance">Appliance</option>
                            <option value="damage">Damage</option>
                            <option value="electrical">Electrical</option>
                            <option value="hot tub">Hot Tub</option>
                            <option value="housekeeping">Housekeeping</option>
                            <option value="inspection">Inspection</option>
                            <option value="mechanical">Mechanical</option>
                            <option value="plumbing">Plumbing</option>
                            <option value="pool">Pool</option>
                            <option value="preventive">Preventive</option>
                            <option value="project">Project</option>
                            <option value="refrigeration">Refrigeration</option>
                            <option value="safety">Safety</option>
                            <option value="standard operating procedure">
                              Standard Operating Procedure
                            </option>
                          </select>
                          <button
                            className="uk-button uk-button-default"
                            type="button"
                            tabIndex="-1"
                          >
                            <span className="select-value">
                              {getValues("category")}
                            </span>
                            <span
                              uk-icon="icon: chevron-down"
                              className="hms-align-right"
                            ></span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="uk-width-1-2">
                      <label className="uk-form-label">Priority</label>
                      <div className="uk-form-controls">
                        <div uk-form-custom="target: > * > span:first-child">
                          <select
                            defaultValue="medium"
                            {...register("priority")}
                          >
                            <option value="high">High</option>
                            <option value="medium">Medium</option>
                            <option value="low">Low</option>
                          </select>
                          <button
                            className="uk-button uk-button-default"
                            type="button"
                            tabIndex="-1"
                          >
                            <span className="select-value">
                              {getValues("priority")}
                            </span>
                            <span
                              uk-icon="icon: chevron-down"
                              className="hms-align-right"
                            ></span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="uk-width-1-2">
                      <label className="uk-form-label">Assign to</label>
                      <div className="uk-form-controls">
                        <div uk-form-custom="target: > * > span:first-child">
                          <Select
                            mode="multiple"
                            optionFilterProp="label"
                            allowClear
                            style={{ width: "100%" }}
                            placeholder="Select Assigner"
                            value={assignList}
                            onChange={(selected) => setAssigns(selected)}
                            options={assignerOptions}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="uk-width-1-2">
                      <label className="uk-form-label">Status</label>
                      <div className="uk-form-controls">
                        <div uk-form-custom="target: > * > span:first-child">
                          <select {...register("status")}>
                            <option value="new">New</option>
                            <option value="in-progress">In-Progress</option>
                            <option value="resolved">Resolved</option>
                            <option value="closed">Closed</option>
                          </select>
                          <button
                            className="uk-button uk-button-default"
                            type="button"
                            tabIndex="-1"
                          >
                            <span className="select-value">
                              {getValues("status")}
                            </span>
                            <span
                              uk-icon="icon: chevron-down"
                              className="hms-align-right"
                            ></span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="uk-width-1-1">
                      <div className="uk-form-label">Attachments</div>

                      <ImagePreview
                        attachments={attachmentList}
                        handler={removeImage}
                      />
                      <div className="js-upload uk-placeholder hms-file-uploader uk-text-center hms-file-delete">
                        <div className="uk-form-custom">
                          <div>
                            <Image
                              src="/images/upload-image.svg"
                              className="hms-navbar"
                            />
                          </div>
                          <input
                            key="thumnail_key"
                            type="file"
                            accept="image/*"
                            multiple
                            {...register("thumbnail")}
                            onChange={(evt) => {
                              setAttachments((prev) => {
                                return [...prev, ...evt.target.files];
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="uk-width-1-1">
                      <div className="hms-add-tag">
                        <div className="uk-form-label">Tags</div>
                        <div className="uk-flex uk-flex-top">
                          <Tags
                            value={tags || []}
                            onChange={(tags) => setValue("tags", tags)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="uk-width-1-1">
                      {/* <div className="attacment-display-area">
                        <Image src="/images/attachment.png" />
                      </div> */}
                    </div>
                    {/* <div className="uk-width-1-2">
                      <div className="attacment-display-area">
                        <Image src="/images/attachment.png" />
                      </div>
                    </div> */}
                    {/* <div className="uk-width-1-3">
                      <div className="attacment-display-area">
                        <Image src="/images/pdf-attachment.png" />
                        <div className="pdf-attachment-name">
                          <Image src="/images/pdf-icon.png" />
                          <span>pdf file name goes here..</span>
                        </div>
                      </div>
                    </div> */}
                  </div>
                  <div>
                    <button
                      className="uk-button hms-btn hms-red-btn"
                      type="submit"
                    >
                      {isProcessing ? (
                        <div data-uk-spinner="ratio: 0.5;"></div>
                      ) : (
                        "Save"
                      )}
                    </button>
                    <button
                      className="uk-button hms-btn uk-offcanvas-close"
                      type="button"
                      onClick={handleClearForm}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
                <div className="s-msg-last-updated">
                  {workOrderContext.isNew === false ? (
                    <div className="s-msg-last-updated">
                      {"Last Updated: " +
                        moment(workOrderContext.workOrder?.updated_at).format(
                          "MM/DD/YYYY hh:mm a"
                        )}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WorkOrderAdd;
