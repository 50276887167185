// Import package components
import { useContext, useState, useCallback } from "react";

import { AppContexts } from "../../../providers";

import UIkit from "uikit";
import SMSEmailInputBox from "../../common/sms-email-inputbox";

const GuestMessage = ({ reservation }) => {
  const [loading, setLoading] = useState(false);
  const [body, setBody] = useState("");

  const { createSMS } = useContext(AppContexts.ReservationsContext);

  const handleSendMsg = async (e) => {
    e.preventDefault();

    setLoading(true);
    if (!reservation.formatted_phone) {
      setLoading(false);
      UIkit.notification("Phone number is missing", "warning");
      return;
    }

    let sms = {
      guest_reservation_id: reservation.guest_reservation_id,
      message: body,
      phone: reservation.formatted_phone,
    };

    let result = await createSMS(sms);
    if (result) {
      setLoading(false);
      UIkit.modal("#modal-guest-messages").hide();
      UIkit.notification("Text message sent successfully.", "success");
      setBody("");
    }
  };

  const handleClose = useCallback(() => {
    setBody("");
  }, []);

  return (
    <div
      id="modal-guest-messages"
      className="uk-modal-full hms-modals uk-modal uk-open uk-dark"
      uk-offcanvas="flip: true; overlay: true"
    >
      <div className="uk-offcanvas-bar">
        <div className="hms-modals-main">
          <button
            className="uk-offcanvas-close uk-close-large"
            type="button"
            uk-close="true"
          ></button>
          <div className="uk-modal-header">
            <h2 className="uk-modal-title">Send Message</h2>
          </div>
          <div className="uk-modal-body">
            <div className="add-contact-form">
              <form
                className="uk-grid-small"
                uk-grid="true"
                onSubmit={handleSendMsg}
              >
                <div className="uk-width-1-1">
                  <SMSEmailInputBox
                    value={body || ""}
                    limit={false}
                    macrosOrResponse={false}
                    onChange={(e) => setBody(e.target.value)}
                    onSavedReply={setBody}
                  />
                </div>
                <div className="uk-width-1-1 uk-margin-top uk-flex">
                  <button
                    className="uk-button hms-btn hms-red-btn"
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? "Sending..." : "Send"}
                  </button>
                  <button
                    className="uk-button hms-btn uk-offcanvas-close"
                    onClick={handleClose}
                    type="button"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GuestMessage;
