// Import base components
import { httpV2 } from "../http.js";

const AccessoryService = {
  insertAccessory: (accessory) => httpV2.post("/accessories", accessory),
  retrieve: () => httpV2.get(`/accessories`),
  updateAccessory: (obj) => httpV2.put(`/accessories/${obj.id}`, obj),
  deleteAccessory: (id) => httpV2.delete(`/accessories/${id}`),
  getAccessory: (id, slug = undefined) => {
    if (slug) {
      return httpV2.get(`/accessories/${slug}/${id}`);
    } else {
      return httpV2.get(`/accessories/${id}`);
    }
  },
  getAccessories: (slug = undefined, criteria, recordsPerPage) => {
    if (slug) {
      return httpV2.get(
        `/accessories/${slug}?page=${criteria.page}&filters=${criteria.filters}&per=${recordsPerPage}&search_term=${criteria.search_term}&priority=${criteria.priority}&category=${criteria.category}&assignee=${criteria.assignee}&tag=${criteria.tag}`,
      );
    } else {
      return httpV2.get(
        `/accessories?page=${criteria.page}&filters=${criteria.filters}&per=${recordsPerPage}&search_term=${criteria.search_term}&priority=${criteria.priority}&category=${criteria.category}&assignee=${criteria.assignee}&tag=${criteria.tag}`,
      );
    }
  },
  getTags: (slug = "") => httpV2.get(`accessories/tags?slug=${slug}`),
  updateTabOrder: (id, ordering) =>
    httpV2.post(`accessories/update_tab_order`, { id: id, ordering: ordering }),
};

export default AccessoryService;
