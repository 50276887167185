import { useMemo } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

export default function usePath() {
  const history = useHistory();
  const pathArray = useMemo(() => history.location.pathname.split("/"), [
    history.location.pathname,
  ]);
  const path = pathArray[pathArray.length - 1].replace("#", "");

  return path;
}
