// Import package components
import {
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef,
  useMemo,
} from "react";
import { NavLink, useParams } from "react-router-dom";
import { Image, Flex } from "uikit-react";
import { useForm } from "react-hook-form";
import { useCookies } from "react-cookie";
import classnames from "classnames";
import moment from "moment";
import isEqual from "lodash/isEqual";
import debounce from "lodash.debounce";

// Import common components
import Pagination from "../../common/Pagination";
import RecordsPerPage from "../../common/RecordsPerPage";
import AddTextLine from "../../common/AddTextLine";

// Import work-order components
import WorkOrderAdd from "../work_order_add";
import WorkOrderDelete from "../work_order_delete";

import { AppContexts } from "../../../providers";
import Loading from "../../common/Loading";
import TableLoading from "../../common/Loading/table_loading";
import Tags from "../../common/Tags";
import { Link } from "../../../ui";

const defaultCriteria = {
  search_term: "",
  filters: "new",
  sort: "updated_at",
  category: "",
  priority: "",
  assignee: "",
  property: "",
  tag: "",
  page: 0,
  per: "20",
};

const sortOptions = [
  { key: "updated_at", title: "Last Updated" },
  { key: "created_at", title: "Created At" },
  { key: "summary", title: "Summary" },
  { key: "id", title: "Order Number" },
  { key: "assignee", title: "Assignee" },
];

const filterOptions = [
  { key: "new", title: "New" },
  { key: "in-progress", title: "In Progress" },
  { key: "resolved", title: "Resolved" },
  { key: "closed", title: "Closed" },
];

const WorkOrderList = () => {
  const params = useParams();

  const settingsContext = useContext(AppContexts.SettingsContext);
  const rentalContext = useContext(AppContexts.RentalsContext);
  const workOrderContext = useContext(AppContexts.WorkOrderContext);

  const { user } = useContext(AppContexts.AuthContext);

  const { register, getValues, setValue, watch } = useForm();

  const { workOrders, total, loading, attributes } = workOrderContext;
  const { rentals } = rentalContext;

  const [cookies] = useCookies(["records-per-page"]);
  const { "records-per-page": recordsPerPage } = cookies;

  const [criteria, setCriteria] = useState(defaultCriteria);

  useEffect(() => {
    document.title = "Work Orders - RueBaRue";
    if (user) {
      rentalContext.get();
      settingsContext.get(["tags"]);
      workOrderContext.getAttributes(null, ["assigners"]);
    }

    if (params.slug) {
      workOrderContext.getAttributes(params.slug, [
        "tags",
        "rentals",
        "assigners",
      ]);
    }
  }, []);

  useEffect(() => {
    if (workOrderContext.attributes && params.slug) {
      let { rentals, tags } = workOrderContext.attributes;

      if (rentals.length) rentalContext.setRentals(rentals);
      if (tags.length)
        settingsContext.setSettings({
          ...settingsContext.settings,
          tags: tags,
        });
    }
  }, [params.slug, workOrderContext.attributes]);

  const orders = useMemo(() => workOrders || [], [workOrders]);
  const propertyValues = useMemo(
    () =>
      (rentalContext.rentals || [])
        .filter((r) => r.pms_id)
        .sort((a, b) => a.pms_id.localeCompare(b.pms_id)) || [],
    [rentalContext.rentals]
  );
  const tagValues = useMemo(
    () =>
      (settingsContext.settings.tags || []).sort((a, b) =>
        a.name.localeCompare(b.name)
      ) || [],
    [settingsContext.settings]
  );
  const assignees = useMemo(() => {
    let { recipients, contacts, assignees } =
      workOrderContext.attributes.assigners;

    return [
      ...(recipients || [])
        .filter((recipient) => {
          return !!recipient.user_id && !recipient.deleted;
        })
        .map((recipient) => {
          return {
            label: `${recipient.first_name} ${recipient.last_name}`,
            value: `${recipient.recipient_id}`,
          };
        }),
      ...(contacts || [])
        .filter((contact) => {
          return !!assignees.find(
            (a) =>
              a.assignee_type === "contact" && +a.assignee_id === +contact.id
          );
        })
        .map((contact) => {
          return {
            label: `${contact.first_name} ${contact.last_name}`,
            value: `${contact.id}`,
          };
        }),
    ];
  }, [workOrderContext.attributes]);

  const debounceSearch = useMemo(
    () => debounce((evt) => setValue("search_term", evt.target.value), 500),
    []
  );

  const searchTerm = watch("search_term", defaultCriteria.search_term);
  const priorityWch = watch("priority", defaultCriteria.priority);
  const categoryWch = watch("category", defaultCriteria.category);
  const assigneeWch = watch("assignee", defaultCriteria.assignee);
  const propertyWch = watch("property", defaultCriteria.property);
  const tagWch = watch("tag", defaultCriteria.tag);

  const useDeepCompareEffect = (callback, dependencies) => {
    const currentDependenciesRef = useRef();

    if (!isEqual(currentDependenciesRef.current, dependencies)) {
      currentDependenciesRef.current = dependencies;
    }

    useEffect(() => {
      return callback();
    }, [currentDependenciesRef.current]);
  };

  useDeepCompareEffect(() => {
    setCriteria((prev) => {
      return {
        ...prev,
        priority: priorityWch,
        category: categoryWch,
        assignee: assigneeWch,
        property: propertyWch,
        tag: tagWch,
        search_term: searchTerm,
        per: recordsPerPage,
      };
    });
  }, [
    priorityWch,
    categoryWch,
    assigneeWch,
    propertyWch,
    tagWch,
    searchTerm,
    recordsPerPage,
  ]);

  useDeepCompareEffect(() => {
    workOrderContext.getWorkOrders(params.slug, criteria);
  }, [criteria]);

  const property = useCallback(
    (id) => rentals.find((rental) => +rental.rental_id === +id),
    [orders, rentals]
  );
  const handlePageChange = useCallback((pageIdx) =>
    handleCriteriaChange("page", pageIdx)
  );

  const handleAddWorkOrder = useCallback(() => {
    workOrderContext.setIsNew(true);
    workOrderContext.initWorkOrder();
  }, []);

  const handleEditWorkOrder = useCallback((id) => {
    workOrderContext.setIsNew(false);
    workOrderContext.getWorkOrder(id);
  }, []);

  const handleClickDelete = (workOrder) =>
    workOrderContext.setWorkOrder(workOrder);

  const handleChangeTags = (tag, selected, destOrder) => {
    let updatedOrder = {};
    if (selected) {
      updatedOrder = {
        ...destOrder,
        tags: [...(destOrder?.tags || []), tag],
      };
    } else {
      updatedOrder = {
        ...destOrder,
        tags: (destOrder.tags || []).filter(
          (existing) => existing.id !== tag.id
        ),
      };
    }

    workOrderContext.updateWorkOrder(updatedOrder.id, updatedOrder);
  };

  const getAssignees = (workOrder) =>
    workOrder.work_order_assignees
      .map((assignee) => {
        let idx;
        let { recipients, contacts } = workOrderContext.attributes.assigners;
        switch (assignee.assignee_type) {
          case "recipient":
            idx = (recipients || []).findIndex(
              (recipient) => +recipient.recipient_id === +assignee.assignee_id
            );
            if (idx > -1) return recipients[idx];
            break;
          case "contact":
            idx = (contacts || []).findIndex(
              (contact) => +contact.id === +assignee.assignee_id
            );
            if (idx > -1) return contacts[idx];
            break;
          default:
            break;
        }
      })
      .filter((a) => a)
      .map((d) => `${d.first_name} ${d.last_name}`)
      .join(", ");

  const diffDate = (order) => {
    let diff = moment.duration(moment().diff(moment.utc(order.created_at).local()));

    let year = diff.years();
    let month = diff.months();
    let day = diff.days();
    let hour = diff.hours();

    if (year) {
      return `${year} year(s) ago`;
    } else if (month) {
      return `${month} month(s) ago`;
    } else if (day) {
      return `${day} day(s) ago`;
    } else if (hour) {
      return `${hour} hour(s) ago`;
    }
  };

  const handleSelectedTags = useCallback((tags, order) => {
    let updated = { ...order, tags };
    workOrderContext.updateWorkOrder(updated.id, updated);
  }, []);

  // const handleSelectedRentals = useCallback((rentals, order) => {
  //   let updated = { ...order, rentals };
  //   workOrderContext.updateWorkOrder(updated.id, updated);
  // }, []);

  const handleCriteriaChange = useCallback((key, value) => {
    setCriteria((criteria) => ({ ...criteria, [key]: value }));
  });

  // return workOrderContext.loading ? <Loading /> : renderWorkOrders();

  const getRental = (rental) => {
    console.log(rental);
    return rental;
  };

  const orderRenderer = useCallback(
    (workOrder, idx) => (
      <tr key={`workorder_${idx}`}>
        <td>
          <div>{workOrder.id}</div>
        </td>
        <td>
          <div>
            {workOrder.summary}
            <div>{workOrder.guest_reservation?.name}</div>
            <div className="secondary">
              {property(workOrder.rental_id)?.pms_id}
            </div>
            <div className="secondary">
              {property(workOrder.rental_id)?.address}
            </div>
            <div className="secondary">
              {/* workOrder.guest_reservation
                                  ? `Reported By Guest: ${workOrder.guest_reservation?.first_name} ${workOrder.guest_reservation?.last_name}`
                                  : "" */}
            </div>
          </div>
        </td>
        <td>
          <div className="hms-booking-tag">{workOrder.priority}</div>
        </td>
        <td>
          <div>{workOrder.category}</div>
        </td>
        <td>
          <div>{getAssignees(workOrder)}</div>
        </td>
        <td>
          <div>
            {workOrder.reporter?.first_name +
              " " +
              workOrder.reporter?.last_name}
          </div>
        </td>

        {/* <td className="hms-add-tag">
                          <Properties
                            value={workOrder?.rentals || []}
                            onChange={(editedTags) =>
                              handleSelectedRentals(editedTags, workOrder)
                            }
                          />
                        </td> */}
        <td className="hms-add-tag">
          <Tags
            value={workOrder?.tags || []}
            onChange={(event) => handleSelectedTags(event, workOrder)}
          />
        </td>
        <td>
          <div>
            <div>{moment.utc(workOrder.created_at).local().format("MM/DD/YYYY hh:mm A")}</div>
            <div className="secondary">{diffDate(workOrder)}</div>
          </div>
        </td>
        <td className="hms-action-btn">
          <div className="uk-flex uk-flex-top">
            <div className="uk-inline">
              <button
                className="uk-button"
                uk-toggle="target: #work-order-add"
                onClick={() => handleEditWorkOrder(workOrder.id)}
                uk-tooltip="Edit"
              >
                <Image src="/images/edit.svg" />
              </button>
            </div>
            <div className="uk-inline">
              {params.slug ? (
                <a
                  href={`/work-orders/${params.slug}/${workOrder.id}`}
                  target="_blank"
                >
                  <button className="uk-button" uk-tooltip="Comment">
                    <Image src="/images/workorder-comment.svg" />
                  </button>
                </a>
              ) : (
                <a href={`/work-orders/${workOrder.id}`} target="_blank">
                  <button className="uk-button" uk-tooltip="Comment">
                    <Image src="/images/workorder-comment.svg" />
                  </button>
                </a>
              )}
            </div>
            {/* <div className="uk-inline">
                                <button className="uk-button">
                                  <Image src="/images/sms.svg" />
                                </button>
                              </div> */}
            <div className="uk-inline">
              <button
                className="uk-button"
                uk-toggle="target: #work-order-delete"
                onClick={() => handleClickDelete(workOrder)}
                uk-tooltip="Delete"
              >
                <Image src="/images/delete.svg" />
              </button>
            </div>
          </div>
        </td>
      </tr>
    ),
    [orders, attributes]
  );

  return (
    <>
      <section id="work-order-template">
        <AddTextLine feature="Work Orders" />
        <div id="hms-page-title">
          <h1 className="uk-heading-small">Work Orders</h1>
        </div>
        <div id="hms-main-body">
          <div className="uk-flex uk-flex-between uk-flex-top">
            <div className="hms-guest-btn">
              <button
                className="uk-button hms-btn hms-red-btn"
                uk-toggle="target: #work-order-add"
                onClick={handleAddWorkOrder}
              >
                <span uk-icon="icon: plus; ratio: 0.7"></span> Work Order
              </button>
            </div>
          </div>
          <div className="uk-card uk-card-small uk-card-default hms-form-card card-without-filters">
            <div className="uk-overflow-auto">
              <form action="" className="uk-form">
                <Flex
                  alignment="between top"
                  className="workorder-form-top-filters"
                >
                  <div className="hms-form">
                    <div className="uk-inline">
                      <div uk-form-custom="target: > * > span:first-child">
                        <select {...register("priority")}>
                          <option value="">All Priorities</option>
                          <option value="high">High</option>
                          <option value="medium">Medium</option>
                          <option value="low">Low</option>
                        </select>
                        <button
                          className="uk-button uk-button-default"
                          type="button"
                          tabIndex="-1"
                        >
                          <span></span>
                          <span uk-icon="icon: chevron-down"></span>
                        </button>
                      </div>
                    </div>

                    <div className="uk-inline">
                      <div uk-form-custom="target: > * > span:first-child">
                        <select {...register("category")}>
                          <option value="">All Categories</option>
                          <option value="air">Air Conditioner</option>
                          <option value="appliance">Appliance</option>
                          <option value="damage">Damage</option>
                          <option value="electrical">Electrical</option>
                          <option value="hot tub">Hot Tub</option>
                          <option value="housekeeping">Housekeeping</option>
                          <option value="inspection">Inspection</option>
                          <option value="mechanical">Mechanical</option>
                          <option value="plumbing">Plumbing</option>
                          <option value="pool">Pool</option>
                          <option value="preventive">Preventive</option>
                          <option value="project">Project</option>
                          <option value="refrigeration">Refrigeration</option>
                          <option value="safety">Safety</option>
                          <option value="standard operating procedure">
                            Standard Operating Procedure
                          </option>
                        </select>
                        <button
                          className="uk-button uk-button-default"
                          type="button"
                          tabIndex="-1"
                        >
                          <span></span>
                          <span uk-icon="icon: chevron-down"></span>
                        </button>
                      </div>
                    </div>

                    <div className="uk-inline">
                      <div uk-form-custom="target: > * > span:first-child">
                        <select {...register("assignee")}>
                          <option value="">All Assignees</option>
                          {assignees.map((assignee, idx) => {
                            return (
                              <option
                                key={`assignee_${idx}`}
                                value={assignee.value}
                              >
                                {assignee.label}
                              </option>
                            );
                          })}
                        </select>
                        <button
                          className="uk-button uk-button-default"
                          type="button"
                          tabIndex="-1"
                        >
                          <span></span>
                          <span uk-icon="icon: chevron-down"></span>
                        </button>
                      </div>
                    </div>

                    <div className="uk-inline">
                      <div uk-form-custom="target: > * > span:first-child">
                        <select {...register("property")}>
                          <option value="">All Properties</option>
                          {propertyValues.map((property, index) => (
                            <option
                              value={property.rental_id}
                              key={`property_option_${index}`}
                            >
                              {property.pms_id}
                            </option>
                          ))}
                        </select>
                        <button
                          className="uk-button uk-button-default"
                          type="button"
                          tabIndex="-1"
                        >
                          <span></span>
                          <span uk-icon="icon: chevron-down"></span>
                        </button>
                      </div>
                    </div>

                    <div className="uk-inline">
                      <div uk-form-custom="target: > * > span:first-child">
                        <select {...register("tag")}>
                          <option value="">All Tags</option>
                          {tagValues.map((tag, idx) => {
                            return (
                              <option key={`tag_select_${idx}`} value={tag.id}>
                                {tag.name}
                              </option>
                            );
                          })}
                        </select>

                        <button
                          className="uk-button uk-button-default"
                          type="button"
                          tabIndex="-1"
                        >
                          <span></span>
                          <span uk-icon="icon: chevron-down"></span>
                        </button>
                      </div>
                    </div>

                    <div className="uk-inline uk-margin-small-top">
                      <div className="uk-search uk-search-default">
                        {searchTerm && searchTerm !== "" ? (
                          <a
                            className="uk-form-icon-flip uk-form-icon"
                            uk-icon="icon: close"
                            onClick={() => setValue("search_term", "")}
                          ></a>
                        ) : (
                          <span
                            className="uk-search-icon-flip"
                            uk-search-icon="true"
                          />
                        )}
                        <input
                          className="uk-search-input"
                          type="search"
                          placeholder="Order # or Summary"
                          {...register("search_term")}
                          onChange={debounceSearch}
                        />
                      </div>
                    </div>
                  </div>

                  <div>
                    <div>
                      <div className="hms-sortby-btn">
                        <button className="uk-button" type="button">
                          SORT BY<span uk-icon="icon: triangle-down"></span>
                        </button>
                        <div uk-dropdown="mode: click; offset: 0; animation: uk-animation-slide-top-small; duration: 400">
                          <ul className="uk-nav uk-dropdown-nav">
                            {sortOptions.map((option, idx) => (
                              <li
                                className={classnames({
                                  "uk-active": criteria.sort === option.key,
                                })}
                                key={`sort_option_${idx}`}
                              >
                                <Link
                                  href="#"
                                  onClick={() =>
                                    handleCriteriaChange("sort", option.key)
                                  }
                                >
                                  {option.title}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </Flex>
              </form>

              <div className="uk-flex uk-flex-between">
                <div>
                  <ul data-uk-tab>
                    {filterOptions.map((option, idx) => (
                      <li
                        className={classnames({
                          "uk-active": criteria.filters === option.key,
                        })}
                        key={`filter_option_${idx}`}
                      >
                        <Link
                          href="#"
                          onClick={() =>
                            handleCriteriaChange("filters", option.key)
                          }
                        >
                          {option.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <table className="uk-table uk-table-hover uk-table-divider hms-table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th className="uk-table-expand">
                      Summary/Property ID/ADDRESS
                    </th>
                    <th>Priority</th>
                    <th>Category</th>
                    <th>Assigned To</th>
                    <th>Reporter</th>
                    {/* <th>Properties</th> */}
                    <th>Tags</th>
                    <th>Created At</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    TableLoading(10)
                  ) : orders.length > 0 ? (
                    orders.map(orderRenderer)
                  ) : (
                    <tr>
                      <td
                        colSpan={10}
                        style={{ padding: "30px 25px", textTransform: "none" }}
                      >
                        No work orders matching your current search or filters.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <Flex alignment="between top" className="guest-form-top-filters">
            <Pagination
              count={total}
              index={criteria.page}
              onIndexChanged={handlePageChange}
            />
            <RecordsPerPage />
          </Flex>
        </div>
      </section>
      <WorkOrderAdd />
      <WorkOrderDelete />
    </>
  );
};

export default WorkOrderList;
