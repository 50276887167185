import { createContext, useState } from "react";

import stripeService from "../services/stripe.service";

export const StripeContext = createContext();
export const StripeProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [accountLoading, setAccountLoading] = useState(true);
  const [active, setActive] = useState("");
  const [customerUrl, setCustomerUrl] = useState(null);
  const [account, setAccount] = useState(null);

  let stripeContext = {
    loading,
    active,
    setActive,
    customerUrl,
    account,
    accountLoading,

    // Generates a random UUID
    getCustomerUrl: async () => {
      setLoading(true);
      let result = await stripeService.getCustomerUrl();
      let { url, error } = result.data;

      setLoading(false);
      if (error) {
        console.log(error);
        setCustomerUrl(null);
      } else {
        setCustomerUrl(url);
      }
    },

    createAccount: () => {
      return stripeService.createAccount();
    },

    linkAccount: (account) => {
      return stripeService.linkAccount(account);
    },

    createRefund: (refund) => {
      return stripeService.refund(refund);
    },

    checkAccount: () => {
      setAccountLoading(true);
      stripeService
        .checkAccount()
        .then((resp) => {
          setAccountLoading(false);
          setAccount(resp.data?.stripe_account || null);
        })
        .catch((err) => {
          setAccountLoading(false);
          console.log("Error getting stripe account:", err);
        });
    },
  };

  return (
    <StripeContext.Provider value={stripeContext}>
      {children}
    </StripeContext.Provider>
  );
};
