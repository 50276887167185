import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import moment from "moment";

import { AppContexts } from "../../providers";
import UIkit from "uikit";
import classnames from "classnames";

const wdFormat = "YYYY-MM-DD HH:mm:ss";

const requiredFields = ["first_name", "last_name", "email"];

const TeamAdd = () => {
  let [errors, setErrors] = useState([]);

  let {
    recipient,
    setRecipient,
    createRecipient,
    updateRecipient,
    settings,
    isNew,
    setIsNew,
  } = useContext(AppContexts.InboxContext);

  const convertUTCDateToLocalDate = (date) => {
    const newDate = new Date(
      date.getTime() - date.getTimezoneOffset() * 60 * 1000,
    ).toLocaleString();

    return moment(newDate).format("YYYY-MM-DD HH:mm:ss");
  };

  let converted_recipient = useMemo(() => {
    let schedule = recipient.schedule.map((item) => {
      let startTime = convertUTCDateToLocalDate(new Date(item.start_time));
      let endTime = convertUTCDateToLocalDate(new Date(item.end_time));
      return { ...item, start_time: startTime, end_time: endTime };
    });
    return { ...recipient, schedule };
  }, [recipient]);

  const handleInput = (key, e) => {
    if (errors.includes(key)) {
      setErrors(errors.filter((f) => f !== key));
    }

    setRecipient({ ...recipient, [key]: e.target.value });
  };

  const handleUpdate = (e) => {
    e.preventDefault();

    let valid = true;
    let errs = requiredFields.filter((f) => !recipient[f]);
    if (errs.length > 0) {
      setErrors(errs);
      e.stopPropagation();
      return;
    }

    let result = "";

    if (!recipient.recipient_id) {
      if (
        settings.recipients.find(
          (item) => item.deleted == false && item.email == recipient.email,
        )
      ) {
        UIkit.notification("Duplicated email address");
        return;
      }
      result = createRecipient(recipient);
    } else {
      result = updateRecipient(recipient);
    }

    UIkit.modal("#team-add").hide();

    if (result == null) {
      UIkit.notification(isNew ? "Add Failed" : "Update Failed");
    } else {
      UIkit.notification(
        isNew ? "Added Successfully" : "Updated Successfully",
      );
    }
  };

  const errorClass = (key) => {
    return errors.includes(key) ? ["error"] : [];
  };

  const getSchedulePosition = (wd) => {
    let start_time = moment(wd.start_time, wdFormat);
    let end_time = moment(wd.end_time, wdFormat);
    let start =
      ((+start_time.format("H") * 4 + +start_time.format("mm") / 15) / 96) *
      100;
    let end =
      ((+end_time.format("H") * 4 + +end_time.format("mm") / 15) / 96) * 100;

    return { left: `${start}%`, width: `${end - start}%` };
  };

  const setAllDay = (dow) => {
    let wd = recipient.schedule.filter((d) => d?.weekday === dow)[0];
    if (!wd) {
      wd = { weekday: dow };
      wd.start_time = `2001-01-0${wd.weekday} 00:00:00`;
      wd.end_time = `2001-01-0${wd.weekday} 23:59:00`;
      recipient.schedule.push(wd);
    }

    wd.start_time = `2001-01-0${wd.weekday} 00:00:00`;
    wd.end_time = `2001-01-0${wd.weekday} 23:59:00`;

    setRecipient({ ...recipient });
  };

  const toggleMute = (dow) => {
    let wd = recipient.schedule.filter((d) => +d?.weekday === dow)[0];

    if (!!wd) {
      recipient.schedule.splice(recipient.schedule.indexOf(wd), 1);
    } else {
      recipient.schedule.push({
        start_time: `2001-01-0${dow} 09:00:00`,
        end_time: `2001-01-0${dow} 17:00:00`,
        weekday: dow,
      });
    }

    setRecipient({ ...recipient });
  };

  const handleDragStart = (wd, key, e) => {
    e.preventDefault();
    e.stopPropagation();

    const parent = e.target.closest(".notification-schedule-slider-wrapper");
    const onMove = (e) => {
      let { left: parentLeft, width: parentWidth } =
        parent.getBoundingClientRect();
      let { pageX: mouseLeft } = e;

      let percent = (mouseLeft - parentLeft) / parentWidth;
      let hour = Math.floor(24 * percent);
      let minute = Math.round((24 * percent - hour) * 60);
      let rounded =
        minute % 15 > 7
          ? minute + (15 - (minute % 15))
          : minute - (minute % 15);

      if (rounded === 60) {
        hour = hour + 1;
        rounded = 0;
      }

      if (hour === 24) {
        hour = 23;
        rounded = 59;
      }

      if (hour < 10) {
        hour = `0${hour}`;
      }

      if (rounded < 10) {
        rounded = `0${rounded}`;
      }

      let targetTime = `${wd[key].split(/[\s|T]/g)[0]} ${hour}:${rounded}:00`;

      //Sanity check
      if (key === "start_time") {
        let endTime = moment(wd.end_time, wdFormat);
        if (endTime.isAfter(moment(targetTime))) {
          wd[key] = targetTime;
        }
      } else {
        let startTime = moment(wd.start_time, wdFormat);
        if (startTime.isBefore(targetTime)) {
          wd[key] = targetTime;
        }
      }

      setRecipient({ ...recipient });
    };

    const onUp = (e) => {
      parent.removeEventListener("mousemove", onMove);
      window.removeEventListener("mouseup", onUp);
    };

    parent.addEventListener("mousemove", onMove);
    window.addEventListener("mouseup", onUp);
  };

  const getHours = (wd) => {
    if (!wd) return "Muted";

    let start_time = moment(wd.start_time, wdFormat);
    let end_time = moment(wd.end_time, wdFormat);

    return `${start_time.format("h:mma")} - ${end_time.format("h:mma")}`;
  };

  const weekdays = {
    1: "Sunday",
    2: "Monday",
    3: "Tuesday",
    4: "Wednesday",
    5: "Thursday",
    6: "Friday",
    7: "Saturday",
  };

  return (
    <div
      id="team-add"
      className="uk-modal-full hms-modals uk-modal uk-open uk-dark"
      uk-offcanvas="flip: true; overlay: true"
    >
      <div className="uk-offcanvas-bar">
        <div className="hms-modals-main">
          <button
            className="uk-offcanvas-close uk-close-large"
            type="button"
            data-uk-close
          ></button>
          <div className="uk-modal-header">
            <h2 className="uk-modal-title">{isNew ? "Add a" : "Edit"} User</h2>
          </div>
          <div className="uk-modal-body">
            <form
              className="uk-grid-small uk-margin-none-top"
              data-uk-grid
              onSubmit={handleUpdate}
            >
              <div className="uk-width-1-1 uk-grid uk-grid-margin uk-first-column">
                <div className="uk-width-1-2 uk-first-column">
                  <label className="uk-form-label">First Name*</label>
                  <div className="uk-form-controls">
                    <input
                      className={["uk-input", ...errorClass("first_name")].join(
                        " ",
                      )}
                      type="text"
                      placeholder=""
                      value={recipient.first_name}
                      onInput={(e) => handleInput("first_name", e)}
                    />
                  </div>
                </div>
                <div className="uk-width-1-2@s">
                  <label className="uk-form-label">Last Name*</label>
                  <div className="uk-form-controls">
                    <input
                      className={["uk-input", ...errorClass("last_name")].join(
                        " ",
                      )}
                      type="text"
                      placeholder=""
                      value={recipient.last_name}
                      onInput={(e) => handleInput("last_name", e)}
                    />
                  </div>
                </div>
              </div>
              <div className="uk-width-1-1">
                <div className="uk-width-1-2 uk-first-column">
                  <label className="uk-form-label">Email*</label>
                  <div className="uk-form-controls">
                    <input
                      className={classnames(
                        ["uk-input", ...errorClass("email")].join(" "),
                        { "input-disable-status": isNew == false },
                      )}
                      type="text"
                      placeholder=""
                      value={recipient.email}
                      onInput={(e) => handleInput("email", e)}
                      disabled={!!recipient.recipient_id}
                    />
                  </div>
                </div>
              </div>
              <div className="uk-width-1-1 model-inner-headings uk-margin-top">
                Notification Schedule
              </div>
              {[1, 2, 3, 4, 5, 6, 7].map((dow) => {
                let wd = recipient.schedule?.filter(
                  (d) => d?.weekday === dow,
                )[0];
                let isActiveFlag = 0;
                if (!!wd) {
                  isActiveFlag =
                    wd.start_time?.includes("00:00:00") &&
                    wd.end_time?.includes("23:59:00")
                      ? 1
                      : 2;
                }
                console.log("wd:", wd);
                return (
                  <div className="uk-width-1-1 notification-timeline-box uk-margin-bottom">
                    <div className="secondary">
                      {weekdays[dow]}: {getHours(wd)}
                    </div>
                    <div className="uk-form-controls">
                      <div className="uk-flex">
                        <div className="notification-timeline-wrapper">
                          <div className="notification-schedule-timeline">
                            <ul className="uk-flex">
                              <li>
                                <div>12:00 AM</div>
                              </li>
                              <li>
                                <div>6:00 AM</div>
                              </li>
                              <li>
                                <div>NOON</div>
                              </li>
                              <li>
                                <div>6:00 PM</div>
                              </li>
                              <li>
                                <div>12:00 PM</div>
                              </li>
                            </ul>
                            {!!wd && (
                              <div className="notification-schedule-slider-wrapper">
                                <div
                                  className="notification-schedule-slider"
                                  style={getSchedulePosition(wd)}
                                >
                                  <div
                                    className="notification-schedule-knob"
                                    onMouseDown={(e) =>
                                      handleDragStart(wd, "start_time", e)
                                    }
                                  ></div>
                                  <div
                                    className="notification-schedule-knob"
                                    onMouseDown={(e) =>
                                      handleDragStart(wd, "end_time", e)
                                    }
                                  ></div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="hms-notification-action-btn">
                          <div
                            className={
                              "uk-button all-day-" +
                              (isActiveFlag === 1 ? "all" : "non")
                            }
                            onClick={() => setAllDay(dow)}
                          >
                            ALL DAY
                          </div>
                          <div
                            className={
                              "uk-button mute-unmute-" +
                              (!!wd ? "MUTE" : "UNMUTE")
                            }
                            onClick={() => toggleMute(dow)}
                          >
                            {!!wd ? "MUTE" : "UNMUTE"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
              <div className="uk-width-1-1 uk-margin-top">
                <button className="uk-button hms-btn hms-red-btn" type="submit">
                  Save
                </button>
                <button
                  className="uk-button hms-btn uk-modal-close"
                  uk-toggle="target: #team-add"
                  type="button"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamAdd;
