import React, { useMemo, useContext, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import UIkit from "uikit";
import { Image } from "uikit-react";
import { InputContainer } from "uikit-react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import classnames from "classnames";
import CurrencyInput from "react-currency-input-field";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";

import { AppContexts } from "../../providers";
import { Form, FormRow, FormInputWrapper, Link } from "../../ui";
import Tags from "../common/Tags";
import ImagePreview from "../common/ImagePreview";
import MasterConfirm from "../common/Confirm";

const schema = yup.object().shape({
  label: yup.string().required(),
});

export const MODAL_NAME = "accessory-property-add";

const AccessoryAdd = () => {
  const { settings, setSettings } = useContext(AppContexts.SettingsContext);
  const {
    accessory,
    insertAccessory,
    updateAccessory,
    selectedTab,
    setSelectedTab,
    initAccessory,
  } = useContext(AppContexts.AccessoryContext);
  const utilContext = useContext(AppContexts.UtilContext);

  const [attachments, setAttachments] = useState([]);
  const [toDelete, setToDelete] = useState(null);
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    getValues,
    watch,
    clearErrors,
    reset,
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const variants = useMemo(
    () => getValues("variants") || [],
    [watch("variants")],
  );
  const availability = useMemo(
    () => getValues("availability") || "",
    [watch("availability")],
  );
  const tags = useMemo(() => getValues("tags") || [], [watch("tags")]);
  const isNew = useMemo(
    () => accessory?.account_id === 0,
    [JSON.stringify(accessory)],
  );
  const toPrice = (price) =>
    (+(Math.round(price / 100 + "e+2") + "e-2")).toFixed(2);

  const handleError = (err) => console.error(err);

  useEffect(() => {
    console.log(variants);
  }, [variants]);

  useEffect(() => {
    UIkit.util.on(`#${MODAL_NAME}`, "hidden", function (e) {
      setAttachments([]);
      initAccessory();
      // reset();
    });

    return () => {
      setAttachments([]);
      initAccessory();
      // reset();
    };
  }, []);

  useEffect(() => {
    if (!isNew && accessory) {
      clearErrors();

      Object.keys(accessory).forEach((formname) => {
        if (formname === "price") {
          setValue("price", toPrice(accessory["price"]));
        } else if (formname === "variants") {
          let variants = (accessory["variants"] || []).map((v) => {
            let variant = { ...v };
            if (variant?.options) {
              let options = variant.options.map((o) => {
                return { ...o, price: toPrice(o.price) };
              });
              variant.options = options;
            }
            return variant;
          });

          setValue("variants", variants);
        } else {
          setValue(formname, accessory[formname]);
        }
      });

      let images = (accessory.attachments || []).filter(
        (attachment) => attachment.type === "image",
      );
      setAttachments(images);
    }
  }, [JSON.stringify(accessory), isNew]);

  const handleRegister = async (formdata) => {
    if (!!loading) {
      return;
    }

    try {
      if (attachments) {
        const files = [...attachments].filter((a) => !a?.url);

        const urls = await Promise.all(
          files.map((file) =>
            utilContext.uploadFile(
              file,
              `add_ons/attachments/${utilContext.genUUID()}`,
            ),
          ),
        );

        const newAssets = files.map((file, idx) => {
          return {
            type: "image",
            name: file.name,
            original_name: file.name,
            url: urls[idx],
          };
        });

        let newAttachments = attachments.map((a) => {
          if (a?.url) return a;
          return newAssets.shift();
        });

        formdata["attachments"] = newAttachments;
      }
      formdata.tab_id = selectedTab.id;
      formdata.price = Math.round(formdata.price * 100);

      let variants = (formdata["variants"] || []).map((v) => {
        let variant = { ...v };
        if (variant?.options) {
          let options = variant.options.map((o) => {
            return { ...o, price: Math.round(o.price * 100) };
          });
          variant.options = options;
        }
        return variant;
      });
      formdata.variants = variants;

      setLoading(true);
      let [accessory, accessoryErr] = isNew
        ? await insertAccessory({ ...formdata })
        : await updateAccessory({ ...formdata });

      console.log(
        accessoryErr !== null,
        accessory === null,
        accessoryErr !== null || accessory === null,
      );

      if (accessoryErr !== null || accessory === null) {
        UIkit.notification("Error: Failed to create add-on", "error");
        setLoading(false);
      } else {
        if (isNew) {
          let newTab = {
            ...selectedTab,
            ordering: [...selectedTab.ordering, accessory.id],
          };

          let accessory_tabs = [...settings.accessories.accessory_tabs];
          let idx = accessory_tabs.findIndex(
            (tab) => tab.id === selectedTab.id,
          );
          accessory_tabs[idx] = newTab;
          setSettings({ ...settings });
          setSelectedTab(newTab);
        }
      }

      UIkit.modal(`#${MODAL_NAME}`).hide();
      UIkit.notification(
        isNew ? "Added Successfully" : "Updated Successfully",
        "success",
      );

      setLoading(false);
      reset();
    } catch (err) {
      console.log(err);
      UIkit.notification("Failed to create add-on", "error");
      setLoading(false);
    }
  };

  const handleClose = () => {
    document.body.style.overflow = "unset";
    UIkit.modal(`#${MODAL_NAME}`).hide();
  };

  const hasImage = () => {
    return !![...attachments].find((a) => !!a?.url);
  };

  const initialOption = {
    label: "Option Label",
    price: "0",
    description: "",
  };

  const initialVariant = {
    label: "Variant Label",
    multiple: false,
    required: false,
    options: [],
  };

  const DragOptionHandle = SortableHandle(() => (
    <div>
      <button className="uk-button drag-handle" uk-tooltip="Move">
        <Image src="/images/drag-handle.svg" />
      </button>
    </div>
  ));

  const SortableOptionDiv = SortableElement(
    ({ option, varIndex, optIndex }) => (
      <div className="uk-card uk-card-default uk-card-small variant-option-card">
        <div className="uk-flex uk-flex-left variant-option-body">
          <div className="uk-margin-top">
            <DragOptionHandle />
          </div>

          <div className="uk-flex uk-margin-left">
            <FormInputWrapper label="Name" size="one">
              <InputContainer>
                <input
                  className="uk-input"
                  type="text"
                  {...register(
                    `variants[${varIndex}].options[${optIndex}].label`,
                  )}
                  defaultValue={option.name || ""}
                />
              </InputContainer>
            </FormInputWrapper>

            <FormInputWrapper label="Price" size="one">
              <Controller
                name={`variants[${varIndex}].options[${optIndex}].price`}
                control={control}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <CurrencyInput
                    className="uk-input"
                    decimalsLimit={2}
                    prefix="$"
                    placeholder="$"
                    value={value || ""}
                    onValueChange={(value) => {
                      onChange(value);
                    }}
                    onBlur={onBlur}
                    ref={ref}
                  />
                )}
              />
            </FormInputWrapper>
          </div>

          <button
            className="uk-button uk-button-small close-option-btn"
            onClick={() => handleRemoveOption(varIndex, optIndex)}
            type="button"
          >
            <span uk-icon="close"></span>
          </button>
        </div>
      </div>
    ),
  );

  const SortableOptionList = SortableContainer(({ options, varIndex }) => (
    <div>
      {options?.map((option, index) => (
        <SortableOptionDiv
          key={`variant${varIndex}-option${index}`}
          index={index}
          option={option}
          varIndex={varIndex}
          optIndex={index}
        />
      ))}
    </div>
  ));

  const handleReorderOptions =
    (variantIndex) =>
    ({ oldIndex, newIndex }) => {
      const newVariants = [...variants];
      const options = newVariants[variantIndex].options || [];

      newVariants[variantIndex].options = arrayMoveImmutable(
        [...options],
        oldIndex,
        newIndex,
      );
      setValue("variants", newVariants);
    };

  const handleAddOption = (variantIndex) => {
    const newVariants = [...variants];
    const options = newVariants[variantIndex].options || [];
    newVariants[variantIndex].options = [...options, initialOption];
    setValue("variants", newVariants);
  };

  const DragVariantHandle = SortableHandle(() => (
    <button className="uk-button drag-handle" uk-tooltip="Move">
      <Image src="/images/drag-handle.svg" />
    </button>
  ));

  const SortableVariantDiv = SortableElement(({ variant, varIndex }) => (
    <div className="uk-card uk-card-default uk-card-small accessory-variant-card">
      <div className="uk-flex uk-flex-left accessory-variant-body">
        <div className="uk-margin-top">
          <DragVariantHandle />
        </div>

        <div className="uk-flex uk-margin-left uk-flex-column">
          <FormRow>
            <FormInputWrapper label="Title" size="two">
              <InputContainer>
                <input
                  className="uk-input"
                  type="text"
                  {...register(`variants[${varIndex}].label`)}
                />
              </InputContainer>
            </FormInputWrapper>
          </FormRow>

          <FormRow>
            <div className="uk-width-1-1@s">
              <label className="uk-form-label">Allow Multiple Selection</label>
              <div className="rbr-checkbox">
                <label
                  htmlFor={`variants[${varIndex}].multiple`}
                  className="setting-switch"
                >
                  <input
                    type="checkbox"
                    className="uikit-checkbox"
                    id={`variants[${varIndex}].multiple`}
                    {...register(`variants[${varIndex}].multiple`)}
                  />
                  <span className="setting-slider setting-round"></span>
                </label>
              </div>
            </div>
          </FormRow>

          <FormRow>
            <div className="uk-width-1-1@s">
              <label className="uk-form-label">Require Selection</label>
              <div className="rbr-checkbox">
                <label
                  htmlFor={`variants[${varIndex}].required`}
                  className="setting-switch"
                >
                  <input
                    type="checkbox"
                    className="uikit-checkbox"
                    id={`variants[${varIndex}].required`}
                    {...register(`variants[${varIndex}].required`)}
                  />
                  <span className="setting-slider setting-round"></span>
                </label>
              </div>
            </div>
          </FormRow>

          {Array.isArray(variant.options) && variant.options.length > 0 && (
            <FormRow>
              <FormInputWrapper label="Options" size="two">
                <div className="uk-width-1-1@s add-options-body">
                  <SortableOptionList
                    options={variant.options || []}
                    varIndex={varIndex}
                    useDragHandle
                    onSortEnd={handleReorderOptions(varIndex)}
                  />
                </div>
              </FormInputWrapper>
            </FormRow>
          )}
        </div>

        <button
          className="uk-button uk-button-small close-variant-btn"
          onClick={() => handleRemoveVariant(varIndex)}
          type="button"
        >
          <span uk-icon="close"></span>
        </button>
      </div>
      <hr />
      <div className="uk-flex accessory-variant-action">
        <button
          className="uk-button uk-flex add-options-action"
          type="button"
          onClick={() => handleAddOption(varIndex)}
        >
          <span uk-icon="plus-circle"></span>
          <span>Add another option</span>
        </button>
      </div>
    </div>
  ));

  const SortableVariantList = SortableContainer(({ variants }) => (
    <div>
      {variants?.map((variant, index) => (
        <SortableVariantDiv
          key={`variant-${index}`}
          index={index}
          varIndex={index}
          variant={variant}
        />
      ))}
    </div>
  ));

  const handleReorderVariants = ({ oldIndex, newIndex }) => {
    const newVariants = arrayMoveImmutable(variants, oldIndex, newIndex);
    setValue("variants", newVariants);
  };

  const handleAddVariant = () => {
    const newVariants = [...variants, initialVariant];
    setValue("variants", newVariants);
  };

  const handleRemoveVariant = (index) => {
    const newVariants = [...variants];
    newVariants.splice(index, 1);
    setValue("variants", newVariants);
  };

  const handleRemoveOption = (variantIndex, optionIndex) => {
    let variant = variants[variantIndex];

    variant.options.splice(optionIndex, 1);
    setValue("variants", [...variants]);
  };

  const removeImage = (idx) => {
    let newAttachments = [...attachments];
    newAttachments.splice(idx, 1);
    setAttachments(newAttachments);
  };

  const renderVariants = useMemo(
    () => (
      <div className="uk-width-1-1@s uk-margin-top uk-margin-bottom add-variants-body">
        <div className="uk-form-label">Variants</div>
        <div className="uk-width-1-1@s uk-margin-small-bottom">
          Add options like size, color, bedroom size
        </div>
        <SortableVariantList
          variants={variants}
          useDragHandle
          onSortEnd={handleReorderVariants}
        />
        <div className="uk-width-1-1@s">
          <button
            className="uk-button uk-flex add-variants-action"
            type="button"
            onClick={handleAddVariant}
          >
            <span uk-icon="plus-circle"></span>
            <span>Add variant</span>
          </button>
        </div>
      </div>
    ),
    [variants],
  );

  return (
    <>
      <div
        id={MODAL_NAME}
        className="uk-modal-full hms-modals uk-modal uk-open"
        uk-offcanvas="flip: true; overlay: true"
      >
        <div className="uk-offcanvas-bar">
          <div className="hms-modals-main">
            <button
              className="uk-offcanvas-close uk-close-large"
              type="button"
              uk-close="true"
            ></button>
            <div className="uk-modal-header">
              <h2 className="uk-modal-title">{`${
                isNew ? "Add a" : "Edit"
              } Product`}</h2>
            </div>
            <div className="uk-modal-body">
              <Form onSubmit={handleSubmit(handleRegister, handleError)}>
                <FormRow>
                  <FormInputWrapper label="Name*" size="one">
                    <InputContainer>
                      <input
                        className={classnames("uk-input", {
                          error: errors["label"],
                        })}
                        type="text"
                        {...register("label")}
                      />
                    </InputContainer>
                  </FormInputWrapper>
                </FormRow>
                <FormRow>
                  <div className="uk-width-1-1">
                    <div className="uk-form-label">Thumbnail</div>

                    <ImagePreview
                      attachments={attachments}
                      handler={removeImage}
                    />
                    {!hasImage() && (
                      <div className="js-upload uk-placeholder hms-file-uploader uk-text-center hms-file-delete">
                        <div className="uk-form-custom">
                          <div>
                            <Image
                              src="/images/upload-image.svg"
                              className="hms-navbar"
                            />
                          </div>

                          <input
                            type="file"
                            accept="image/*"
                            multiple
                            onChange={(evt) => {
                              setAttachments((prev) => {
                                return [...prev, ...evt.target.files];
                              });
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </FormRow>
                {/*
                <FormRow>
                  <FormInputWrapper label="Tax Category" size="one">
                    <InputContainer>
                      <input
                        className={classnames("uk-input", {
                          error: errors["category"],
                        })}
                        type="text"
                        {...register("category")}
                      />
                    </InputContainer>
                  </FormInputWrapper>
                </FormRow>
                */}
                <FormRow>
                  <FormInputWrapper label="Description" size="two">
                    <span className="hms-align-right">
                      <Link className="m-l-10">
                        <a
                          href="https://intercom.help/ruebarueapp/en/articles/9792129-style-shortcuts"
                          target="_blank"
                        >
                          View Styles
                        </a>
                      </Link>
                    </span>
                    <InputContainer>
                      <textarea
                        className={classnames("uk-textarea", {
                          error: errors.description,
                        })}
                        {...register("description")}
                        rows="5"
                      />
                    </InputContainer>
                  </FormInputWrapper>
                </FormRow>
                <FormRow>
                  <div className="uk-width-1-1@s">
                    <FormInputWrapper label="Specifications" size="two">
                      <InputContainer>
                        <textarea
                          className={classnames("uk-textarea", {
                            error: errors.specifications,
                          })}
                          {...register("specifications")}
                          rows="5"
                        />
                      </InputContainer>
                    </FormInputWrapper>
                  </div>
                </FormRow>

                <FormRow>
                  <div className="uk-width-1-2@s uk-first-column">
                    <FormInputWrapper label="Price" size="one">
                      <Controller
                        name="price"
                        control={control}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <CurrencyInput
                            className="uk-input"
                            decimalsLimit={2}
                            prefix="$"
                            placeholder="$"
                            value={value || ""}
                            onValueChange={(value) => {
                              onChange(value);
                            }}
                            onBlur={onBlur}
                            ref={ref}
                          />
                        )}
                      />
                    </FormInputWrapper>
                  </div>
                </FormRow>
                {/*
                <FormRow>
                  <div className="uk-width-1-2">
                    <label className="uk-form-label">Availability</label>
                    <div className="uk-form-controls">
                      <div uk-form-custom="target: > * > span:first-child">
                        <select
                          {...register("availability")}
                          defaultValue={"99999"}
                        >
                          <option value="">Select an Option</option>
                          <option value="-3">
                            Up To 3 Days Before Check-In
                          </option>
                          <option value="-2">
                            Up To 2 Days Before Check-In
                          </option>
                          <option value="-1">
                            Up To 1 Day Before Check-In
                          </option>
                          <option value="0">Up To Day Of Check-In</option>
                          <option value="99998">Up To Day of Check-Out</option>
                          <option value="99999">Forever</option>
                        </select>
                        <button
                          className="uk-button uk-button-default"
                          type="button"
                          tabIndex="-1"
                        >
                          <span className="select-value">
                            {availability
                              ? document.querySelector(
                                  `option[value="${availability}"]`,
                                )?.textContent
                              : "Select an Option"}
                          </span>
                          <span
                            uk-icon="icon: chevron-down"
                            className="hms-align-right"
                          ></span>
                        </button>
                      </div>
                    </div>
                  </div>
                </FormRow>
                */}
                <FormRow>
                  <div className="uk-width-1-1@s">
                    <div className="rbr-checkbox">
                      <label htmlFor="isTaxable" className="setting-switch">
                        <input
                          type="checkbox"
                          className="uikit-checkbox"
                          id="isTaxable"
                          {...register("is_taxable")}
                        />
                        <span className="setting-slider setting-round"></span>
                      </label>
                      <span className="toggle-label">Is Taxable</span>
                    </div>
                  </div>
                </FormRow>
                <FormRow>
                  <div className="uk-width-1-1@s">
                    <div className="rbr-checkbox">
                      <label htmlFor="dateRequired" className="setting-switch">
                        <input
                          type="checkbox"
                          className="uikit-checkbox"
                          id="dateRequired"
                          {...register("date_required")}
                        />
                        <span className="setting-slider setting-round"></span>
                      </label>
                      <span className="toggle-label">
                        Show Date When Required Field
                      </span>
                    </div>
                  </div>
                </FormRow>
                <FormRow>
                  <div className="uk-width-1-1@s">
                    <div className="rbr-checkbox">
                      <label
                        htmlFor="quantityRequired"
                        className="setting-switch"
                      >
                        <input
                          type="checkbox"
                          className="uikit-checkbox"
                          id="quantityRequired"
                          {...register("quantity_required")}
                        />
                        <span className="setting-slider setting-round"></span>
                      </label>
                      <span className="toggle-label">Show Quantity Field</span>
                    </div>
                  </div>
                </FormRow>
                <FormRow>
                  <div className="uk-width-1-1@s">
                    <div className="rbr-checkbox">
                      <label
                        htmlFor="specialRequired"
                        className="setting-switch"
                      >
                        <input
                          type="checkbox"
                          className="uikit-checkbox"
                          id="specialRequired"
                          {...register("special_instructions_required")}
                        />
                        <span className="setting-slider setting-round"></span>
                      </label>
                      <span className="toggle-label">
                        Show Special Instructions Field
                      </span>
                    </div>
                  </div>
                </FormRow>

                <FormRow>
                  <div label="Tags">
                    <label className="uk-form-label">Tags</label>
                    <div className="hms-add-tag">
                      <Tags
                        value={tags}
                        onChange={(editedTags) => setValue("tags", editedTags)}
                      />
                    </div>
                  </div>
                </FormRow>

                {renderVariants}

                <div>
                  <button
                    className="uk-button hms-btn hms-red-btn"
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? (
                      <div data-uk-spinner="ratio: 0.5;"></div>
                    ) : (
                      "Save"
                    )}
                  </button>
                  <button
                    className="uk-button hms-btn uk-offcanvas-close"
                    onClick={handleClose}
                    type="button"
                  >
                    Cancel
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccessoryAdd;
