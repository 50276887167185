// Import package components
import { useContext, useEffect, useState } from "react";
import { Link } from "uikit-react";
import { AppContexts } from "../../../providers";
import { useHistory } from "react-router-dom";

const defaultValues = [{ types: [], path: "connect", key: "connect" }];

const StripeNavbar = () => {
  const { get, active, setActive } = useContext(AppContexts.StripeContext);
  const history = useHistory();

  const handleActiveList = (key) => {
    let val = defaultValues.find((v) => v.key === key);

    setActive(key);

    history.push(`/payments/${val.path}`);
  };

  useEffect(() => {
    let pathArray = history.location.pathname.split("/");
    let path = pathArray.pop().replace("#", "");
    let val = defaultValues.find((v) => v.path === path);

    if (!val) {
      setActive(defaultValues[0].key);
    } else {
      setActive(val.key);
    }
  }, []);

  return (
    <div className="uk-width-auto uk-first-column">
      <ul className="uk-tab-left hms-setting-option-tabs hms-left-tabs uk-tab">
        <li
          className={active === "connect" ? "uk-active" : ""}
          onClick={() => handleActiveList("connect")}
        >
          <Link>Connect Stripe Account</Link>
        </li>
        <li
          className={active === "connect" ? "uk-active" : ""}
          onClick={() => handleActiveList("lynnbrook")}
        >
          <Link>Connect Lynnbrook Account</Link>
        </li>
      </ul>
    </div>
  );
};

export default StripeNavbar;
