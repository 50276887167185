import { useState, useCallback, useEffect, useRef, useContext } from "react";
import { AppContexts } from "../../../providers";
import UIkit from "uikit";

const DestinationShare = () => {
  const { shared, setShared } = useContext(AppContexts.DestinationContext);
  const { shareGuide } = useContext(AppContexts.UtilContext);

  const [phone, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [copied, setCopied] = useState(false);

  const offcanvasRef = useRef(null);

  useEffect(() => {
    const offcanvasElement = UIkit.offcanvas(offcanvasRef.current);
    const offcanvasDOMElement = offcanvasElement.$el;
    offcanvasDOMElement.addEventListener("beforehide", handleBeforeHide);
    return () => {
      offcanvasDOMElement.removeEventListener("beforehide", handleBeforeHide);
    };
  }, []);

  const handleBeforeHide = (e) => {
    if (e.target === offcanvasRef.current) {
      setNumber("");
      setEmail("");
      setCopied(false);
    }
  };

  const handleInput = (key, value) => {
    switch (key) {
      case "phone":
        setNumber(value);
        break;
      case "email":
        setEmail(value);
        break;
      default:
        break;
    }
  };

  const handleCopy = async (e) => {
    e.preventDefault();

    await navigator.clipboard.writeText(
      `https://guide.ruebarue.com/destination/${shared?.destination_id || ""}`
    );

    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  const handleShare = async (evt, key) => {
    evt.preventDefault();

    let payload = {
      guide_type: "destination",
      guide_id: shared.destination_id,
    };

    switch (key) {
      case "phone":
        if (phone === "") return;
        payload = {
          ...payload,
          send_type: "sms",
          recipient: phone,
        };
        break;
      case "email":
        if (email === "") return;
        payload = {
          ...payload,
          send_type: "email",
          recipient: email,
        };
        break;
      default:
        break;
    }

    const result = await shareGuide(payload);
    if (!!result) {
      UIkit.modal("#shareDestinationGuide-modal").hide();
      UIkit.notification("Area Guide Sent", "success");
    } else {
      UIkit.notification("Failed Sending Area Guide", "error");
    }
  };

  return (
    <div
      id="shareDestinationGuide-modal"
      className="uk-modal-full hms-modals hms-modals-small uk-modal uk-open uk-dark"
      uk-offcanvas="overlay: true; flip: true;"
      ref={offcanvasRef}
    >
      <div className="uk-offcanvas-bar">
        <div className="hms-modals-main">
          <button
            className="uk-offcanvas-close uk-close-large"
            type="button"
            uk-close="true"
          ></button>
          <div className="uk-modal-header">
            <h2 className="uk-modal-title">Share Area Guide</h2>
          </div>
          <div className="uk-modal-body">
            <div className="add-contact-form">
              <form
                className="uk-grid-small"
                data-uk-grid
                onSubmit={handleCopy}
              >
                <div className="uk-width-1-1">
                  <div className="uk-width-3-4 uk-display-inline-block">
                    <label className="uk-form-label">Area Guide URL</label>
                    <div className="uk-form-controls">
                      <input
                        className="uk-input"
                        type="text"
                        placeholder=""
                        value={`https://guide.ruebarue.com/destination/${
                          shared?.destination_id || 0
                        }`}
                        disabled
                      />
                    </div>
                  </div>
                  <button
                    className="uk-button hms-btn hms-red-btn uk-display-inline-block uk-margin-left"
                    type="submit"
                  >
                    {copied ? "Copied!" : "Copy"}
                  </button>
                </div>
              </form>

              <form
                className="uk-grid-small"
                data-uk-grid
                onSubmit={(e) => handleShare(e, "phone")}
              >
                <div className="uk-width-1-1">
                  <div className="uk-width-3-4 uk-display-inline-block">
                    <label className="uk-form-label">Text Area Guide</label>
                    <div className="uk-form-controls">
                      <input
                        className="uk-input"
                        placeholder="Mobile Number"
                        value={phone}
                        onInput={(e) => handleInput("phone", e.target.value)}
                      />
                    </div>
                  </div>
                  <button
                    className="uk-button hms-btn hms-red-btn uk-display-inline-block uk-margin-left"
                    type="submit"
                  >
                    Share
                  </button>
                </div>
              </form>

              <form
                className="uk-grid-small"
                data-uk-grid
                onSubmit={(e) => handleShare(e, "email")}
              >
                <div className="uk-width-1-1">
                  <div className="uk-width-3-4 uk-display-inline-block">
                    <label className="uk-form-label">Email Area Guide</label>
                    <div className="uk-form-controls">
                      <input
                        className="uk-input"
                        placeholder="Email Address"
                        value={email}
                        onInput={(e) => handleInput("email", e.target.value)}
                      />
                    </div>
                  </div>
                  <button
                    className="uk-button hms-btn hms-red-btn uk-display-inline-block uk-margin-left"
                    type="submit"
                  >
                    Share
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DestinationShare;
