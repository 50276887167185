import React, {
  useContext,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from "react";
import { useHistory } from "react-router-dom";
import SMSEmailInputBox from "../../common/sms-email-inputbox";
import { useForm } from "react-hook-form";
import { AppContexts } from "../../../providers";
import UIkit from "uikit";

const defaultValues = {
  method: "sms",
  body: "",
  subject: "",
};

const Message = () => {
  const { register, handleSubmit, getValues, setValue, watch, reset } = useForm(
    {},
  );
  const {
    contact,
    getContacts,
    sendMessageToContacts,
    flagIsProcessing,
    selectedContacts,
    batchFlag,
  } = useContext(AppContexts.ContactsContext);
  const { get, settings } = useContext(AppContexts.SettingsContext);
  const history = useHistory();

  const handleSelectedResponse = (response) => {
    setValue("body", response);
    UIkit.dropdown("#messages-response").hide(0);
  };

  const body = useMemo(() => getValues("body"), [watch("body")]);
  const method = useMemo(() => getValues("method"), [watch("method")]);

  useEffect(() => {
    get(["branding"]);
  }, []);

  useEffect(() => {
    Object.keys(defaultValues).forEach((formname) => {
      setValue(formname, defaultValues[formname]);
    });
  }, [selectedContacts, contact]);

  useEffect(() => {
    if (settings && settings.branding) {
      setValue("from_name", settings.branding?.from_name);
      setValue("from_email", settings.branding?.from_email);
    }
  }, [settings]);

  const handleSend = async (form_data) => {
    let contacts = [];
    if (batchFlag) {
      contacts = selectedContacts.map((val) => {
        let { id, formatted_phone, email, first_name, last_name } = val;
        return { id, formatted_phone, email, first_name, last_name };
      });
    } else {
      contacts = [
        {
          id: contact.id,
          formatted_phone: contact.formatted_phone,
          email: contact.email,
          first_name: contact.first_name,
          last_name: contact.last_name,
        },
      ];
    }
    let noPhone = contacts.filter((contact) => !contact.formatted_phone);
    let noEmail = contacts.filter((contact) => !contact.email);
    if (form_data.method === "sms" && !!noPhone.length) {
      UIkit.notification("The phone number is missing", "error");
      return;
    }
    if (form_data.method === "email" && !!noEmail.length) {
      UIkit.notification("The email is missing", "error");
      return;
    }
    let result = await sendMessageToContacts(contacts, form_data);
    if (result) {
      UIkit.modal("#modal-contact-message").hide();
      UIkit.notification("Message Sent Successfully", "success");
      await getContacts();
      reset();
      setValue("method", "sms");
    }
  };

  const handleClose = useCallback(() => {
    Object.keys(defaultValues).forEach((formname) => {
      setValue(formname, defaultValues[formname]);
    });
  }, []);

  return (
    <div
      id="modal-contact-message"
      className="uk-modal-full hms-modals uk-modal uk-open uk-dark"
      uk-offcanvas="flip: true; overlay: true"
    >
      <div className="uk-offcanvas-bar">
        <div className="hms-modals-main">
          <button
            className="uk-offcanvas-close uk-close-large"
            type="button"
            uk-close="true"
          ></button>
          <div className="uk-modal-header">
            <h2 className="uk-modal-title">Send a Message</h2>
          </div>
          <div className="uk-modal-body">
            <div className="add-contact-form">
              <form
                className="uk-grid-small"
                uk-grid="true"
                onSubmit={handleSubmit(handleSend)}
              >
                <div className="uk-width-1-1">
                  <div className="uk-grid uk-form-controls">
                    <label className="uk-margin-right">
                      <input
                        type="radio"
                        className="uk-radio"
                        name="send-type-radio"
                        value="sms"
                        {...register("method")}
                      />{" "}
                      SMS
                    </label>
                    <label>
                      <input
                        type="radio"
                        className="uk-radio"
                        name="send-type-radio"
                        value="email"
                        {...register("method")}
                      />{" "}
                      EMAIL
                    </label>
                  </div>
                </div>
                {method == "email" && (
                  <>
                    <div className="uk-width-1-1">
                      <div className="uk-width-1-2@s">
                        <label className="uk-form-label" htmlFor="txt-subject">
                          Subject
                        </label>
                        <div className="uk-form-controls">
                          <input
                            className="uk-input"
                            id="txt-subject"
                            type="text"
                            {...register("subject")}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="uk-width-1-1">
                      <div className="uk-width-1-2@s">
                        <label className="uk-form-label" htmlFor="txt-name">
                          From Name
                        </label>
                        <div className="uk-form-controls">
                          <input
                            className="uk-input"
                            id="txt-name"
                            type="text"
                            {...register("from_name")}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="uk-width-1-1">
                      <div className="uk-width-1-2@s">
                        <label className="uk-form-label" htmlFor="txt-email">
                          From Email Address
                        </label>
                        <div className="uk-form-controls">
                          <input
                            className="uk-input"
                            id="txt-email"
                            type="text"
                            {...register("from_email")}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div className="uk-width-1-1">
                  <SMSEmailInputBox
                    value={body || ""}
                    macros={true}
                    limit={method === "sms"}
                    macrosOrResponse={false}
                    onChange={(e) => setValue("body", e.target.value)}
                    onSavedReply={handleSelectedResponse}
                  />
                </div>
                <div>
                  <button
                    className="uk-button hms-btn hms-red-btn"
                    type="submit"
                  >
                    {flagIsProcessing == true ? (
                      <div data-uk-spinner="ratio: 0.5;"></div>
                    ) : (
                      "Send"
                    )}
                  </button>
                  <button
                    className="uk-button hms-btn uk-offcanvas-close"
                    onClick={handleClose}
                    type="button"
                  >
                    Cancel
                  </button>
                  {!!contact.thread_id && (
                    <button
                      className="uk-button hms-btn hms-orange-btn uk-margin-small-bottom"
                      onClick={() =>
                        history.push(`/messages/${contact.thread_id}`)
                      }
                      type="button"
                    >
                      View Conversation
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Message;
