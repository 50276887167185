import { useState, useEffect, useContext } from "react";
import { loadConnectAndInitialize } from "@stripe/connect-js";

import { AppContexts } from "../../../providers";

export const useStripeConnect = (connectedAccountId) => {
  const { linkAccount } = useContext(AppContexts.StripeContext);

  const [stripeConnectInstance, setStripeConnectInstance] = useState();

  useEffect(() => {
    if (connectedAccountId) {
      const fetchClientSecret = async () => {
        // const response = await fetch("/account_session", {
        //   method: "POST",
        //   headers: {
        //     "Content-Type": "application/json",
        //   },
        //   body: JSON.stringify({
        //     account: connectedAccountId,
        //   }),
        // });

        const response = await linkAccount({
          account: connectedAccountId,
        });

        if (!response?.data?.client_secret) {
          // Handle errors on the client side here
          const { error } = await response.data;
          throw ("An error occurred: ", error);
        } else {
          const { client_secret: clientSecret } = await response.data;
          return clientSecret;
        }
      };

      setStripeConnectInstance(
        loadConnectAndInitialize({
          publishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
          fetchClientSecret,
          appearance: {
            overlays: "dialog",
            variables: {
              colorPrimary: "#635BFF",
            },
          },
        }),
      );
    }
  }, [connectedAccountId]);

  return stripeConnectInstance;
};

export default useStripeConnect;
