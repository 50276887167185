// Import base components
import { httpV2 } from "../http.js";

const WorkOrderService = {
  selectWorkOrderList: () => httpV2.get("/workorders"),
  insertWorkOrder: (order) => httpV2.post("/workorders", order),
  updateWorkOrder: (id, obj) => httpV2.put(`/workorders/${id}`, obj),
  deleteWorkOrder: (id) => httpV2.delete(`/workorders/${id}`),
  getWorkOrder: (id, slug = undefined) => {
    if (slug) {
      return httpV2.get(`/workorders/${slug}/${id}`);
    } else {
      return httpV2.get(`/workorders/${id}`);
    }
  },
  getAttributes: (slug, types) => {
    if (slug) {
      return httpV2.get(
        `/workorders/attributes?slug=${slug}&types=${types.join(",")}`
      );
    }
    return httpV2.get(`/workorders/attributes?types=${types.join(",")}`);
  },
  getWorkOrders: (slug = undefined, criteria) => {
    const queryParams = new URLSearchParams(criteria).toString();
    const url = slug ? `/workorders/${slug}` : "/workorders";
    return httpV2.get(`${url}?${queryParams}`);
  },
  commentWorkOrder: (id, data, slug = undefined) => {
    if (slug) {
      return httpV2.post(`/workorders/${slug}/${id}/comment`, data);
    } else {
      return httpV2.post(`/workorders/${id}/comment`, data);
    }
  },
  getAssigners: (slug = "") => httpV2.get(`/workorders/assigners?slug=${slug}`),
  getTags: (slug = "") => httpV2.get(`workorders/tags?slug=${slug}`),
};

export default WorkOrderService;
