// Import package components
import { useContext, useCallback, useEffect, useMemo, useState } from "react";
import { Icon, Image, Link } from "uikit-react";
import UIkit from "uikit";

import { AppContexts } from "../../providers";
import AccessoryItemAdd, {
  MODAL_NAME as ACCESSORY_ITEM_MODAL_NAME,
} from "./add";
import classnames from "classnames";
import Loading from "../common/Loading";
import Markdown from "../common/Markdown";
import AccessoryDelete from "./delete";
import { FormInputWrapper } from "../../ui";

import {
  DndContext,
  DragOverlay,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";

import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";

import { CSS } from "@dnd-kit/utilities";

const AccessoryItem = () => {
  const {
    accessories,
    retrieve,
    selectedTab,
    setSelectedTab,
    setAccessory,
    initAccessory,
    deleteAccessory,
    updateTabOrder,
  } = useContext(AppContexts.AccessoryContext);

  const { get, set, settings, setSettings } = useContext(
    AppContexts.SettingsContext,
  );

  const [loading, setLoading] = useState(true);
  const [deleted, setDeleted] = useState(null);
  const [reorderTarget, setReorderTarget] = useState(null);
  const [accessoryTabs, setAccessoryTabs] = useState([]);

  const sensors = useSensors(useSensor(PointerSensor));

  let { accessory } = useMemo(() => {
    return {
      accessory: settings.accessories || { tab_order: [], accessory_tabs: [] },
    };
  }, [settings]);

  useEffect(() => {
    setAccessoryTabs(settings.accessories?.accessory_tabs || []);
  }, [settings]);

  let filteredTabs = useMemo(() => {
    return accessoryTabs.filter((t) => true);
  }, [accessoryTabs]);

  let tabOrder = useMemo(() => {
    return accessory?.tab_order || [];
  }, [filteredTabs]);

  let filteredItems = useMemo(() => {
    return (selectedTab?.ordering || [])
      .map((id) => {
        return accessories.find((a) => a.id === id);
      })
      .filter((a) => {
        return !!a;
      });
  }, [accessories, selectedTab]);

  useEffect(() => {
    if (!selectedTab && !!accessory && !!accessoryTabs) {
      // let slugs = (filteredTabs || []).map((t) => t.slug);
      // let orders = settings?.accessory.tab_order.filter(
      //   (o) => slugs.indexOf(o) > -1,
      // );

      // let tab = (filteredTabs || []).find((t) => t.slug === orders[0]);
      // setSelectedTab(tab);

      let order = accessory.tab_order || [];

      for (var i = 0; i < order.length; i++) {
        let tab = filteredTabs.find((t) => t.id === order[i]);

        if (!!tab) {
          setSelectedTab(tab);
          break;
        }
      }
    }
  }, [filteredTabs]);

  useEffect(async () => {
    document.title = "Extras - RueBaRue";

    document.body.style.overflow = "unset";
    setSelectedTab(null);

    await Promise.all([get(["accessories", "tags"])]);
    await Promise.all([retrieve()]);
    setLoading(false);
  }, []);

  const handleSwitch = useCallback((item) => {
    setAccessory(item);
  }, []);

  const getImage = (item) => {
    if (item.attachments && item.attachments.length > 0)
      return item.attachments[0].url;
    return "https://guide.ruebarue.com/images/rbr-placeholder-md.jpeg";
  };

  const toPrice = (price) => {
    return (+(Math.round(price / 100 + "e+2") + "e-2")).toFixed(2);
  };

  const handleDelete = async () => {
    if (!deleted) {
      return;
    }

    let [success, error] = await deleteAccessory(deleted.id);

    setDeleted(null);

    return [success, error];
  };

  const handleReorderStart = (e) => {
    setReorderTarget(
      filteredItems.find((t) => {
        return t.id === e.active.id;
      }),
    );
  };

  const handleReorder = async (e) => {
    const { active, over } = e;

    if (over !== null && active.id !== over.id) {
      const oldIndex = filteredItems.findIndex((t) => t.id === active.id);
      const newIndex = filteredItems.findIndex((t) => t.id === over.id);
      const reordered = arrayMove(filteredItems, oldIndex, newIndex);

      console.log(reordered);
      console.log(selectedTab);

      let updated = await updateTabOrder(
        selectedTab.id,
        reordered.map((a) => a.id),
      );

      if (!!updated.error) {
        console.log("Error updating tabs:", updated.error);
      }

      let tabIdx = accessoryTabs.findIndex((t) => t.id === updated.id);
      if (tabIdx !== -1) {
        accessoryTabs[tabIdx] = updated;
        selectedTab.ordering = [...updated.ordering];

        setAccessoryTabs([...accessoryTabs]);
        setSelectedTab({ ...selectedTab });
      }
    }

    setReorderTarget(null);
  };

  const ProductBlock = ({ item }) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({ id: item.id });

    const style = {
      transform: CSS.Translate.toString(transform),
      transition,
      opacity: isDragging ? 0 : 1,
    };

    return (
      <div
        id={item.id}
        style={style}
        ref={setNodeRef}
        className="uk-card uk-card-small uk-card-default hms-property-card"
      >
        <div className="uk-flex">
          <div className="hms-property-card-body uk-flex-1 add-on">
            <div className="hms-property-card-content accessory-card-content">
              <div className="hms-property-thumbnail-title-price">
                <div className="hms-property-thumbnail">
                  <img src={getImage(item)} />
                </div>
                <div className="hms-property-title-price">
                  <h3>{item.label}</h3>
                  <div className="hms-property-price">
                    ${toPrice(item.price || 0)}
                  </div>

                  <div>
                    <Markdown input={item.description} />
                  </div>
                </div>
              </div>
              <div className="uk-width-1-1">
                <div className="uk-flex">
                  {(item.variants || []).map((v) => (
                    <div className="uk-flex uk-flex-column">
                      <div className="uk-form-label">{v.label}</div>
                      {(v.options || []).map((o) => (
                        <div className="uk-text-small uk-margin-remove">
                          <span>{o.label}</span> -{" "}
                          <span>${toPrice(o.price)}</span>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
                <div className="fine-print-text">
                  <Markdown input={item.specifications} />
                </div>
                <div className="extras-settings">
                  <ul class="uk-list">
                    <li>
                      <span class="availability">Availability:</span>
                      {
                        {
                          "-3": " Up To 3 Days Before Check-In",
                          "-2": " Up To 2 Days Before Check-In",
                          "-1": " Up To 1 Day Before Check-In",
                          0: " Up To Day Of Check-In",
                          99998: " Up To Day of Check-Out",
                          99999: " Forever",
                        }[item.availability]
                      }
                    </li>
                    <li>
                      <input
                        type="checkbox"
                        className="uk-checkbox"
                        disabled
                        checked={item.is_taxable}
                      />{" "}
                      Is Taxable
                    </li>
                    <li>
                      <input
                        type="checkbox"
                        className="uk-checkbox"
                        disabled
                        checked={item.date_required}
                      />{" "}
                      Show Date When Required Field
                    </li>
                    <li>
                      <input
                        type="checkbox"
                        className="uk-checkbox"
                        disabled
                        checked={item.quantity_required}
                      />{" "}
                      Show Quantity Field
                    </li>
                    <li>
                      <input
                        type="checkbox"
                        className="uk-checkbox"
                        disabled
                        checked={item.special_instructions_required}
                      />{" "}
                      Show Special Instructions Field
                    </li>
                  </ul>
                  <div className="hms-guest-tags">
                    <div className="guest-tags">
                      {(item.tags || []).map((tag, idx) => (
                        <div className="uk-inline" key={idx}>
                          {tag.name}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="hms-property-card-btn guide-actions">
            <div>
              <button
                className="uk-button"
                uk-toggle={`target: #${ACCESSORY_ITEM_MODAL_NAME}`}
                uk-tooltip="Edit Product"
                onClick={() => handleSwitch(item)}
              >
                <Image src="/images/edit-icon.svg" />
              </button>
            </div>{" "}
            <hr />
            <div className="uk-sortable-handle">
              <button
                className="uk-button hms-simple-icon-btn drag-handle"
                uk-tooltip="title: Move; delay: 500"
                {...listeners}
                {...attributes}
              >
                <Image src="../images/move.svg" />
              </button>
            </div>
            <hr />
            <div>
              <button
                className="uk-button"
                onClick={() => setDeleted(item)}
                uk-toggle="target: #accessory-item-delete"
                uk-tooltip="Delete Product"
              >
                <Image src="/images/delete-icon.svg" />
              </button>
            </div>
            <hr />
          </div>
        </div>
      </div>
    );
  };

  const AccessoryTabs = ({ tabs }) => {
    return (
      <div className="master-guide-tab">
        {tabs.map((tab, idx) => (
          <div className="master-guide-list" key={`tab-${idx}`}>
            <div
              key={idx}
              className={classnames("", {
                "master-active": tab?.id === selectedTab?.id,
              })}
              style={{ textAlign: "center" }}
              onClick={() => setSelectedTab(tab)}
            >
              <span
                tabIndex="0"
                style={{
                  cursor: "initial",
                  display: "inline-block",
                  backgroundColor: "inherit",
                }}
              >
                {tab?.label}
              </span>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      {loading == true ? (
        <Loading />
      ) : (
        <section id="master-property-template">
          <div id="hms-page-title">
            <div className="uk-flex uk-flex-between uk-flex-middle">
              <h1 className="uk-heading-small">Extras Guide</h1>
            </div>
          </div>

          <div id="hms-main-body">
            <div className="hms-property-tabs uk-flex uk-flex-between uk-flex-middle">
              <AccessoryTabs
                tabs={tabOrder
                  .map((o) => filteredTabs.find((t) => o === t.id))
                  .filter((t) => !!t)}
              />
            </div>

            {filteredTabs.length > 0 ? (
              <div className="uk-flex uk-flex-between uk-flex-top">
                <div className="hms-guest-btn">
                  <button
                    className="uk-button hms-btn hms-red-btn"
                    uk-toggle={`target: #${ACCESSORY_ITEM_MODAL_NAME}`}
                    onClick={initAccessory}
                  >
                    <span uk-icon="icon: plus; ratio: 0.7"></span> Product
                  </button>
                </div>
              </div>
            ) : (
              <div className="uk-flex uk-flex-top">
                <span>No Tabs Found. Please add Extras product tabs</span>
                <Link className="hms-link-to" href="/settings/extras">
                  here.
                </Link>
              </div>
            )}

            <div className="uk-flex uk-flex-top">
              <div className="uk-width-2-3@s">
                <DndContext
                  sensors={sensors}
                  collisionDetection={closestCenter}
                  onDragStart={handleReorderStart}
                  onDragEnd={handleReorder}
                >
                  <SortableContext
                    items={filteredItems}
                    strategy={verticalListSortingStrategy}
                  >
                    {filteredItems.map((item) => (
                      <ProductBlock key={`${item.id}`} item={item} />
                    ))}
                  </SortableContext>
                  <DragOverlay>
                    {!!reorderTarget ? (
                      <ProductBlock item={reorderTarget} />
                    ) : null}
                  </DragOverlay>
                </DndContext>
              </div>
            </div>
          </div>
        </section>
      )}

      <AccessoryItemAdd />
      <AccessoryDelete accessory={deleted} onDelete={handleDelete} />
    </>
  );
};

export default AccessoryItem;
