// Import package components
import { useContext, useCallback, useEffect, useMemo, useState } from "react";
import { Image } from "uikit-react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { AppContexts } from "../../../providers";
import MasterConfirm from "../../common/Confirm";
import ImageInput from "../../common/ImageInput";

const schema = yup.object().shape({
  title: yup.string().required(),
  body: yup.string().required(),
});

const rgba2hex = (rgba) => {
  if (rgba[0] === "#") {
    return rgba;
  }
  rgba = rgba.match(
    /^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i,
  );
  return rgba && rgba.length === 4
    ? "#" +
        ("0" + parseInt(rgba[1], 10).toString(16)).slice(-2) +
        ("0" + parseInt(rgba[2], 10).toString(16)).slice(-2) +
        ("0" + parseInt(rgba[3], 10).toString(16)).slice(-2)
    : "";
};

const defaultValues = {
  company_name: "",
  from_name: "",
  from_email: "",
  bcc_email: "",
  logo_image_url: "",
  logo_url: "",
  color_1: "",
  color_2: "",
  color_3: "",
  color_4: "",
  color_5: "",
  custom_url: "",
  hide_email_logo: false,
};

const defaultColors = {
  color_1: "#3178ac",
  color_2: "#e66656",
  color_3: "#ffffff",
  color_4: "#f5f5f5",
  color_5: "#e66656",
};

const Branding = () => {
  const { settings, set, loading, active } = useContext(
    AppContexts.SettingsContext,
  );
  const utilContext = useContext(AppContexts.UtilContext);
  const { user } = useContext(AppContexts.AuthContext);

  const { register, handleSubmit, setValue, getValues, watch } = useForm();

  const [dirty, setDirty] = useState(false);
  const [upload, setUpload] = useState(null);

  const [branding, setBranding] = useState({
    account_id: 0,
    company_name: "",
    from_name: "",
    from_email: "",
    bcc_email: "",
    logo_image_url: "",
    logo_url: "",
    color_1: "#3178ac",
    color_2: "#e66656",
    color_3: "#ffffff",
    color_4: "#f5f5f5",
    color_5: "#e66656",
    custom_url: "",
    hide_email_logo: false,
  });

  const [destination, setDestination] = useState({
    account_id: 0,
    tab_order: "faq,restaurants,attractions,essentials,directions,tours",
    tip_title: "Your host says",
    show_guide_title: false,
    show_guide_distance: false,
    show_embed_distance: false,
    show_embed_thumbnail: false,
    show_guide_home: true,
    show_embed_home: false,
    show_embed_hours: true,
    show_embed_menus: true,
    show_embed_reviews: true,
    show_embed_website: true,
    show_embed_phone: true,
    show_embed_directions: true,
    show_welcome_guide: true,
    embed_limit: 0,
  });

  useEffect(() => {
    document.title = "Settings | Branding - RueBaRue";
    setBranding(settings.branding || {});
    setDestination(settings.destination || { tab_order: "" });
  }, [settings]);

  let logo = useMemo(() => {
    if (!branding.logo_image_url) {
      return null;
    }

    return {
      type: "image",
      url: branding.logo_image_url,
    };
  }, [branding]);

  // const color_1 = useMemo(() => getValues("color_1"), [watch("color_1")]);
  // const color_2 = useMemo(() => getValues("color_2"), [watch("color_2")]);
  // const color_3 = useMemo(() => getValues("color_3"), [watch("color_3")]);
  // const color_4 = useMemo(() => getValues("color_4"), [watch("color_4")]);
  // const color_5 = useMemo(() => getValues("color_5"), [watch("color_5")]);

  // const hide_email_logo = useMemo(() => getValues("color_5"), [watch("color_5")]);
  // const color_5 = useMemo(() => getValues("color_5"), [watch("color_5")]);
  // const color_5 = useMemo(() => getValues("color_5"), [watch("color_5")]);

  // const settingsBranding = useMemo(() => {
  //   if (!settings) {
  //     return defaultValues;
  //   } else {
  //     let result = {};
  //     if (settings.branding) {
  //       Object.keys(settings.branding).forEach((key) => {
  //         if (key.slice(0, 5) === "color") {
  //           result[key] = rgba2hex(settings.branding[key]);
  //         } else {
  //           result[key] = settings.branding[key];
  //         }
  //       });
  //     } else {
  //       Object.keys(defaultBranding).forEach((key) => {
  //         if (key.slice(0, 5) === "color") {
  //           result[key] = rgba2hex(defaultBranding[key]);
  //         } else {
  //           result[key] = defaultBranding[key];
  //         }
  //       });
  //     }
  //     return result;
  //   }
  // }, [settings]);

  // useEffect(() => {
  //   Object.keys(settingsBranding).forEach((key) => {
  //     setValue(key, settingsBranding[key]);
  //   });
  // }, [settingsBranding]);

  const handleUpdate = async () => {
    let uploadUrl = branding.logo_image_url;
    if (upload !== null) {
      uploadUrl = await utilContext.uploadFile(
        upload,
        `users/logos/${user.account_id}`,
      );
    }

    set(["branding", "destination"], {
      branding: { ...branding, logo_image_url: uploadUrl },
      destination: destination,
    }).then((res) => {
      setDirty(false);
      setUpload(null);
    });
  };

  const handleInputChange = (key, e) => {
    console.log(key);

    if (branding.hasOwnProperty(key)) {
      setBranding({ ...branding, [key]: e.target.value });
    }

    if (destination.hasOwnProperty(key)) {
      setDestination({ ...destination, [key]: e.target.value });
    }

    setDirty(true);
  };

  const handleInputToggle = (key, e) => {
    if (branding.hasOwnProperty(key)) {
      setBranding({ ...branding, [key]: !branding[key] });
    }

    if (destination.hasOwnProperty(key)) {
      setDestination({ ...destination, [key]: !destination[key] });
    }

    setDirty(true);
  };

  const handleColorReset = (key) => {
    setBranding({ ...branding, [key]: defaultColors[key] });
    setDirty(true);
  };

  const handleImage = (file) => {
    if (!!file) {
      setUpload(file);
    } else {
      setUpload(null);
      setBranding({ ...branding, logo_image_url: "" });
    }

    setDirty(true);
  };

  // const deleteImage = () => {
  //   setBranding((prev) => ({ ...prev, logo_image_url: "" }));
  //   setUpload(null);
  //   setImage(null);
  // };

  const validateColor = (color) => {
    var s = new Option().style;
    s.color = color;
    return s.color !== "" ? color : "rgba(0, 0, 0, 0)";
  };

  return (
    <li
      className={`setting-tab-content ${
        active === "branding" ? "uk-active" : ""
      }`}
    >
      <div className="uk-card hms-left-tabs-content-card mx-w-665">
        <h2>Branding</h2>
        <form className="uk-grid-small" onSubmit={handleSubmit(handleUpdate)}>
          <div className="uk-width-1-1 uk-margin-bottom hms-headings">
            <h3>Header</h3>
          </div>
          <div className="uk-width-1-1 uk-margin-bottom hms-headings">
            <div className="uk-form-label">
              Company Logo
              <img
                className="info-icon"
                src="/images/info-icon.svg"
                alt="info"
                uk-tooltip="Company Logo for the Guestbook and email messages"
              />
            </div>
            <ImageInput label="" attachment={logo} handler={handleImage} />

            {/*
            <div className="js-upload uk-placeholder hms-file-uploader uk-text-center">
              <div data-uk-form-custom>
                <div>
                  <Image src={getBGImage()} />
                </div>
                <input type="file" multiple onChange={handleImage} />
                <span className="uk-link">{upload?.name}</span>
              </div>
              <div>
                {!(getBGImage() === "images/file-upload.png") && (
                  <MasterConfirm onDelete={deleteImage} />
                )}
              </div>
            </div>
            */}
            <progress
              id="js-progressbar"
              className="uk-progress"
              value="0"
              max="100"
              hidden
            ></progress>
          </div>
          <div className="uk-width-1-2@s uk-margin-bottom">
            <label className="uk-form-label">Company Name </label>
            <img
              className="info-icon"
              src="/images/info-icon.svg"
              alt="info"
              uk-tooltip="Available as [CompanyName] macro"
            />
            <div className="uk-form-controls">
              <input
                className="uk-input"
                type="text"
                placeholder=""
                value={branding.company_name}
                onInput={(e) => handleInputChange("company_name", e)}
              />
            </div>
          </div>
          <div className="uk-width-1-2@s uk-margin-bottom">
            <label className="uk-form-label">Digital Guestbook Logo Link</label>
            <img
              className="info-icon"
              src="/images/info-icon.svg"
              alt="info"
              uk-tooltip="The logo on the digital guestbook will go to this page"
            />
            <div className="uk-form-controls">
              <input
                className="uk-input"
                type="text"
                placeholder=""
                value={branding.logo_url}
                onInput={(e) => handleInputChange("logo_url", e)}
              />
            </div>
          </div>
          <div className="uk-width-1-2@s">
            <label className="uk-form-label">Area Guide Tip Title </label>
            <img
              className="info-icon"
              src="/images/info-icon.svg"
              alt="info"
              uk-tooltip="This tip title will be displayed on Area Guide locations when a tip is added"
            />
            <div className="uk-form-controls">
              <input
                className="uk-input"
                type="text"
                placeholder=""
                value={destination.tip_title}
                onInput={(e) => handleInputChange("tip_title", e)}
              />
            </div>
          </div>
          <div className="uk-width-1-1 uk-margin-bottom uk-margin-large-top hms-headings">
            <h3>Email</h3>
          </div>
          <div className="uk-width-1-2@s uk-margin-bottom">
            <label className="uk-form-label">From Name</label>
            <div className="uk-form-controls">
              <input
                className="uk-input"
                type="text"
                placeholder=""
                value={branding.from_name}
                onInput={(e) => handleInputChange("from_name", e)}
              />
            </div>
          </div>
          <div className="uk-width-1-2@s uk-margin-bottom">
            <label className="uk-form-label">From Email Address</label>
            <div className="uk-form-controls">
              <input
                className="uk-input"
                type="email"
                placeholder=""
                value={branding.from_email}
                onInput={(e) => handleInputChange("from_email", e)}
              />
            </div>
          </div>{" "}
          <div className="uk-width-1-1 uk-margin-bottom uk-margin-large-top hms-headings">
            <h3>Colors</h3>
          </div>
          <div className="uk-width-1-1 hms-small-note uk-margin-small-bottom">
            Brand your guides to match your company's color palette.
          </div>
          <div className="uk-width-1-1">
            <div className="uk-flex uk-flex-middle uk-margin-bottom">
              <div className="uk-width-1-3">
                <label className="uk-form-label">Active Tab</label>
                <div className="uk-form-controls setting-color-box">
                  <div className="uk-flex uk-flex-middle">
                    <div
                      className="rbr-color-preview"
                      style={{
                        backgroundColor: validateColor(branding.color_2),
                      }}
                    >
                      <input
                        type="color"
                        name="tabBGColor"
                        id="tabBGColor"
                        value={branding.color_2}
                        onInput={(e) => handleInputChange("color_2", e)}
                      />
                    </div>
                    <input
                      className="setting-color-value"
                      id="tab-BG-color"
                      value={branding.color_2}
                      onInput={(e) => handleInputChange("color_2", e)}
                    />
                  </div>
                </div>
                <button
                  type="button"
                  className="uk-button resetOldColor"
                  data-colorname="#000000"
                  onClick={() => handleColorReset("color_2")}
                >
                  Reset
                </button>
              </div>
              <div className="uk-width-1-3 uk-margin-left">
                <label className="uk-form-label">Tab Text</label>
                <div className="uk-form-controls setting-color-box">
                  <div className="uk-flex uk-flex-middle">
                    <div
                      className="rbr-color-preview"
                      style={{
                        backgroundColor: validateColor(branding.color_3),
                      }}
                    >
                      <input
                        type="color"
                        name="tabBGColor"
                        id="tabBGColor"
                        value={branding.color_3}
                        onInput={(e) => handleInputChange("color_3", e)}
                      />
                    </div>
                    <input
                      className="setting-color-value"
                      id="tab-BG-color"
                      value={branding.color_3}
                      onInput={(e) => handleInputChange("color_3", e)}
                    />
                  </div>
                </div>
                <button
                  type="button"
                  className="uk-button resetOldColor"
                  data-colorname="#ffffff"
                  onClick={() => handleColorReset("color_3")}
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
          <div className="uk-width-1-1">
            <div className="uk-flex uk-flex-middle">
              <div className="uk-width-1-3">
                <label className="uk-form-label">Page Background</label>
                <div className="uk-form-controls setting-color-box">
                  <div className="uk-flex uk-flex-middle">
                    <div
                      className="rbr-color-preview"
                      style={{
                        backgroundColor: validateColor(branding.color_4),
                      }}
                    >
                      <input
                        type="color"
                        name="tabBGColor"
                        id="tabBGColor"
                        value={branding.color_4}
                        onInput={(e) => handleInputChange("color_4", e)}
                      />
                    </div>
                    <input
                      className="setting-color-value"
                      id="tab-BG-color"
                      value={branding.color_4}
                      onInput={(e) => handleInputChange("color_4", e)}
                    />
                  </div>
                </div>
                <button
                  type="button"
                  className="uk-button resetOldColor"
                  data-colorname="#ececef"
                  onClick={() => handleColorReset("color_4")}
                >
                  Reset
                </button>
              </div>
              <div className="uk-width-1-3 uk-margin-left">
                <label className="uk-form-label">Distance</label>
                <div className="uk-form-controls setting-color-box">
                  <div className="uk-flex uk-flex-middle">
                    <div
                      className="rbr-color-preview"
                      style={{
                        backgroundColor: validateColor(branding.color_5),
                      }}
                    >
                      <input
                        type="color"
                        name="tabBGColor"
                        id="tabBGColor"
                        value={branding.color_5}
                        onInput={(e) => handleInputChange("color_5", e)}
                      />
                    </div>
                    <input
                      className="setting-color-value"
                      id="tab-BG-color"
                      value={branding.color_5}
                      onInput={(e) => handleInputChange("color_5", e)}
                    />
                  </div>
                </div>
                <button
                  type="button"
                  className="uk-button resetOldColor"
                  data-colorname="#272841"
                  onClick={() => handleColorReset("color_5")}
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
          <div className="uk-width-1-1 uk-margin-bottom uk-margin-top hms-headings">
            <h3>Modules</h3>
          </div>
          <div className="uk-width-1-1 m-w-300">
            <div className="uk-flex uk-flex-between uk-flex-middle hms-setting-cb-border">
              <div>Distance to Places</div>
              <div className="autosend-checkbox">
                <label htmlFor="distanceToPlaces" className="setting-switch">
                  <input
                    type="checkbox"
                    id="distanceToPlaces"
                    checked={destination.show_guide_distance}
                    onChange={(e) =>
                      handleInputToggle("show_guide_distance", e)
                    }
                  />
                  <span className="setting-slider setting-round"></span>
                </label>
              </div>
            </div>
          </div>
          <div className="uk-width-1-1 m-w-300">
            <div className="uk-flex uk-flex-between uk-flex-middle hms-setting-cb-border">
              <div>Hide Logo in Emails</div>
              <div className="autosend-checkbox">
                <label htmlFor="hideLogoEmail" className="setting-switch">
                  <input
                    type="checkbox"
                    id="hideLogoEmail"
                    checked={branding.hide_email_logo}
                    onChange={(e) => handleInputToggle("hide_email_logo", e)}
                  />
                  <span className="setting-slider setting-round"></span>
                </label>
              </div>
            </div>
          </div>
          <div className="uk-width-1-1 m-w-300">
            <div className="uk-flex uk-flex-between uk-flex-middle hms-setting-cb-border">
              <div>Property Icon on Map</div>
              <div className="autosend-checkbox">
                <label htmlFor="propertyIconOnMap" className="setting-switch">
                  <input
                    type="checkbox"
                    id="propertyIconOnMap"
                    checked={destination.show_guide_home}
                    onChange={(e) => handleInputToggle("show_guide_home", e)}
                  />
                  <span className="setting-slider setting-round"></span>
                </label>
              </div>
            </div>
          </div>
          <div className="uk-width-1-1 uk-margin-top">
            <button
              className="uk-button hms-btn hms-red-btn"
              type="submit"
              disabled={loading}
            >
              {loading ? (
                <div data-uk-spinner="ratio: 0.5;"></div>
              ) : dirty ? (
                "Save"
              ) : (
                "Saved"
              )}
            </button>
          </div>
        </form>
      </div>
    </li>
  );
};

export default Branding;
