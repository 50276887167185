import React, {
  useMemo,
  useEffect,
  useState,
  useContext,
  useCallback,
} from "react";
import { InputContainer } from "uikit-react";
import { Form, FormRow, FormInputWrapper } from "../../../ui";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import classnames from "classnames";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import diff from "diff-arrays-of-objects";
import { AppContexts } from "../../../providers";
import UIkit from "uikit";
import MasterConfirm from "../../common/Confirm";

const schema = yup.object().shape({
  question: yup.string().required(),
  question_type: yup.string().required(),
});

const defaultQuestion = {
  question: "",
  help_text: "",
  low_label: "",
  high_label: "",
  question_type: "multiple-choice",
  survey_question_id: "",
  include_other: false,
  deleted: false,
  option_order: null,
  question_options: [],
};

const AddQuestion = () => {
  const [options, setOptions] = useState([]);
  const [optionOrder, setOptionOrder] = useState("");
  const [selectOption, setSelectOption] = useState(-1);
  const {
    register,
    getValues,
    setValue,
    watch,
    clearErrors,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const surveysContext = useContext(AppContexts.SurveysContext);

  useEffect(() => {
    clearErrors();
    if (surveysContext.flagIsNew) {
      reset(defaultQuestion);
    } else if (Object.keys(surveysContext.question).length === 0) {
      clearForms();
      setOptions([]);
      setOptionOrder("");
    } else {
      reset(surveysContext.question);
    }
  }, [surveysContext.question, surveysContext.flagIsNew]);

  useEffect(() => {
    setOptionOrder(
      options?.map((option) => option.survey_question_option_id).join(),
    );
  }, [options]);

  const clearForms = useCallback(() => {
    Object.keys(defaultQuestion).forEach((formname) => {
      setValue(formname, defaultQuestion[formname]);
    });
  }, []);

  const handleRegister = async (form_data) => {
    let qs = {
      ...surveysContext.question,
      survey_id: surveysContext.survey.survey_id,
    };

    if (surveysContext.flagIsNew) {
      qs = {
        ...qs,
        ...defaultQuestion,
        ...form_data,
      };
      qs.question_options = options;
    } else {
      qs = {
        ...qs,
        ...form_data,
        option_order: optionOrder,
        question_options: options,
      };
    }
    surveysContext.setQuestion(qs);
    let result = await surveysContext.upsertQuestion(qs);

    if (result) {
      let sv = { ...surveysContext.survey };
      let idx = sv.questions.findIndex(
        (q) =>
          q.survey_question_id.toString() ===
          result.survey_question_id.toString(),
      );
      idx > -1
        ? (sv.questions[idx] = result)
        : (sv.questions = [...sv.questions, result]);

      surveysContext.setSurvey({
        ...sv,
        question_order: surveysContext.flagIsNew
          ? [sv.question_order, result.survey_question_id.toString()].join(",")
          : sv.question_order,
      });
      surveysContext.setSurveys((prev) =>
        prev.map((indexSurvey) =>
          indexSurvey.survey_id.toString() === result.survey_id.toString()
            ? { ...sv }
            : indexSurvey,
        ),
      );
      UIkit.modal("#modal-question-add").hide();
    }
  };

  const questionType = useMemo(() => {
    let temp = getValues("question_type");
    if (temp == surveysContext.question.question_type) {
      setOptions(
        surveysContext.question.question_options?.filter((opt) => {
          if (opt.deleted == true) return false;
          return true;
        }),
      );
    } else {
      setOptions([]);
    }
    return temp;
  }, [watch("question_type"), surveysContext.question]);

  const handleInsertOption = () => {
    setOptions((prev) => [
      ...prev,
      {
        type: null,
        deleted: false,
        answer: getValues("option_input"),
        custom_answer: "",
      },
    ]);
  };

  const deleteQuestion = (no) => {
    setOptions((prev) => {
      return [
        ...prev.slice(0, no),
        { ...prev[no], deleted: true },
        ...prev.slice(no + 1),
      ];
    });
  };

  useEffect(() => {
    setValue("option_input", "");
  }, [options]);

  const handleClose = useCallback(() => {
    clearErrors();
    if (Object.keys(surveysContext.question).length === 0) {
      clearForms();
      setOptions([]);
    } else {
      reset(surveysContext.question);
    }
  }, [surveysContext.question]);

  const DragHandle = SortableHandle(() => (
    <span style={{ marginInline: "10px" }}>
      <img src="/images/move.svg" />
    </span>
  ));

  const SortableItem = SortableElement(({ opt, no, flag }) => (
    <div>
      <DragHandle />
      {selectOption === no && (
        <>
          <input
            type="text"
            defaultValue={opt.answer}
            {...register(`option_edit${no}`)}
          />
          <span
            uk-icon="icon: check"
            className="survey-icon"
            onClick={() => {
              setOptions((prev) => {
                let temp = prev;
                temp[no] = {
                  ...temp[no],
                  type: null,
                  answer: getValues(`option_edit${no}`),
                  custom_answer: "",
                };
                return temp;
              });
              setSelectOption(-1);
            }}
          ></span>
          <span
            uk-icon="icon: close"
            className="survey-icon"
            onClick={() => setSelectOption(-1)}
          ></span>
        </>
      )}
      {selectOption !== no && (
        <>
          <label>
            {flag === "checkboxes" && (
              <input className="uk-checkbox" type="checkbox" name="option" />
            )}
            {flag === "multiple-choice" && (
              <input type="checkbox" className="uk-radio" name="option" />
            )}{" "}
            {opt.answer}
          </label>
          <span className="survey-icon" onClick={() => setSelectOption(no)}>
            <img src="/images/edit-icon.svg" />
          </span>
          <MasterConfirm onDelete={() => deleteQuestion(no)} />
        </>
      )}
    </div>
  ));

  const SortableList = SortableContainer(({ options, flag }) => (
    <div className="uk-width-1-1">
      {options.map((opt, i) => {
        return (
          <SortableItem
            opt={opt}
            no={i}
            flag={flag}
            index={i}
            key={`item-${i}`}
          />
        );
      })}
    </div>
  ));

  const handleReorder = ({ oldIndex, newIndex }) => {
    setOptions((prev) => arrayMoveImmutable(prev, oldIndex, newIndex));
  };

  const optionRenderer = (type) => (
    <>
      {(type === "multiple-choice" || type === "checkboxes") && (
        <>
          <div className="uk-width-1-1 uk-margin-top">
            <div className="uk-flex">
              <div className="autosend-checkbox uk-form-controls">
                <label htmlFor="include_other" className="setting-switch">
                  <input
                    type="checkbox"
                    id="include_other"
                    {...register("include_other")}
                  />
                  <span className="setting-slider setting-round"></span>
                </label>
              </div>
              <div className="m-l-10">
                <div>Include "Other" Checkbox</div>
              </div>
            </div>
          </div>
          <SortableList
            options={(options || []).filter((opt) => {
              if (opt.deleted == true) return false;
              return true;
            })}
            flag={type}
            useDragHandle={true}
            onSortEnd={handleReorder}
          />
          <div className="uk-width-1-1">
            <label className="uk-form-label">Add Option</label>
            <div className="uk-grid uk-form-controls">
              <div className="uk-form-controls uk-width-3-4 uk-margin-small-right">
                <input
                  type="text"
                  className="uk-input"
                  {...register("option_input")}
                />
              </div>
              <span
                uk-icon="icon: check"
                className="survey-icon"
                onClick={handleInsertOption}
              ></span>
              <span
                uk-icon="icon: close"
                className="survey-icon"
                onClick={() => setValue("option_input", "")}
              ></span>
            </div>
          </div>
        </>
      )}
      {(type === "range-5" || type === "range-10") && (
        <>
          <FormRow>
            <FormInputWrapper label="Low Label" size="two">
              <InputContainer>
                <input className="uk-input" {...register("low_label")} />
              </InputContainer>
            </FormInputWrapper>
          </FormRow>
          <FormRow>
            <FormInputWrapper label="High Label" size="two">
              <InputContainer>
                <input className="uk-input" {...register("high_label")} />
              </InputContainer>
            </FormInputWrapper>
          </FormRow>
        </>
      )}
    </>
  );

  return (
    <>
      <div
        id="modal-question-add"
        className="uk-modal-full hms-modals uk-modal uk-open"
        uk-offcanvas="flip: true; overlay: true"
      >
        <div className="uk-offcanvas-bar">
          <div className="hms-modals-main">
            <button
              className="uk-offcanvas-close uk-close-large"
              type="button"
              uk-close="true"
            ></button>
            <div className="uk-modal-header">
              <h2 className="uk-modal-title">
                {surveysContext.flagIsNew ? "Add a" : "Edit"} Question
              </h2>
            </div>
            <div className="uk-modal-body">
              <Form uk-grid="true" onSubmit={handleSubmit(handleRegister)}>
                <FormRow>
                  <FormInputWrapper label="Question*" size="two">
                    <InputContainer>
                      <input
                        className={classnames("uk-input", {
                          " error": errors.question,
                        })}
                        {...register("question")}
                      />
                    </InputContainer>
                  </FormInputWrapper>
                </FormRow>
                <FormRow>
                  <FormInputWrapper label="Help Text" size="two">
                    <InputContainer>
                      <textarea
                        className="uk-textarea"
                        {...register("help_text")}
                      />
                    </InputContainer>
                  </FormInputWrapper>
                </FormRow>
                <div className="uk-width-1-1">
                  <label className="uk-form-label">Question Type*</label>
                  <div className="uk-form-controls uk-width-3-4">
                    <div uk-form-custom="target: > * > span:first-child">
                      <select {...register("question_type")}>
                        <option value="multiple-choice">Multiple Choice</option>
                        <option value="short-answer">Short Answer</option>
                        <option value="checkboxes">Checkboxes</option>
                        <option value="range-5">Range(1-5)</option>
                        <option value="range-10">Range(1-10)</option>
                      </select>
                      <button
                        className="uk-button uk-button-default"
                        type="button"
                        tabIndex="-1"
                      >
                        <span></span>
                        <span
                          uk-icon="icon: chevron-down"
                          className="hms-align-right"
                        ></span>
                      </button>
                    </div>
                  </div>
                </div>
                {optionRenderer(questionType)}
                <div>
                  <button
                    className="uk-button hms-btn hms-red-btn"
                    type="submit"
                    disabled={surveysContext.flagIsProcessing}
                  >
                    {surveysContext.flagIsProcessing == true ? (
                      <div data-uk-spinner="ratio: 0.5;"></div>
                    ) : (
                      "Save"
                    )}
                  </button>
                  <button
                    className="uk-button hms-btn uk-offcanvas-close"
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddQuestion;
