// Import package components
import { useContext } from "react";
import UIkit from "uikit";

// Import base components
import { AppContexts } from "../../../providers";

// Import common components
import Dialog from "../../common/Dialog";

const MasterDelete = () => {
  const { updateSection, selectedSection, selectedTab, setSelectedSection } =
    useContext(AppContexts.RentalsContext);
  const { get, set, settings } = useContext(AppContexts.SettingsContext);
  const { user, refresh } = useContext(AppContexts.AuthContext);

  const handleDelete = async () => {
    let [success, error] = await updateSection({ ...selectedSection, suspended: true });
    if (success) {

      let orders = settings?.welcome_order;
      if (!orders) {
        await get("welcome_order");
        orders = settings["welcome_order"];
      }

      console.log("Selected Section:", selectedTab);
      if (!selectedTab) {
        return UIkit.notification("Failed", "error");
      }

      let selectedOrder = orders.filter((o) => o.type === selectedTab.type)[0];
      console.log("Selected Order", selectedOrder);
      if (!selectedOrder) {
        return UIkit.notification("Failed", "error");
      }

      selectedOrder.ordering = selectedOrder.ordering.filter(
        (o) =>
          o?.toString() !== selectedSection.welcome_guide_question_id?.toString(),
      );
      await set("welcome_order", settings.welcome_order);

      await Promise.all([
        refresh(),
        get(["guestbook", "welcome_order", "welcome_tabs"]),
      ]);

    } else {
      UIkit.notification("Failed", "error");
    }

    UIkit.notification("Removed Successfully", "success");

    UIkit.modal("#master-property-delete").hide();
  };

  return (
    <>
      <Dialog id="master-property-delete">
        <div className="uk-modal-dialog uk-modal-body">
          <button
            className="uk-modal-close-default"
            type="button"
            uk-close="true"
          ></button>
          <h2 className="uk-modal-title">Delete Block</h2>
          <p>
            Are you sure you want to delete?{" "}
            <b>Warning: This step cannot be reversed.</b>
          </p>
          <p className="uk-text-right">
            <button
              className="uk-button hms-btn uk-modal-close uk-modal-delete-cancel"
              type="button"
            >
              Cancel
            </button>
            <button
              className="uk-button hms-btn hms-gray-btn"
              type="button"
              onClick={handleDelete}
            >
              Delete
            </button>
          </p>
        </div>
      </Dialog>
    </>
  );
};

export default MasterDelete;
