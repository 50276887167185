import { useCallback, useContext, useEffect, useState, useMemo } from "react";
import { AppContexts } from "../../../providers";
import { NavLink, useParams } from "react-router-dom";
import { Image, Link, Flex } from "uikit-react";
import { useForm } from "react-hook-form";
import { useCookies } from "react-cookie";

import moment from "moment";
import debounce from "lodash.debounce";
import classnames from "classnames";
import UIkit from "uikit";
import utils from "../../../utils/utils.js";

import AddTextLine from "../../common/AddTextLine";
import RecordsPerPage from "../../common/RecordsPerPage";
import MessageForm from "../forms/message.js";
import RefundForm from "../forms/refund.js";

import CancelModal from "../modals/cancel-order.js";

const OrderDetails = () => {
  const params = useParams();

  const [maximums, setMaximums] = useState({});
  const [pending, setPending] = useState(false);

  const { user } = useContext(AppContexts.AuthContext);
  const { order, setOrder, loading, get, fulfill, capture, cancel } =
    useContext(AppContexts.OrdersContext);

  const statusFlags = ["new", "waiting", "pending", "partial", "fulfilled"];
  const statusNames = {
    new: "New",
    waiting: "Awaiting Customer Response",
    pending: "Pending",
    partial: "Partially Fulfilled",
    fulfilled: "Fulfilled",
  };
  const paymentFlags = [
    "authorized",
    "paid",
    "cancelled",
    "partially_refunded",
    "refunded",
  ];
  const paymentNames = {
    authorized: "Authorized",
    paid: "Paid",
    cancelled: "Cancelled",
    partially_refunded: "Partially Refunded",
    refunded: "Refunded",
  };

  const itemStatusFlags = ["fulfilled", "refunded"];
  const itemStatusNames = {
    "": "Unfulfilled",
    fulfilled: "Fulfilled",
    refunded: "Refunded",
  };

  useEffect(() => {
    let order_number = window.location.pathname.split("/")[2];
    get(order_number);
  }, []);

  const rental = useMemo(() => {
    console.log(order);

    return order?.rental || null;
  }, [order]);

  const reservation = useMemo(() => {
    return order?.guest_reservation || null;
  }, [order]);

  useEffect(() => {
    (order?.accessories || []).map((a) => {
      maximums[a.id] = Math.max(maximums[a.id] || 0, a.quantity || 0);
    });

    setMaximums({ ...maximums });
  }, [order]);

  const status = (order) => {
    let status = order.flags.filter((a) => {
      return statusFlags.includes(a.name);
    })[0];

    if (!status) {
      console.log("Couldn't find status flag:", order.flags);
    }

    return statusNames[status.name] || "";
  };

  const payment_status = (order) => {
    let payment_status = order.flags.filter((a) => {
      return paymentFlags.includes(a.name);
    })[0];

    if (!payment_status) {
      console.log("Couldn't find payment status flag:", order.flags);
    }

    return paymentNames[payment_status.name] || "";
  };

  const item_status = (accessory) => {
    let status = (accessory.flags || []).filter((a) => {
      return itemStatusFlags.includes(a.name);
    })[0];

    return itemStatusNames[status?.name || ""] || "";
  };

  const has_flag = (obj, flag) => {
    return !!(obj.flags || []).find((f) => f.name === flag);
  };

  const fulfill_item = (accessory) => {
    // if (
    //   !(accessory.flags || []).find((f) =>
    //     ["fulfilled", "refunded"].includes(f.name),
    //   )
    // ) {
    fulfill(accessory.id);
    // }
  };

  const allow_updates = () => {
    if (!!order.receipt) {
      return false;
    }

    return true;
  };

  const actual_quantity = (accessory) => {
    if (!!order.receipt) {
      let receipt_item = (order.receipt?.items || []).find(
        (item) => accessory.id === item.accessory_invoice_item_id,
      );

      if (!!receipt_item) {
        return +receipt_item.quantity;
      } else {
        console.log("Error finding receipt item?");
        return +accessory.quantity;
      }
    }

    return +accessory.quantity;
  };

  const update_quantity = (accessory, e) => {
    accessory.quantity = +e.target.value;
    setOrder({ ...order });
  };

  const isFree = () => {
    let refunds = order?.refunds || [];

    return (
      (order.accessories || []).reduce((a, c) => {
        let isRefunded = refunds.find((r) => {
          return r.items.find((i) => i.accessory_invoice_item_id === c.id);
        });

        if (isRefunded) {
          console.log("Is Refunded");
          return a;
        }

        return (a += c.price * c.quantity);
      }, 0) > 0
    );
  };

  const price = () => {
    let total = (order.accessories || []).reduce((a, c) => {
      return (a += c.price * c.quantity);
    }, 0);

    return `$${utils.to_price(total)}`;
  };

  const approve = (e) => {
    if (pending) {
      return;
    }

    e.preventDefault();

    setPending(true);
    capture()
      .then(() => {
        UIkit.notification("Customer card has been charged", "success");
        setPending(false);
      })
      .catch((err) => {
        UIkit.notification("Error processing request", "warning");
        setPending(false);
      });
  };

  const cancelOrder = (e) => {
    if (pending) {
      return;
    }

    setPending(true);
    cancel()
      .then(() => {
        UIkit.notification(
          "This order has been successfully cancelled",
          "success",
        );
        setPending(false);
      })
      .catch((err) => {
        UIkit.notification("Error processing request", "warning");
        setPending(false);
      });
  };

  const handleRefund = (e) => {
    e.preventDefault();
    return;
  };

  return !order ? (
    <section></section>
  ) : (
    <>
      <section>
        <AddTextLine feature="Orders" />
        <div id="hms-page-title">
          <h1 className="uk-heading-small">{`#${order.order_number}`}</h1>
          <div>
            {moment.utc(order.created_at).local().format("MM/DD/YYYY h:mm A")}
          </div>
        </div>
        <div id="hms-main-body">
          <div className="uk-card uk-card-small uk-card-default hms-form-card card-without-filters">
            <div className="uk-overflow-auto">
              <div className="uk-flex uk-flex-between uk-flex-top">
                <div>
                  {/*<form
                    action=""
                    className="uk-form uk-flex uk-flex-middle hms-form"
                  >
                    <div className="uk-inline">
                      <button class="download">
                        <img
                          src="/images/download.svg"
                          uk-tooltip="Download CSV"
                          title=""
                          aria-describedby="uk-tooltip-796"
                          tabindex="0"
                        />
                      </button>
                    </div>
                  </form>*/}
                </div>
              </div>
              <div
                class="uk-grid uk-child-width-1-3@m uk-grid-match uk-margin-top"
                uk-grid
              >
                <div className="order-detail-guest">
                  <div class="">
                    <label class="uk-text-bold">GUEST</label>
                    <div>
                      {[
                        reservation?.first_name || "",
                        reservation?.last_name || "",
                      ].join(" ")}
                    </div>
                    <div class="secondary">
                      {reservation?.phone || ""} / {reservation?.email || ""}
                    </div>
                  </div>
                </div>

                <div>
                  <div class="">
                    <label class="uk-text-bold">CHECK-IN & OUT</label>
                    <div>
                      {`${moment(reservation.check_in, "YYYY-MM-DD").format(
                        "MMM DD, YYYY",
                      )} - ${moment(reservation.check_in, "YYYY-MM-DD").format(
                        "MMM DD, YYYY",
                      )}`}
                    </div>
                  </div>
                </div>

                <div>
                  <div class="">
                    <label class="uk-text-bold">PROPERTY ID/ADDRESS</label>
                    <div>
                      {`${order?.rental?.pms_id || ""} / ${order?.rental?.address || ""}`}
                    </div>
                  </div>
                </div>
              </div>

              <table className="uk-table uk-table-hover uk-table-divider hms-table">
                <thead>
                  <tr>
                    <th>Item</th>
                    <th className="number">Price</th>
                    <th className="number">Quantity</th>
                    <th className="number">Total</th>
                    <th>Fulfillment Status</th>
                    <th>Instructions</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {(order?.accessories || []).map((a) => (
                    <tr>
                      <td>{a.label}</td>
                      <td className="number">${utils.to_price(a.price)}</td>
                      <td className="number">
                        <div class="uk-form-controls">
                          <select
                            class="uk-select uk-form-width-small"
                            id="quantity"
                            disabled={!allow_updates()}
                            onChange={(e) => {
                              update_quantity(a, e);
                            }}
                          >
                            {Array.apply(
                              null,
                              Array((maximums[a.id] || 0) + 1),
                            ).map((_, idx) => {
                              return (
                                <option
                                  value={idx}
                                  selected={idx === actual_quantity(a)}
                                >
                                  {idx}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </td>
                      <td className="number">
                        ${utils.to_price(a.price * a.quantity)}
                      </td>
                      <td>
                        <div className="hms-booking-tag">{item_status(a)}</div>
                      </td>
                      <td>{a.instructions}</td>
                      <td class="hms-action-btn">
                        <div class="uk-flex uk-flex-top">
                          <div class="uk-inline">
                            <button
                              class="uk-button"
                              uk-tooltip="Details"
                              title=""
                              aria-describedby="uk-tooltip-755"
                              aria-expanded="false"
                              onClick={() => {
                                fulfill_item(a);
                              }}
                            >
                              <img
                                data-src={
                                  has_flag(a, "fulfilled")
                                    ? "/images/fulfilled.svg"
                                    : "/images/unfulfilled.svg"
                                }
                                data-uk-img=""
                                loading="lazy"
                                src={
                                  has_flag(a, "fulfilled")
                                    ? "/images/fulfilled.svg"
                                    : "/images/unfulfilled.svg"
                                }
                              />
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}

                  <tr>
                    <td colspan="3" className="number">
                      <b>Total</b>
                    </td>
                    <td colspan="1" className="number">
                      <b>{price(order)}</b>
                    </td>
                    <td colspan="2"></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="uk-margin-top">
              Review the order and update the quantity if you're unable to
              fulfill the entire request. Set the quantity to zero if you cannot
              fulfill it at all. Remember to message the guest while making any
              updates. Once you're ready, click 'Approve' to charge the credit
              card and send a text notification to the guest.
            </div>
            <div className="uk-margin-top">
              <button
                class="uk-button hms-btn hms-orange-btn"
                type="submit"
                uk-toggle="target: #modal-guest-messages"
              >
                Message Guest
              </button>
              {order.receipt === null ? (
                <>
                  <button
                    class="uk-button hms-btn hms-red-btn"
                    type="submit"
                    onClick={approve}
                  >
                    Approve All Items and Charge Card
                  </button>
                  <button
                    class="uk-button hms-btn hms-red-btn"
                    type="submit"
                    uk-toggle="target: #modal-accessory-item-delete"
                  >
                    Cancel Order
                  </button>
                </>
              ) : isFree() ? (
                <button
                  class="uk-button hms-btn hms-red-btn"
                  type="submit"
                  uk-toggle="target: #modal-refund-order"
                >
                  Issue Refund
                </button>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        <div class="uk-padding"></div>
      </section>
      <MessageForm reservation={reservation} />
      <RefundForm order={order} />
      <CancelModal onCancel={cancelOrder} />
    </>
  );
};

export default OrderDetails;
