// Import package components
import { useContext, useState, useEffect, useMemo, useCallback } from "react";
import { Image } from "uikit-react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import clsx from "clsx";
import { AppContexts } from "../../../providers";
import UIkit from "uikit";

const schema = yup.object().shape({
  scheduled_message_id: yup.number().required(),
  contact: yup.string().required(),
});

const GuestShare = () => {
  const [status, setStatus] = useState("Send");
  const [selected, setSelected] = useState(null);

  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
  });

  const { reservation, sendScheduledMessage } = useContext(
    AppContexts.ReservationsContext,
  );
  const { scheduledMsgs } = useContext(AppContexts.SchedulerContext);

  const scheduled_message_id = useMemo(
    () => getValues("scheduled_message_id"),
    [watch("scheduled_message_id")],
  );

  useEffect(() => {
    setSelected(
      scheduledMsgs.find(
        (item) => item.scheduled_message_id == scheduled_message_id,
      ),
    );
  }, [scheduled_message_id]);

  useEffect(() => {
    reset();
  }, [reservation]);

  useEffect(() => {
    setValue("contact", null);
  }, [selected]);

  const showMsg = (msg, reservation) => {
    if (!msg.active || msg.deleted) {
      return false;
    }

    let msgTags = msg.tags || [];
    let resTags = reservation.tags || [];

    if (msgTags.length > 0) {
      return !!msgTags.filter(
        (t1) => !!resTags.find((t2) => t1.id === t2.id),
      )[0];
    }

    return true;
  };

  const scheduled = useMemo(() => {
    return (scheduledMsgs || []).sort((prev, next) =>
      next?.updated_at?.localeCompare(prev?.updated_at),
    );
  }, [scheduledMsgs]);

  const handleSendMsg = async (data) => {
    setStatus("Sending");
    sendScheduledMessage(
      reservation.guest_reservation_id,
      data.scheduled_message_id,
      data.contact,
    )
      .then((res) => {
        console.log(res);
        setStatus("Sent");
        UIkit.notification("Shared Successfully!", "success");
        setValue("contact", "");
      })
      .catch((err) => {
        setStatus("Sent");
        UIkit.notification("Failed", "error");
        setValue("contact", "");
      });
  };

  useEffect(() => {
    setStatus("Send");
  }, [scheduled_message_id]);

  const filteredMsgs = useMemo(() => {
    return (scheduled || []).filter((it) => showMsg(it, reservation));
  }, [scheduled, reservation]);

  const buildMsgSelect = (msg, idx) => {
    return (
      <li key={idx} style={{ display: "flex" }}>
        <input
          type="radio"
          value={msg.scheduled_message_id}
          {...register("scheduled_message_id")}
        />
        <label
          style={{ marginLeft: "5px", cursor: "pointer" }}
          onClick={() =>
            setValue("scheduled_message_id", msg.scheduled_message_id)
          }
        >
          {msg.name}
        </label>
      </li>
    );
  };

  const handleClose = useCallback(() => {
    reset();
  }, []);

  return (
    <div
      id="modal-guest-share"
      className="uk-modal-full hms-modals uk-modal uk-open uk-dark"
      uk-offcanvas="flip: true; overlay: true"
    >
      <div className="uk-offcanvas-bar">
        <div className="hms-modals-main">
          <button
            className="uk-offcanvas-close uk-close-large"
            type="button"
            onClick={handleClose}
            uk-close="true"
          ></button>
          <div className="uk-modal-header">
            <h2 className="uk-modal-title">Share a Message</h2>
          </div>
          <form onSubmit={handleSubmit(handleSendMsg)}>
            <div className="uk-margin uk-margin-top"></div>
            <ul className="share-message">
              {filteredMsgs.map(buildMsgSelect)}
              {filteredMsgs.length === 0 && "No active scheduled message"}
            </ul>
            {filteredMsgs.length > 0 && (
              <div className="hms-msg-typing-box uk-margin">
                <input
                  className={clsx("uk-input", {
                    "uk-form-danger": errors.contact,
                  })}
                  type={(selected || {}).email ? "email" : "text"}
                  placeholder={`${
                    (selected || {}).email ? "Email Address" : "Mobile Number"
                  }`}
                  style={{ width: "100%" }}
                  {...register("contact")}
                />

                <div className="uk-margin-small-top">
                  <div className="uk-flex uk-flex-middle">
                    <div>
                      <button
                        className="uk-button hms-btn hms-red-btn"
                        type="submit"
                        disabled={status === "Sending"}
                      >
                        {status}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default GuestShare;
