// Import package components
import { createContext, useState } from "react";

import ContactsService from "../services/contacts.service";

export const ContactsContext = createContext();
export const ContactsProvider = ({ children }) => {
  const [flagIsEditing, setIsEditing] = useState(false);
  const [flagIsNew, setIsNew] = useState(false);
  const [flagIsProcessing, setIsProcessing] = useState(false);
  const [listContacts, setContacts] = useState([]);
  const [contact, setContact] = useState({});
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [batchFlag, setBatchFlag] = useState(false);
  const [totalCnt, setTotalCnt] = useState(0);

  const contextContacts = {
    flagIsEditing,
    setIsEditing,
    flagIsNew,
    setIsNew,
    flagIsProcessing,
    listContacts,
    contact,
    setContact,
    selectedContacts,
    setSelectedContacts,
    batchFlag,
    setBatchFlag,
    totalCnt,

    initContact: () => {
      setContact({
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        category: "owner",
        tags: [],
        rentals: [],
      });
    },

    getContacts: () => {
      setIsProcessing(true);
      ContactsService.getContacts()
        .then((res, err) => {
          setIsProcessing(false);
          setContacts(res.data);
        })
        .catch((err) => console.log(err));
    },

    searchContacts: (criteria) => {
      setIsProcessing(true);
      ContactsService.searchContacts(criteria)
        .then((res) => {
          setIsProcessing(false);
          setContacts(res.data.paginated || []);
          setTotalCnt(res.data.total || 0);
        })
        .catch((err) => {
          setIsProcessing(false);
          console.log(err);
        });
    },

    insertContact: async (objContact) => {
      setIsProcessing(true);
      try {
        let res = await ContactsService.insertContact(objContact);
        setIsProcessing(false);
        setContacts((prev) => [...prev, res.data]);
        setTotalCnt((prev) => prev + 1);
        return res.data;
      } catch (error) {
        console.error(error);
      }
    },
    importContacts: async (contactsArr) => {
      try {
        let res = await ContactsService.importContacts(contactsArr);
        setContacts((prev) => [...prev, ...res.data.saved]);
        setTotalCnt((prev) => prev + (res.data.saved || []).length);
        return res.data;
      } catch (error) {
        console.error(error);
      }
    },

    updateContact: async (objContact) => {
      try {
        let res = await ContactsService.updateContact(objContact);
        if (res.data) {
          setContacts((prev) =>
            prev.map((indexContact) =>
              indexContact.id === res.data.id ? res.data : indexContact
            )
          );
          return res.data;
        }
      } catch (error) {
        console.error(error);
      }
    },

    deleteContact: (objContact) => {
      ContactsService.deleteContact(objContact)
        .then((res, err) => {
          setContacts((prev) =>
            prev.filter((indexContact) => indexContact.id !== objContact.id)
          );
          setTotalCnt((prev) => prev - 1);
        })
        .catch((err) => console.log(err));
    },

    sendMessageToContacts: async (contacts, message) => {
      setIsProcessing(true);
      try {
        let res = await ContactsService.sendMessageToContacts({
          contacts,
          msg: message,
        });
        setIsProcessing(false);
        if (res) {
          return res.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
  };

  return (
    <ContactsContext.Provider value={contextContacts}>
      {children}
    </ContactsContext.Provider>
  );
};
