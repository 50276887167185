// Import package components
import { useContext, useCallback, useEffect, useMemo, useState } from "react";
import { Image } from "uikit-react";

import { AppContexts } from "../../../providers";

import { useStripeConnect } from "./use-connect";

import {
  ConnectAccountOnboarding,
  ConnectComponentsProvider,
} from "@stripe/react-connect-js";

const Connect = () => {
  const [accountCreatePending, setAccountCreatePending] = useState(false);
  const [onboardingExited, setOnboardingExited] = useState(false);
  const [error, setError] = useState(false);
  const [connectedAccountId, setConnectedAccountId] = useState();

  const stripeConnectInstance = useStripeConnect(connectedAccountId);

  const { createAccount, checkAccount, account, accountLoading } = useContext(
    AppContexts.StripeContext,
  );
  const { user, loading } = useContext(AppContexts.AuthContext);

  useEffect(() => {
    if (!loading) {
      if (user.stripe_account) {
        setConnectedAccountId(user.stripe_account.stripe_id);
      }

      checkAccount();
    }
  }, [loading]);

  let requiredFields = useMemo(() => {
    if (!account) {
      return [];
    }

    return account.requirements;
  }, [account]);

  return (
    <li className="setting-tab-content uk-active">
      <div className="uk-card hms-left-tabs-content-card mx-w-665">
        <h2>Connect To Stripe</h2>
        {!accountLoading && !!account && !account.charges_enabled && (
          <div className="uk-alert">
            Your Stripe Account is currently disabled. Please complete your
            integration below
          </div>
        )}
        <div className="content">
          {!connectedAccountId && <h2>Get ready for take off</h2>}
          {connectedAccountId && !stripeConnectInstance && (
            <h2>Add information to start accepting money</h2>
          )}
          {!connectedAccountId && (
            <p>Setup a stripe account to start using Extras today</p>
          )}
          {!accountCreatePending && !connectedAccountId && (
            <div>
              <button
                onClick={async () => {
                  setAccountCreatePending(true);
                  setError(false);
                  createAccount().then((resp) => {
                    setAccountCreatePending(false);
                    const { account, error } = resp.data;

                    console.log(account);
                    if (account) {
                      setConnectedAccountId(account);
                    }

                    if (error) {
                      setError(true);
                    }
                  });
                }}
              >
                Sign up
              </button>
            </div>
          )}
          {stripeConnectInstance && (
            <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
              <ConnectAccountOnboarding
                onExit={() => setOnboardingExited(true)}
              />
            </ConnectComponentsProvider>
          )}
          {error && <p className="error">Something went wrong!</p>}
          {(connectedAccountId || accountCreatePending || onboardingExited) && (
            <div className="dev-callout">
              {connectedAccountId && (
                <>
                  <p>
                    This is your Stripe Connect account ID:{" "}
                    <code className="bold">{connectedAccountId}</code>
                  </p>

                  <p>
                    Please store this ID somewhere safe, and click on the "Add
                    Information" button to setup your Stripe Connect account
                    with RueBaRue.
                  </p>
                </>
              )}
              {accountCreatePending && <p>Creating a connected account...</p>}
              {onboardingExited && (
                <p>The Account Onboarding component has exited</p>
              )}
            </div>
          )}
          <div className="info-callout">{/*<p>Link to TOS?</p>*/}</div>
        </div>
      </div>
    </li>
  );
};

export default Connect;
