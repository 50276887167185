import React, {
  useState,
  useContext,
  useMemo,
  useCallback,
  useEffect,
} from "react";
import { useForm } from "react-hook-form";
import { AppContexts } from "../../providers";
import { useHistory } from "react-router";
import { Icon, Image } from "uikit-react";
import UIkit from "uikit";
import moment from "moment";
import GoogleAutoComplete from "../common/GoogleAutoComplete";
import Tags from "./tags";
import PropertyDelete from "./delete.js";
import QRPart from "../common/Qrcode";

const TableRow = ({ guide, index, allTags }) => {
  const [guideDestination, setGuideDestination] = useState(null);
  const DestinationContext = useContext(AppContexts.DestinationContext);
  const {
    updateRental,
    setRental,
    selectedFlags,
    setSelectedFlags,
    setIsNew,
  } = useContext(AppContexts.RentalsContext);
  const history = useHistory();

  const listDestinations = useMemo(() => {
    if (!DestinationContext.listDestinations) {
      return [];
    } else {
      return DestinationContext.listDestinations;
    }
  }, [DestinationContext.listDestinations]);

  const handleSelectedTags = async (selectedTags) => {
    let updated = { ...guide, tags: selectedTags };
    if (updated.hasOwnProperty("custom_macro_values"))
      delete updated["custom_macro_values"];
    try {
      await updateRental(updated.rental_id, updated);
    } catch (e) {
      console.log(e);
    }
  };

  const handleSelect = useCallback((event, index) => {
    setSelectedFlags((flags) => [
      ...flags.slice(0, index),
      event.target.checked,
      ...flags.slice(index + 1),
    ]);
  }, []);

  return (
    <>
      <tr>
        <td>
          <div className="autosend-checkbox">
            <label htmlFor={`propertyCB${index}`}>
              <input
                type="checkbox"
                id={`propertyCB${index}`}
                checked={selectedFlags[index]}
                onChange={(event) => handleSelect(event, index)}
              />
            </label>
          </div>
        </td>
        <td>
          <div>
            <div className="semi-bold-font property-guide-name">
              <label className="uk-margin-right">
                {guide.name && guide.name.trim() !== '' ? guide.name : '<No Guide Name>'}
              </label>            

              <button
                className="uk-button edit-guide"
                uk-toggle={`target: #addPropertyGuide-modal`}
                uk-tooltip="Edit Guide"
                onClick={() => {
                  setIsNew(false);
                  setRental(guide);
                }}
              >
                <Image src="/images/edit-icon.svg" />
              </button>
            </div>
          </div>
          <div className="uk-margin-very-small-top">
            <span className="secondary property-guide-label">Address</span>
            &nbsp;
            <span>{guide.address}</span>
          </div>
          <div>
            <span className="secondary property-guide-label">Property ID</span>
            &nbsp;
            <span>
              {guide.pms_id === "" ? "Click to Add an ID" : guide.pms_id}
            </span>
          </div>
          <div>
            <span className="secondary property-guide-label">Area Guide</span>
            &nbsp;
            <div
              uk-form-custom="target: > * > span:first-child"
              className="uk-margin-small-right"
            >
              <span>
                {!!listDestinations.find(
                  (item) => item.destination_id === guide.destination_id,
                )
                  ? listDestinations.find(
                      (item) => item.destination_id === guide.destination_id,
                    ).name
                  : "No area guide attached"}
              </span>
            </div>
            {guideDestination === index && (
              <span onClick={() => setGuideDestination(null)}>
                <Icon options="close" button={true} />
              </span>
            )}
          </div>
          {/*
          <div>
            Property SMS Code:{" "}
            <span className="color-blue">{guide.property_code}</span>
          </div>
          */}
        </td>
        <td className="hms-add-tag">
          <Tags
            value={guide.tags}
            all={allTags}
            onChange={(event) => handleSelectedTags(event, guide)}
          />
        </td>
        <td className="td-w-240 hms-action-btn">
          <div className="uk-inline">
            <button
              className="uk-button"
              type="button"
              onClick={() => history.push(`properties/${guide.rental_id}`)}
              uk-tooltip="Edit"
            >
              <img src="/images/edit.svg" />
            </button>
          </div>
          <div className="uk-inline">
            <a
              className="uk-button"
              type="button"
              href={`https://guide.ruebarue.com/rental/${guide.rental_id}`}
              target="_blank"
              uk-tooltip="View Home Guide"
            >
              <img src="/images/guide.svg" />
            </a>
          </div>
          <div className="uk-inline">
            <div className="uk-inline">
              <QRPart value={guide.rental_id} guide="rental" />
            </div>
          </div>
          <div className="uk-inline">
            <button
              className="uk-button"
              type="button"
              onClick={() => setRental(guide)}
              uk-toggle="target: #share-modal"
              uk-tooltip="Share"
            >
              <img src="/images/share.svg" />
            </button>
          </div>
          <div className="uk-inline">
            <a
              className="uk-button"
              type="button"
              href={`https://guide.ruebarue.com/rental/${guide.rental_id}/print`}
              target="_blank"
              uk-tooltip="Print"
            >
              <img src="/images/print.svg" />
            </a>
          </div>
          <div className="uk-inline">
            <button
              className="uk-button"
              type="button"
              uk-toggle="target: #modal-property-delete"
              onClick={() => setRental(guide)}
              uk-tooltip="Delete"
            >
              <img src="/images/delete.svg" />
            </button>
          </div>
        </td>
      </tr>
      <PropertyDelete />
    </>
  );
};

export default TableRow;
