// Import package components
import { useMemo, useContext, useEffect, useState } from "react";
import { AppContexts } from "../../../providers";
import { Image, Link } from "uikit-react";
import AccessoryDelete from "./accessory_delete";

import {
  DndContext,
  DragOverlay,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";

import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";

import { CSS } from "@dnd-kit/utilities";

const DragItem = ({ val }) => {
  return (
    <li id={val.key}>
      <div className="uk-width-1-1">
        <div className="uk-flex uk-flex-between uk-flex-middle">
          <div className="uk-sortable-handle">
            <button className="uk-button hms-simple-icon-btn drag-handle">
              <Image src="../images/move.svg" />
            </button>
          </div>
          <div>
            <button type="button" className="uk-button hms-simple-icon-btn">
              <Image src="/images/delete-icon.svg" />
            </button>
          </div>
          <div className="m-w-290">
            <div className="uk-form-controls">
              <input className="uk-input" type="text" value={val.label} />
            </div>
          </div>
          <div>
            <div className="autosend-checkbox">
              <label for={`guideCheckboxShadow`} className="setting-switch">
                <input
                  type="checkbox"
                  id={`guideCheckboxShadow`}
                  checked={val.hidden}
                />
                <span className="setting-slider setting-round"></span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="uk-width-1-1 m-t-5"></div>
    </li>
  );
};

const SortableItemDiv = ({ index, no, tab, val, onUpdate, onDelete }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: val.key });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    opacity: isDragging ? 0 : 1,
  };

  return (
    // <li id={val.key} className="drag-item" data-id={val.key} key={val.key}>
    <li id={val.key} style={style} ref={setNodeRef}>
      <div className="uk-width-1-1">
        <div className="uk-flex uk-flex-between uk-flex-middle">
          <div className="uk-sortable-handle">
            <button
              className="uk-button hms-simple-icon-btn drag-handle"
              uk-tooltip="title: Move; delay: 500"
              {...listeners}
              {...attributes}
            >
              <Image src="../images/move.svg" />
            </button>
          </div>
          <div>
            <button
              type="button"
              className="uk-button hms-simple-icon-btn"
              uk-toggle="target: #accessory-delete"
              uk-tooltip="title: Delete; delay: 500"
              onClick={() => {
                onDelete(val);
              }}
            >
              <Image src="/images/delete-icon.svg" />
            </button>
          </div>
          <div className="m-w-290">
            <div className="uk-form-controls">
              <input
                className="uk-input"
                type="text"
                value={val.label}
                onInput={(e) => {
                  onUpdate(val, "label", e);
                }}
              />
            </div>
          </div>
          <div>
            <div className="autosend-checkbox">
              <label for={`guideCheckbox${no}`} className="setting-switch">
                <input
                  type="checkbox"
                  id={`guideCheckbox${no}`}
                  checked={val.hidden}
                  onInput={(e) => {
                    onUpdate(val, "hidden", {
                      target: { value: !val.hidden },
                    });
                  }}
                />
                <span className="setting-slider setting-round"></span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="uk-width-1-1 m-t-5"></div>
    </li>
  );
};

const AccessoryTabs = () => {
  const { set, settings, loading, active } = useContext(
    AppContexts.SettingsContext,
  );
  const [addTab, setAddTab] = useState("");
  const [tabs, setTabs] = useState([]);
  const [dirty, setDirty] = useState(false);
  const [deleted, setDeleted] = useState(null);
  const [reorderTarget, setReorderTarget] = useState(null);

  const keyedTabs = useMemo(() => {
    return tabs.map((tab) => {
      let key = (Math.random() * 100000000) | 0;

      if (!tab.key) {
        tab.key = key;
      }

      return tab;
    });
  }, [tabs]);

  const sensors = useSensors(useSensor(PointerSensor));

  useEffect(() => {
    document.title = "Settings | Extras - RueBaRue";

    let order = settings.accessories?.tab_order || [];
    let unsorted = settings.accessories?.accessory_tabs || [];

    let sorted = order
      .map((id) => unsorted.find((t) => t.id === id))
      .filter((t) => !!t);

    setTabs(sorted);
  }, [settings]);

  const handleAddTab = () => {
    let newTab = {
      label: addTab.trim(),
      hidden: true,
      deleted: false,
      ordering: [],
    };

    setDirty(true);
    setAddTab("");
    setTabs([...keyedTabs, newTab]);
  };

  const handleSave = () => {
    set(["accessories"], {
      accessories: { tab_order: [], accessory_tabs: keyedTabs },
    }).then((res) => {
      setAddTab("");
      setDirty(false);
    });
  };

  const handleUpdate = (tab, field, evt) => {
    tab[field] = evt.target.value;

    setDirty(true);
    setTabs([...keyedTabs]);
  };

  const setDelete = (tab) => {
    setDeleted(tab);
  };

  const handleDelete = (tab) => {
    tab.deleted = true;

    setDirty(true);
    setTabs([...keyedTabs]);
  };

  const cancelDelete = () => {
    setDeleted(null);
  };

  const handleReorderStart = (e) => {
    setReorderTarget(keyedTabs.find((t) => t.key === e.active.id));
  };

  const handleReorder = (e) => {
    const { active, over } = e;

    if (active.id !== over.id) {
      const oldIndex = keyedTabs.findIndex((t) => t.key === active.id);
      const newIndex = keyedTabs.findIndex((t) => t.key === over.id);

      setDirty(true);
      setTabs(arrayMove(keyedTabs, oldIndex, newIndex));
    }

    setReorderTarget(null);
  };

  return (
    <>
      <li
        className={`setting-destinationGuide-tab-content ${
          active === "extras" ? "uk-active" : ""
        }`}
      >
        <div className="uk-card hms-left-tabs-content-card mx-w-665">
          <h2>Extras Guide</h2>
          <div className="uk-width-1-1">
            <div className="uk-width-1-4 guide-embed-label">
              <div className="uk-flex uk-flex-right">
                <div>Hide</div>
                {/* <div>Embed</div> */}
              </div>
            </div>
          </div>
          <ul className="add-ons-tab-list">
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragStart={handleReorderStart}
              onDragEnd={handleReorder}
            >
              <SortableContext
                items={keyedTabs}
                strategy={verticalListSortingStrategy}
              >
                {keyedTabs
                  .filter((t) => !t.deleted)
                  .map((tab, index) => (
                    <SortableItemDiv
                      key={tab.key}
                      index={index}
                      no={index}
                      val={tab}
                      onUpdate={handleUpdate}
                      onDelete={setDelete}
                    />
                  ))}
              </SortableContext>

              <DragOverlay>
                {!!reorderTarget ? <DragItem val={reorderTarget} /> : null}
              </DragOverlay>
            </DndContext>
          </ul>
          <div className="uk-width-1-1 uk-margin">
            <div className="add-dest-gd-tab-wrapper">
              <div className="uk-flex uk-flex-middle">
                <input
                  className="uk-input"
                  type="text"
                  placeholder="Tab Name"
                  value={addTab}
                  onChange={(e) => setAddTab(e.target.value)}
                />
                <div className="plus-w-2">
                  <button
                    className="uk-button hms-btn hms-gray-btn btn-no-margin"
                    type="button"
                    onClick={handleAddTab}
                  >
                    ADD
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="uk-width-1-1">
            <button
              className="uk-button hms-btn hms-red-btn"
              disabled={loading}
              onClick={handleSave}
            >
              {loading ? (
                <div data-uk-spinner="ratio: 0.5;"></div>
              ) : dirty ? (
                "Save"
              ) : (
                "Saved"
              )}
            </button>
          </div>
        </div>
      </li>
      <AccessoryDelete
        tab={deleted}
        onDelete={handleDelete}
        onCancel={cancelDelete}
      />
    </>
  );
};

export default AccessoryTabs;
