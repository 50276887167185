import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import moment from "moment";
import { Image } from "uikit-react";
import UIkit from "uikit";

import { AppContexts } from "../../providers";

const Notification = () => {
  const { user: account, setUser } = useContext(AppContexts.AuthContext);
  const announcementsContext = useContext(AppContexts.AnnouncementsContext);

  const dismissAnnouncement = async (announcement_id) => {
    await announcementsContext.dismissAnnouncement(
      account.user.user_id,
      announcement_id,
    );
    const announcements = account?.announcements.filter(
      (a) => a.id !== announcement_id,
    );
    setUser({ ...account, announcements: announcements });
  };

  return (
    <div
      id="modal-notifications"
      className="uk-modal-full hms-modals uk-modal uk-open uk-dark"
      uk-offcanvas="flip: true; overlay: true"
    >
      <div className="uk-offcanvas-bar hms-notifications">
        <div className="hms-modals-main">
          <button
            className="uk-offcanvas-close uk-close-large"
            type="button"
            data-uk-close
          />
          <div className="uk-modal-header">
            <span className="uk-modal-title">Notifications</span>
          </div>
          <div className="uk-modal-body uk-flex-column notifications">
            {account?.announcements?.length > 0 ? (
              account.announcements.map((announcement) => (
                <div className="notification" key={announcement.id}>
                  <h4>{announcement.title}</h4>
                  <p>{announcement.description}</p>
                  <div className="uk-flex uk-flex-between">
                    <a className="#" href={announcement.link} target="_blank">
                      Learn More
                      <span uk-icon="icon:  chevron-right; ratio: 0.8"></span>{" "}
                    </a>

                    <button
                      className="uk-button hms-simple-icon-btn"
                      uk-toggle="target: #tag-delete"
                      aria-expanded="false"
                      onClick={() => dismissAnnouncement(announcement.id)}
                    >
                      <img
                        data-src="/images/delete-icon.svg"
                        data-uk-img=""
                        src="/images/delete-icon.svg"
                      />
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <div className="uk-margin-top">You have no unread notifications.</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notification;
