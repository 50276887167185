import { Switch, Route } from "react-router-dom";

import AuthorizedRoute from "../../authorized-route";
import AccessoryRoute from "../../accessory-route";
// import OwnerRoute from "../../owned-route.js";

import AuthPages from "../auth";
import Dashboard from "../dashboard";
import TrialExpiredPage from "../trial";
import Guests from "../guests";
import Contacts from "../contacts";
import Messages from "../messages";
import DestinationGuide from "../destination_guide";
import DestinationGuideDetail from "../destination_guide/destination_detail";
import PropertyGuide from "../property_guide";
import PropertyGuideEditor from "../property_guide/editor/index.js";
import AddOnEditor from "../add_on/index.js";
import Scheduler from "../scheduler";
import Alerts from "../alerts";
import SavedResponses from "../saved_responses";
import MessageTemplates from "../message_templates";
import ExtendGuestStay from "../extend_guest_stay";
import Orders from "../orders";
import Payments from "../payments";
import WorkOrder from "../work_order";
import WorkOrderUpdate from "../work_order/work_order_update";
import Surveys from "../surveys";
import Reports from "../reports";
import Team from "../team";
import Settings from "../settings";
import Profile from "../profile";
import Billing from "../billing";
import NewSubscription from "../billing/new_subscription";
import GuestEmails from "../subscriptions";

const AppLayout = (props) => {
  return (
    <Switch>
      <Route path="/auth" component={AuthPages} />
      <Route path="/work-orders" component={WorkOrder} />
      <Route path="/expired" component={TrialExpiredPage} />

      <AuthorizedRoute exact path="/" component={Dashboard} />
      <AuthorizedRoute path="/guests" component={Guests} />
      <AuthorizedRoute path="/contacts" component={Contacts} />
      <AuthorizedRoute path="/messages" component={Messages} />
      <AuthorizedRoute path="/properties" component={PropertyGuide} />
      <AuthorizedRoute path="/areas" component={DestinationGuide} />
      <AuthorizedRoute
        path="/areas_detail/:destination_id"
        component={DestinationGuideDetail}
      />
      <AuthorizedRoute
        path="/master-home-guide"
        component={PropertyGuideEditor}
      />
      <AccessoryRoute path="/extras" component={AddOnEditor} />
      <AuthorizedRoute path="/subscriptions" component={GuestEmails} />
      <AuthorizedRoute path="/scheduler" component={Scheduler} />
      <AuthorizedRoute path="/alerts" component={Alerts} />
      <AuthorizedRoute path="/saved-responses" component={SavedResponses} />
      <AuthorizedRoute path="/message-templates" component={MessageTemplates} />
      <AuthorizedRoute path="/extend-guest-stay" component={ExtendGuestStay} />
      <AccessoryRoute path="/orders" component={Orders} />
      <AccessoryRoute path="/payments" component={Payments} />

      <AuthorizedRoute path="/surveys" component={Surveys} />
      <AuthorizedRoute path="/reports" component={Reports} />
      <AuthorizedRoute path="/profile" component={Profile} />
      <AuthorizedRoute path="/new-subscription" component={NewSubscription} />

      <AuthorizedRoute path="/settings" component={Settings} />
      <AuthorizedRoute path="/team" component={Team} />
      <AuthorizedRoute path="/billing" component={Billing} />
    </Switch>
  );
};

export default AppLayout;
