import { useContext, React } from "react";
import UIkit from "uikit";
import Dialog from "../../../common/Dialog";
import { AppContexts } from "../../../../providers";

const AccessoryDelete = ({ tab, onDelete, onCancel }) => {
  const cancelDelete = () => {
    onCancel();
    UIkit.modal("#accessory-delete").hide();
  };

  const handleDelete = () => {
    onDelete(tab);
    UIkit.modal("#accessory-delete").hide();
  };

  return (
    <>
      <Dialog id="accessory-delete">
        <div className="uk-modal-dialog uk-modal-body">
          <button
            className="uk-modal-close-default"
            type="button"
            uk-close="true"
          ></button>
          <h2 className="uk-modal-title">Delete Accessory Tab</h2>
          <p>
            Are you sure you want to delete?{" "}
            <b>Warning: This step cannot be reversed.</b>
          </p>
          <p className="uk-text-right">
            <button
              className="uk-button hms-btn uk-modal-close uk-modal-delete-cancel"
              type="button"
              onClick={cancelDelete}
            >
              Cancel
            </button>
            <button
              className="uk-button hms-btn hms-gray-btn"
              type="button"
              onClick={handleDelete}
            >
              Delete
            </button>
          </p>
        </div>
      </Dialog>
    </>
  );
};

export default AccessoryDelete;
