// Import package components
import { useContext, useEffect, useState } from "react";
import { Link } from "uikit-react";
import { AppContexts } from "../../../providers";
import { useHistory } from "react-router-dom";

const defaultValue = [
  { types: ["branding", "destination"], path: "branding", key: "branding" },
  { types: ["inbox"], path: "messages", key: "messages" },
  { types: ["accessories"], path: "extras", key: "extras" },
  { types: ["chat"], path: "chat", key: "chat" },
  { types: ["guestbook"], path: "guestbook", key: "guestbook" },
  {
    types: ["destination", "destination_tabs"],
    path: "destination-guide",
    key: "destination",
  },
  { types: ["five_star"], path: "five-star", key: "five_star" },
  { types: ["tags"], path: "tags", key: "tags" },
  { types: ["destination"], path: "embed", key: "embed" },
  { types: ["integration", "custom_macros"], path: "api", key: "api" },
];

const SettingsNavbar = () => {
  const { get, active, setActive } = useContext(AppContexts.SettingsContext);
  const { user: account } = useContext(AppContexts.AuthContext);
  const history = useHistory();

  const handleActiveList = (key) => {
    let val = defaultValue.find((v) => v.key === key);

    setActive(key);
    get(val.types);
    history.push(`/settings/${val.path}`);
  };

  const hasExtras = () => {
    return (account?.details?.product || "").split(",").includes("extras");
  };

  useEffect(() => {
    let pathArray = history.location.pathname.split("/");
    let path = pathArray.pop().replace("#", "");
    let val = defaultValue.find((v) => v.path === path);

    if (!val) {
      setActive(defaultValue[0].key);
      get(defaultValue[0].types);
    } else {
      setActive(val.key);
      get(val.types);
    }
  }, []);

  return (
    <div className="uk-width-auto uk-first-column">
      <ul className="uk-tab-left hms-setting-option-tabs hms-left-tabs uk-tab">
        <li
          className={active === "branding" ? "uk-active" : ""}
          onClick={() => handleActiveList("branding")}
        >
          <Link>Branding</Link>
        </li>
        <li
          className={active === "guestbook" ? "uk-active" : ""}
          onClick={() => handleActiveList("guestbook")}
        >
          <Link>Digital Guestbooks</Link>
        </li>
        <li
          className={active === "messages" ? "uk-active" : ""}
          onClick={() => handleActiveList("messages")}
        >
          <Link>Texting Platform</Link>
        </li>
        {hasExtras() && (
          <li
            className={active === "extras" ? "uk-active" : ""}
            onClick={() => handleActiveList("extras")}
          >
            <Link>Extras Guide</Link>
          </li>
        )}
        <li
          className={active === "destination" ? "uk-active" : ""}
          onClick={() => handleActiveList("destination")}
        >
          <Link>Area Guide</Link>
        </li>
        <li
          className={active === "chat" ? "uk-active" : ""}
          onClick={() => handleActiveList("chat")}
        >
          <Link>Webchat</Link>
        </li>
        <li
          className={active === "tags" ? "uk-active" : ""}
          onClick={() => handleActiveList("tags")}
        >
          <Link>Tags</Link>
        </li>
        <li
          className={active === "five_star" ? "uk-active" : ""}
          onClick={() => handleActiveList("five_star")}
        >
          <Link>Five Star Review</Link>
        </li>
        <li
          className={active === "embed" ? "uk-active" : ""}
          onClick={() => handleActiveList("embed")}
        >
          <Link>Embed</Link>
        </li>
        <li
          className={active === "api" ? "uk-active" : ""}
          onClick={() => handleActiveList("api")}
        >
          <Link>API</Link>
        </li>
      </ul>
    </div>
  );
};

export default SettingsNavbar;
