import { useCallback, useContext, useEffect } from "react";
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend } from "recharts";
import moment from "moment";

import LegacyNote from "../Common/LegacyNote";
import FilterPanel from "../Common/FilterPanel";
import { AppContexts } from "../../../providers";

const data = [
  { date: "4/6", count: 0 },
  { date: "4/7", count: 1 },
  { date: "4/8", count: 0 },
  { date: "4/9", count: 0 },
  { date: "4/10", count: 0 },
  { date: "4/11", count: 0 },
];

const Overview = () => {
  const ReportsContext = useContext(AppContexts.ReportsContext);
  const { loading, overview, startDate, endDate, getOverview } = ReportsContext;

  const getFn = (start, end) => {
    if (!start || !end) {
      return;
    } else {
      getOverview(start, end);
    }
  };

  const renderCard = useCallback(
    (titleMsg, data, key, url) => {
      if (!data[key]) {
        return null;
      }

      let field =
        {
          guestbook_views: "actions_unique",
          property_views: "actions_unique",
          destination_views: "actions_unique",
        }[key] || "actions";

      let total = data[key].reduce((sum, values) => sum + values[field], 0);
      let arr = [];
      let days =
        Math.abs(
          moment(startDate, "YYYY-MM-DD")
            .startOf("day")
            .diff(moment(endDate, "YYYY-MM-DD").startOf("day"), "days"),
        ) + 1;

      for (var i = 0; i < days; i++) {
        let day = moment(startDate).add(i, "days");
        let actions = data[key].find((d) => {
          return d.action_date === moment(day).format("YYYY-MM-DD");
        });

        arr.push({
          name: moment(day).format("MM/DD"),
          actions: (actions || {})[field] || 0,
        });
      }

      return (
        <>
          <div className="uk-width-1-1 reports-graph-box">
            <div className="uk-flex">
              <div className="uk-width-1-4">
                <div className="card-heading">{titleMsg}</div>
                <div className="hms-blue-number">
                  <a href={"/reports/" + url}>{total}</a>
                </div>
              </div>
              <div className="uk-width-3-4">
                <LineChart width={600} height={200} data={arr}>
                  <XAxis dataKey="name" />
                  <YAxis padding={{ top: 30 }} />
                  <Tooltip />
                  <Line type="monotone" dataKey="actions" stroke="#8884d8" />
                </LineChart>
              </div>
            </div>
          </div>
        </>
      );
    },
    [overview],
  );

  return (
    <li className="reports-overview-tab-content">
      <h2>Overview</h2>
      <div className="uk-card hms-left-tabs-content-card uk-padding-remove">
        <FilterPanel onSubmit={getFn} />
        {!loading && (
          <>
            {renderCard(
              "Text Message Logs",
              overview,
              "message_history",
              "message-log",
            )}
            {renderCard(
              "Unique Guestbook Views",
              overview,
              "guestbook_views",
              "guestbook-views",
            )}
            {renderCard(
              "Unique Home Guide Views",
              overview,
              "property_views",
              "home-guide-views",
            )}
            {renderCard(
              "Unique Area Guide Views",
              overview,
              "destination_views",
              "area-guide-views",
            )}
            {renderCard(
              "Automated Text Sent",
              overview,
              "sent_sms",
              "automated-sms-sent",
            )}
            {renderCard(
              "Automated Emails Sent",
              overview,
              "sent_email",
              "automated-email-sent",
            )}
            {/* renderCard('Guests Imported', overview, "guests_imported", "guests-imported") */}
          </>
        )}
      </div>
    </li>
  );
};

export default Overview;
