import UIkit from "uikit";

import { useEffect, useContext, useMemo, useState } from "react";
import { AppContexts } from "../../../providers";

import ImageInput from "../../common/ImageInput";

const HomeSection = () => {
  const { rental, updateRental } = useContext(AppContexts.RentalsContext);
  const { settings, get } = useContext(AppContexts.SettingsContext);
  const { uploadFile } = useContext(AppContexts.UtilContext);

  const [image, setImage] = useState(null);
  const [status, setStatus] = useState("initial");
  const [upload, setUpload] = useState(null);

  useEffect(async () => {
    if (!settings.custom_macros) {
      await get(["custom_macros"]);
    }
  }, []);

  let imageAtt = useMemo(() => {
    return (rental?.attachments || []).find((a) => a.type === "image");
  }, [rental]);

  const handleImage = async (file) => {
    setUpload(file);
    setStatus("dirty");

    if (!file) {
      let update = JSON.parse(JSON.stringify(rental));
      update.attachments = [
        ...(update.attachments || []).filter((a) => a.type !== "image"),
      ];

      if (update.hasOwnProperty("custom_macro_values"))
        delete update["custom_macro_values"];

      let result = await updateRental(update.rental_id, update);
      if (!!result) {
        UIkit.notification("Updated Successfully");
      }
      setStatus("initial");
    }
  };

  const handleSubmit = async () => {
    console.log("Status", status);
    if (status !== "dirty") {
      return;
    }

    setStatus("pending");
    let attachment = null;
    if (upload !== null) {
      let uploadUrl = await uploadFile(
        upload,
        `/users/rentals/${rental.rental_id}.${upload.type.split("/")[1]}`,
      );

      attachment = {
        type: "image",
        name: upload.name,
        original_name: upload.name,
        url: uploadUrl,
      };

      let update = JSON.parse(JSON.stringify(rental));
      update.attachments = [
        ...(update.attachments || []).filter((a) => a.type !== "image"),
        attachment,
      ];

      if (update.hasOwnProperty("custom_macro_values"))
        delete update["custom_macro_values"];

      let result = await updateRental(update.rental_id, update);
      if (!!result) {
        UIkit.notification("Updated Successfully");
      }

      setImage(null);
      setUpload(null);
      setStatus("initial");
    }
  };

  return (
    <div id="unit-details" className="uk-width-1-3@s hms-property-card">
      <div className="hms-property-card-body">
        <div id="unit-image">
          <label className="uk-form-label">Guestbook Profile Photo</label>
          <ImageInput label="" attachment={imageAtt} handler={handleImage} />
          <p className="hms-small-note uk-margin-small-bottom">
            Min width: 800px. Aspect ratio: 16:9. Formats: .jpg, .jpeg, .png.
            Max file size: 5MB
          </p>
          <button
            className={`uk-button hms-btn hms-red-btn ${
              status === "pending" ? "disabled" : ""
            }`}
            onClick={handleSubmit}
          >
            Upload
          </button>
        </div>

        {settings.custom_macros.length > 0 && (
          <div>
            <div className="uk-width-1-1 uk-margin-top uk-margin-small-bottom">
              <hr />
            </div>

            <div id="unit-macros">
              <label className="uk-form-label">Property Macros</label>
              <ul className="uk-list uk-margin-top">
                {(settings.custom_macros || []).map((m) => {
                  let v = (rental.custom_macro_values || []).find(
                    (v) => v.macro_id === m.id,
                  );

                  return (
                    <li>
                      {m.macro} - {v?.content || ""}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default HomeSection;
