// Import package components
import { useContext, useState } from "react";
import UIkit from "uikit";

// Import base components
import { AppContexts } from "../../../providers";

// Import common components
import Dialog from "../../common/Dialog";

const ReserToMaster = () => {
  const {
    deleteSection,
    selectedSection,
    selectedTab,
    setSelectedSection,
    rental,
    getRental,
  } = useContext(AppContexts.RentalsContext);
  const { get, set, settings } = useContext(AppContexts.SettingsContext);
  const { user, refresh } = useContext(AppContexts.AuthContext);

  const [loading, setLoading] = useState(false);

  const handleReset = async () => {
    setLoading(true);
    let [success, error] = await deleteSection(
      selectedSection.welcome_guide_question_id,
    );
    if (!error) {
      let orders = settings?.welcome_order;
      if (!orders) {
        await get("welcome_order");
        orders = settings["welcome_order"];
      }

      console.log("Selected Section:", selectedTab);
      if (!selectedTab) {
        return UIkit.notification("Failed", "error");
      }

      let selectedOrder = orders.filter((o) => o.type === selectedTab.type)[0];
      console.log("Selected Order", selectedOrder);
      if (!selectedOrder) {
        return UIkit.notification("Failed", "error");
      }

      await set("welcome_order", settings.welcome_order);

      await Promise.all([
        refresh(),
        getRental(rental.rental_id),
        get(["guestbook", "welcome_order", "welcome_tabs"]),
      ]);

      UIkit.notification("Reset Successful.", "success");
    } else {
      UIkit.notification("Failed", "error");
    }

    setLoading(false);
    UIkit.modal("#reset-property").hide();
  };

  return (
    <>
      <Dialog id="reset-property">
        <div className="uk-modal-dialog uk-modal-body">
          <button
            className="uk-modal-close-default"
            type="button"
            uk-close="true"
          ></button>
          <h2 className="uk-modal-title">Reset to Master</h2>
          <p>
            Are you sure you want to reset to master?{" "}
            <b>Warning: This step cannot be reversed.</b>
          </p>
          <p className="uk-text-right">
            <button
              className="uk-button hms-btn uk-modal-close uk-modal-delete-cancel"
              type="button"
            >
              Cancel
            </button>
            <button
              className="uk-button hms-btn hms-gray-btn"
              type="button"
              onClick={handleReset}
            >
              {loading ? <div data-uk-spinner="ratio: 0.5;"></div> : "Reset"}
            </button>
          </p>
        </div>
      </Dialog>
    </>
  );
};

export default ReserToMaster;
