// Import package components
import { useContext, useEffect } from "react";
import {
  Switch,
  Route,
  useRouteMatch,
  useParams,
  Redirect,
} from "react-router-dom";

import { AppContexts } from "../../providers";

import AuthorizedRoute from "../../authorized-route";

import OrderList from "./orders_list";
import OrderUpdate from "./order_detail";

const Orders = () => {
  const { path } = useRouteMatch();
  const { user } = useContext(AppContexts.AuthContext);

  const idRegEx = "[0-9]+";
  const uuidRexEx =
    "[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}";

  return (
    <Switch>
      {user ? (
        <>
          <AuthorizedRoute exact path={path} component={OrderList} />
          <AuthorizedRoute
            path={`${path}/:slug(${uuidRexEx})/:id(${idRegEx})`}
            component={OrderUpdate}
          />
          <AuthorizedRoute
            exact
            path={`${path}/:slug(${uuidRexEx})`}
            component={OrderList}
          />
          <AuthorizedRoute
            path={`${path}/:id(${idRegEx})`}
            component={OrderUpdate}
          />
        </>
      ) : (
        <>
          <Route
            path={`${path}/:slug(${uuidRexEx})/:id(${idRegEx})`}
            component={OrderUpdate}
          />
          <Route
            exact
            path={`${path}/:slug(${uuidRexEx})`}
            component={OrderList}
          />
        </>
      )}
    </Switch>
  );
};

export default Orders;
