import moment from "moment";

export default {
	friendly_date_gap: (start, end) => {
		let gap = moment.duration(moment(start).diff(moment(end)));
		let seconds = (gap._milliseconds / 1000) | 0;

		if (seconds < 60) {
			return { duration: gap.seconds(), unit: "second(s)" };
		} else if (seconds < 3600) {
			return { duation: gap.minutes(), unit: "minute(s)" };
		} else if (seconds < 3600 * 24) {
			return { duration: gap.hours(), unit: "hour(s)" };
		} else if (seconds < 3600 * 24 * 31) {
			return { duration: gap.days(), unit: "day(s)" };
		} else if (seconds < 3600 * 24 * 365) {
			return { duration: gap.months(), unit: "months(s)" };
		} else {
			return { duration: gap.years(), unit: "year(s)" };
		}
	},

	to_price: (price) => {
		return (+(Math.round(price / 100 + "e+2") + "e-2")).toFixed(2);
	},
};
