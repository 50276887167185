// Import package components
import { useState, useContext, useCallback, useEffect } from "react";
import { Image, InputContainer, Flex } from "uikit-react";
import { Form, FormRow, FormInputWrapper } from "../../../ui";
import moment from "moment";

import FilterPanel from "../Common/FilterPanel";
import Pagination from "../../common/Pagination";
import { AppContexts } from "../../../providers";

const GuestbookViewingTimes = () => {
  const [filter, setFilter] = useState("");
  const [page, setPage] = useState(0);
  const ReportsContext = useContext(AppContexts.ReportsContext);
  const { guestbook } = ReportsContext;

  const handlePageChange = useCallback((page) => setPage(page), []);
  const handleFilterChange = useCallback((filter) => setFilter(filter), []);

  const getFn = (startDate, endDate) => {
    if (!startDate || !endDate) {
      return;
    } else {
      ReportsContext.getGuestbook(startDate, endDate, page, filter);
    }
  };

  return (
    <li className="reports-tab-content">
      <h2>Guestbook Viewing Times</h2>
      <div className="uk-card hms-left-tabs-content-card uk-padding-remove">
        <FilterPanel
          searchTermPlaceholder="Guest Name Or Property ID"
          onFilterChange={handleFilterChange}
          onSubmit={getFn}
        />
      </div>
      {/* <Flex alignment="between top" className="guest-form-top-filters">
        <Pagination
          count={guestbook.length}
          index={guestbook.page}
          onIndexChanged={handlePageChange}
        />
      </Flex> */}
    </li>
  );
};

export default GuestbookViewingTimes;
