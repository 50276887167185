// Import package components
import { useContext } from "react";
import UIkit from "uikit";

// Import common components
import Dialog from "../../common/Dialog";

const MasterDelete = ({ onCancel }) => {
  const handleCancel = async (e) => {
    e.preventDefault();
    UIkit.modal("#modal-accessory-item-delete").hide();
    onCancel(e);
  };

  return (
    <>
      <Dialog id="modal-accessory-item-delete">
        <div className="uk-modal-dialog uk-modal-body">
          <button
            className="uk-modal-close-default"
            type="button"
            uk-close="true"
          ></button>
          <h2 className="uk-modal-title">Cancel Order</h2>
          <p>
            <div>Are you sure you want to cancel this order?</div>
            <b>Warning: This step cannot be reversed.</b>
          </p>
          <p className="uk-text-right">
            <button
              className="uk-button hms-btn uk-modal-close uk-modal-delete-cancel"
              type="button"
            >
              Cancel
            </button>
            <button
              className="uk-button hms-btn hms-gray-btn"
              type="button"
              onClick={handleCancel}
            >
              Cancel Order
            </button>
          </p>
        </div>
      </Dialog>
    </>
  );
};

export default MasterDelete;
