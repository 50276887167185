import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import moment from "moment";
import { Image } from "uikit-react";
import UIkit from "uikit";
import { CSVLink } from "react-csv";

import { AppContexts } from "../../../providers";
import { Link } from "../../../ui";
import TableLoading from "../../common/Loading/table_loading";
import Range from "../../surveys/question_types/range";
import Checkboxes from "../../surveys/question_types/checkboxes";
import ShortAnswer from "../../surveys/question_types/short-answer";
import MultipleChoice from "../../surveys/question_types/multiple-choice";

const GuestDetail = () => {
  const {
    reservation,
    historyReservation,
    guestBook,
    survey,
    flagIsLoading,
    surveyResponse,
    getSurveyResponse,
    gettingSurveyResponse,
  } = useContext(AppContexts.ReservationsContext);
  const { threadDetail } = useContext(AppContexts.InboxContext);

  const [isMsg, setTextMsg] = useState(false);
  const [surveyViewMode, setSurveyViewMode] = useState('table') // table | detail

  const headers = [
    { label: "SENT AT", key: "created_at" },
    { label: "SENT BY", key: "sender_type" },
    { label: "MESSAGE", key: "content" },
  ];

  const handleViewSurveyResponse = async (survey) => {
    setSurveyViewMode('detail');
    await getSurveyResponse(survey.survey_id, survey.survey_results_id);
  }

  useEffect(() => {
    UIkit.util.on("#guest-detail", "shown", function (e) {
      UIkit.util.index(e.target) == 1 ? setTextMsg(true) : setTextMsg(false);
    });
  }, []);

  const reservations = useMemo(
    () =>
      reservation.thread_id
        ? threadDetail.guest_reservations
        : historyReservation,
    [threadDetail, historyReservation],
  );

  const messages = useMemo(() => {
    if (reservation.thread_id) {
      return threadDetail?.messages;
    }
    return [];
  }, [threadDetail]);

  const sorted_messages = useMemo(
    () =>
      messages?.sort((prev, next) =>
        next.created_at.localeCompare(prev.created_at),
      ),
    [messages],
  );

  const questions = useMemo(() => {
    if (surveyResponse) {
      let qs = (surveyResponse.question_order || "")
        .split(",")
        .map((id) => {
          let q = (surveyResponse.questions || []).find(
            (i) => +i.survey_question_id === +id,
          );
  
          if (!q) {
            return;
          }
  
          return {
            id: +q.survey_question_id,
            title: q.question,
            help_text: q.help_text,
            options: q.question_options,
            type: q.question_type,
            responses: {
              sum: 0,
              guest_reservation_ids: [],
              answers: {},
            },
          };
        })
        .filter((q) => !!q);
      return qs;
    }
  }, [surveyResponse]);

  const reservationRenderer = (reservation, index) => {
    return (
      <tr key={`reservation_${index}}`}>
        <td>{`${moment(reservation?.check_in)
          .utc()
          .format("MMM DD, YYYY")} - ${moment(reservation?.check_out)
          .utc()
          .format("MMM DD, YYYY")}`}</td>
        <td>{reservation?.pms_id}</td>
      </tr>
    );
  };

  const messageRenderer = (message, index) => {
    return (
      <tr key={`message_${index}`}>
        <td>
          {message.status === "undelivered" ? (
            <>
              <img
                className="report-warning-icon"
                data-src="/images/warning.svg"
                data-uk-img=""
                loading="lazy"
                src="/images/warning.svg"
                alt="Warning Icon"
                data-uk-tooltip={`title: ${message.status} - ${message.error_code} - ${message.error_message}`}
              />
            </>
          ) : (
            <span></span>
          )}
          {moment.utc(message.created_at).local().format("MM/DD/YYYY hh:mm A")}
        </td>
        <td>{message.sender_type}</td>
        <td>
          {message.content}
          {message.attachments.map((it) => (
            <img src={it.url} alt="Image attachment" />
          ))}
        </td>
      </tr>
    );
  };

  const surveyRenderer = (survey, index) => {
    return (
      <tr key={`survey_${survey.survey_id}`}>
        <td>{moment.utc(survey.submitted_at).local().format("MM/DD/YYYY hh:mm A")}</td>
        <td>{survey.name}</td>
        <td>
          <span className="color-blue" onClick={() => handleViewSurveyResponse(survey)}>View</span>
        </td>
      </tr>
    )
  }

  const guestBookRenderer = (guestBook, index) => {
    return (
      <tr key={`guestBook_${index}`}>
        <td>{moment(guestBook.viewed_at).format("MM/DD/YYYY hh:mm A")}</td>
        <td>{guestBook.property_pms_id}</td>
        <td>
          <a href={`rental/${guestBook.property_id}`}>
            {guestBook.property_name}
          </a>
        </td>
      </tr>
    );
  };

  const answerRenderer = useCallback(
    () => {
      let result = surveyResponse?.survey_results[0];
      if (result) {
        return questions.map((quiz) => {
          let data =
            quiz?.type === "checkboxes"
              ? result.answers.filter(
                  (re) => re.survey_question_id.toString() === quiz.id.toString(),
                )
              : result.answers.find(
                  (re) => re.survey_question_id.toString() === quiz.id.toString(),
                );
  
          if (data) {
            return (
              <div
                className="uk-card uk-card-small uk-card-default hms-property-card"
                key={quiz.survey_question_id}
              >
                <div className="guest-information"></div>
                <div className="uk-flex uk-flex-between">
                  <div className="hms-property-card-body">
                    <div className="hms-property-card-content">
                      <h4 className="card-heading-small">{quiz?.title}</h4>
                      <div className="hms-small-note">{quiz?.help_text}</div>
                      <div>
                        {quiz?.type === "multiple-choice" && (
                          <MultipleChoice
                            options={quiz?.options}
                            answer={data?.answer}
                          />
                        )}
                        {quiz?.type === "short-answer" && (
                          <ShortAnswer
                            answer={data?.answer || data.custom_answer}
                          />
                        )}
                        {quiz?.type === "checkboxes" && (
                          <Checkboxes
                            options={quiz?.options}
                            answer={data?.map((a) => a.answer || a.custom_answer)}
                          />
                        )}
                        {quiz?.type === "range-5" && (
                          <Range question={quiz} type={5} answer={data?.answer} />
                        )}
                        {quiz?.type === "range-10" && (
                          <Range
                            question={quiz}
                            type={10}
                            answer={data?.answer}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          } else {
            return null;
          }
        });
      }
    },
    [surveyResponse],
  );

  return (
    <div
      id="modal-guest-detail"
      className="uk-modal-full hms-modals uk-modal uk-open uk-dark"
      uk-offcanvas="flip: true; overlay: true"
    >
      <div className="uk-offcanvas-bar">
        <div className="hms-modals-main">
          <button
            className="uk-offcanvas-close uk-close-large"
            type="button"
            data-uk-close
          />
          <div className="uk-modal-header">
            <span className="uk-modal-title">{reservation?.name}</span>
            <span className="uk-margin-small-left">
              {reservation?.formatted_phone}
            </span>
          </div>
          <div className="uk-modal-body">
            <div className="detail-header">
              <ul
                data-uk-tab
                uk-tab="connect: #guest-detail; animation: uk-animation-fade"
                className="uk-margin-small-bottom uk-margin-small-top"
              >
                <li className="uk-active">
                  <Link href="#">STAYS</Link>
                </li>
                <li>
                  <Link href="#">TEXT MESSAGE LOGS</Link>
                </li>
                <li>
                  <Link href="#">SURVEYS</Link>
                </li>
                <li>
                  <Link href="#">GUESTBOOK VIEWS</Link>
                </li>
              </ul>

              {isMsg && (sorted_messages || []).length > 0 && (
                <CSVLink
                  data={sorted_messages}
                  filename={`${moment().unix()}_msg_logs.csv`}
                  headers={headers}
                >
                  <button className="download">
                    <Image
                      src="/images/download.svg"
                      uk-tooltip="Download CSV"
                    />
                  </button>
                </CSVLink>
              )}
            </div>
            <div>
              <ul id="guest-detail" className="uk-switcher">
                <div className="guest-stays">
                  <table className="uk-table uk-table-hover uk-table-divider hms-table">
                    <thead>
                      <tr>
                        <th>CHECK-IN & OUT</th>
                        <th>PROPERTY ID</th>
                      </tr>
                    </thead>
                    <tbody>
                      {flagIsLoading ? (
                        TableLoading(2)
                      ) : (reservations || []).length ? (
                        (reservations || []).map(reservationRenderer)
                      ) : (
                        <tr>
                          <td colSpan={2} className="uk-text-center">
                            No reservations found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="guest-messages">
                  <table className="uk-table uk-table-hover uk-table-divider hms-table">
                    <thead>
                      <tr>
                        <th>SENT AT</th>
                        <th>SENT BY</th>
                        <th>MESSAGE</th>
                      </tr>
                    </thead>
                    <tbody>
                      {flagIsLoading ? (
                        TableLoading(3)
                      ) : (sorted_messages || []).length ? (
                        (sorted_messages || []).map(messageRenderer)
                      ) : (
                        <tr>
                          <td colSpan={3} className="uk-text-center">
                            No text messages sent or received
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="guest-surveys">
                  {surveyViewMode == 'table' && <table className="uk-table uk-table-hover uk-table-divider hms-table">
                    <thead>
                      <tr>
                        <th>SUBMITTED AT</th>
                        <th>SURVEY</th>
                        <th>RESPONSE</th>
                      </tr>
                    </thead>
                    <tbody>
                      {flagIsLoading ? (
                        TableLoading(3)
                      ) : (survey || []).length ? (
                        (survey || []).map(surveyRenderer)
                      ) : (
                        <tr>
                          <td colSpan={3} className="uk-text-center">
                            No survey submitted
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>}
                  {surveyViewMode === 'detail' &&
                    (gettingSurveyResponse
                      ? TableLoading(3) 
                      : (
                        <div id="surveys-detail-template">
                          <div id="hms-main-body">
                            <div className="survey-details-header">
                              <div className="uk-flex uk-flex-middle">
                                <button
                                  type="button"
                                  className="uk-button hms-img-btn go-back-arrow-btn"
                                  onClick={() => {
                                    setSurveyViewMode("table");
                                  }}
                                >
                                  <span uk-icon="icon: arrow-left; ratio: 1"></span>
                                </button>
                                <div>
                                  <div className="uk-margin-left">
                                    {surveyResponse?.name}
                                  </div>
                                </div>
                              </div>
                            </div>
                            {survey.survey_results_count == 0 ? (
                              <div className="survey-no-response">
                                No survey responses
                              </div>
                            ) : (
                              answerRenderer()
                            )}
                          </div>
                        </div>
                      )
                    )
                  }
                </div>
                <div className="guest-bookview">
                  <table className="uk-table uk-table-hover uk-table-divider hms-table">
                    <thead>
                      <tr>
                        <th>VIEWED AT</th>
                        <th>PROPERTY ID</th>
                        <th>HOME GUIDE</th>
                      </tr>
                    </thead>
                    <tbody>
                      {flagIsLoading ? (
                        TableLoading(3)
                      ) : (guestBook || []).length ? (
                        (guestBook || []).map(guestBookRenderer)
                      ) : (
                        <tr>
                          <td colSpan={3} className="uk-text-center">
                            No Guestbook Views
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GuestDetail;
