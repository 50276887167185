import { useEffect, useContext } from "react";
import UIkit from "uikit";

import Overview from "./Overview";
import ReportsNavbar from "./Layout/navbar";
import GuestBookView from "./GuestBookView";
import MessagesLog from "./MessageLog";
import AutoSentSMS from "./AutoSentSMS";
import AutoSentEmail from "./AutoSentEmail";
import PropertyGuideView from "./PropertyGuideView";
import DestinationGuideView from "./DestinationGuideView";
import Macros from "./Macros";
import FiveStarRating from "./FiveStarRating";
import ExtendStay from "./ExtendStay";
import CheckInOut from "./CheckInOut";
import Webchat from "./Webchat";
import GuestbookViewingTimes from "./GuestbookViewingTimes";
import MessagesSentVsGuestbookViews from "./MessagesSentVsGuestbookViews";
import ExtendstayInOut from "./ExtendstayInOut";

const Reports = () => {
  useEffect(() => {
    document.title = "Reports - RueBaRue";
  }, []);

  return (
    <section id="hms-reports-template">
      <div id="hms-page-title">
        <h1 className="uk-heading-small">Reports</h1>
      </div>
      <div id="hms-main-body">
        <div className="uk-width-1-1" uk-grid="true">
          <ReportsNavbar />
          <div className="uk-width-expand">
            <ul id="reports-tab-left" className="uk-switcher">
              <Overview />
              <MessagesLog />
              <GuestBookView />
              <PropertyGuideView />
              <DestinationGuideView />
              <AutoSentSMS />
              <AutoSentEmail />
              <Macros />
              <FiveStarRating />
              <ExtendStay />
              <CheckInOut />
              <ExtendstayInOut />
              <Webchat />
              <GuestbookViewingTimes />
              <MessagesSentVsGuestbookViews />
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Reports;
