// Import package components
import { useCallback, useContext, useEffect, useState, useMemo } from "react";
import { AppContexts } from "../../../providers";
import { NavLink, useParams } from "react-router-dom";
import { Image, Link, Flex } from "uikit-react";
import { useForm } from "react-hook-form";
import { useCookies } from "react-cookie";

import UIkit from "uikit";
import moment from "moment";
import debounce from "lodash.debounce";
import classnames from "classnames";
import utils from "../../../utils/utils.js";

import AddTextLine from "../../common/AddTextLine";
import RecordsPerPage from "../../common/RecordsPerPage";
import Pagination from "../../common/Pagination";

import MessageForm from "../forms/message.js";
import CancelModal from "../modals/cancel-order.js";

const OrderList = () => {
  const params = useParams();

  const { user } = useContext(AppContexts.AuthContext);
  const { orders, loading, list, toggleFlag, cancel } = useContext(
    AppContexts.OrdersContext,
  );

  const [cookies] = useCookies(["records-per-page"]);
  const { "records-per-page": recordsPerPage } = cookies;

  const [pending, setPending] = useState(false);
  const [sorter, setSorter] = useState("updated_at");
  const [reservation, setReservation] = useState(null);
  const [pendingCancel, setPendingCancel] = useState(null);
  const [criteria, setCriteria] = useState({
    search_term: "",
    start_date: "",
    end_date: "",
    status: "",
    payment_status: null,
    page: 0,
    sort: "updated_at",
  });

  const statusFlags = ["new", "error", "pending", "partial", "fulfilled"];
  const paymentFlags = [
    "authorized",
    "paid",
    "declined",
    "cancelled",
    "partially_refunded",
    "refunded",
  ];

  useEffect(() => {
    list(
      criteria.page || 0,
      recordsPerPage,
      criteria.status || null,
      criteria.payment_status || null,
      criteria.start_date || null,
      criteria.end_date || null,
      criteria.search_term || null,
    );
  }, []);

  useEffect(() => {
    list(
      criteria.page || 0,
      recordsPerPage,
      criteria.status || null,
      criteria.payment_status || null,
      criteria.start_date || null,
      criteria.end_date || null,
      criteria.search_term || null,
    );
  }, [criteria]);

  const pageCount = useMemo(() => {
    return orders[0]?._count || 0;
  }, [orders]);

  const updateCriteria = (field, evt) => {
    console.log(field, evt.target.value);
    setCriteria({ ...criteria, [field]: evt.target.value });
  };

  const dateGap = (created_at) => {
    let gap = utils.friendly_date_gap(moment(), moment.utc(created_at));

    return `${gap.duration} ${gap.unit} ago`;
  };

  const count = (order) => {
    // if (order.receipt) {
    console.log(order);
    // }

    return (order.accessories || []).reduce((a, c) => {
      return a + +c.quantity;
    }, 0);
  };

  const price = (order) => {
    return utils.to_price(order.total);
  };

  const status = (order) => {
    return (order.flags || []).find((f) => statusFlags.includes(f.name))?.name;
  };

  const payment_status = (order) => {
    return (order.flags || [])
      .find((f) => paymentFlags.includes(f.name))
      ?.name.split("_")
      .join(" ");
  };

  const handlePageChange = (page) => {
    setCriteria({ ...criteria, page: page });
  };

  const toggleResponse = async (order) => {
    if (pending) {
      return;
    }

    setPending(true);
    let [toggled, toggledErr] = await toggleFlag(
      "waiting",
      order.id,
      "invoice",
    );

    setPending(false);
  };

  const openMessage = (order) => {
    setReservation(order?.guest_reservation || null);
  };

  const isCancellable = (order) => {
    return !!order.flags.find((f) => f.name === "authorized");
  };

  const openCancel = (order) => {
    setPendingCancel(order);
  };

  const cancelOrder = (e) => {
    if (pending) {
      return;
    }

    if (!pendingCancel) {
      return;
    }

    setPending(true);
    cancel(pendingCancel)
      .then(() => {
        UIkit.notification(
          "This order has been successfully cancelled",
          "success",
        );
        setPending(false);
        setPendingCancel(null);

        list(
          criteria.page || 0,
          recordsPerPage,
          criteria.status || null,
          criteria.payment_status || null,
          criteria.start_date || null,
          criteria.end_date || null,
          criteria.search_term || null,
        );
      })
      .catch((err) => {
        UIkit.notification("Error processing request", "warning");
        setPending(false);
        setPendingCancel(null);
      });
  };

  const isWaiting = (order) => {
    return !!(order?.flags || []).find((f) => f.name === "waiting");
  };

  return (
    <>
      <section id="work-order-template">
        <AddTextLine feature="Orders" />
        <div id="hms-page-title">
          <h1 className="uk-heading-small">Orders</h1>
        </div>
        <div id="hms-main-body">
          <div className="uk-card uk-card-small uk-card-default hms-form-card card-without-filters">
            <div className="uk-overflow-auto">
              <div className="uk-flex uk-flex-between uk-flex-top workorder-form-top-filters">
                <div>
                  <form
                    action=""
                    className="uk-form uk-flex uk-flex-middle hms-form"
                  >
                    <div className="uk-inline">
                      <div uk-form-custom="target: > * > span:first-child">
                        <select
                          value={criteria.payment_status}
                          onInput={(e) => {
                            updateCriteria("payment_status", e);
                          }}
                        >
                          <option value="">All</option>
                          <option value="paid">Paid</option>
                          <option value="waiting">
                            Awaiting Guest Reponse
                          </option>
                          <option value="authorized">Authorized</option>
                          <option value="cancelled">Canceled</option>
                          <option value="partially_refunded">
                            Partially Refunded
                          </option>
                          <option value="refunded">Refunded</option>
                        </select>
                        <button
                          className="uk-button uk-button-default"
                          type="button"
                          tabIndex="-1"
                        >
                          <span></span>
                          <span uk-icon="icon: chevron-down"></span>
                        </button>
                      </div>
                    </div>

                    <div className="uk-inline hms-date-picker">
                      <input
                        type="date"
                        id="guestDateStart"
                        value={criteria.start_date}
                        onInput={(e) => {
                          updateCriteria("start_date", e);
                        }}
                        disabled={false}
                      />
                    </div>
                    <div className="uk-inline hms-date-picker">
                      <input
                        type="date"
                        id="guestDateEnd"
                        value={criteria.end_date}
                        onInput={(e) => {
                          updateCriteria("end_date", e);
                        }}
                        disabled={false}
                      />
                    </div>

                    <div className="uk-inline">
                      <div className="uk-search uk-search-default">
                        {!criteria.search_term ? (
                          <a
                            className="uk-form-icon-flip uk-form-icon"
                            uk-icon="icon: close"
                            onClick={() => updateCriteria("search_term", "")}
                          ></a>
                        ) : (
                          <span
                            className="uk-search-icon-flip"
                            uk-search-icon="true"
                          />
                        )}
                        <input
                          className="uk-search-input"
                          type="search"
                          placeholder="Item or Order #"
                          value={criteria.search_term}
                          onInput={(e) => {
                            updateCriteria("search_term", e);
                          }}
                        />
                      </div>
                    </div>
                    {/*
                    <div className="uk-inline">
                      <button class="download">
                        <img
                          src="/images/download.svg"
                          uk-tooltip="Download CSV"
                          title=""
                          aria-describedby="uk-tooltip-796"
                          tabindex="0"
                        />
                      </button>
                    </div>
                    */}
                  </form>
                </div>
              </div>
              <div className="uk-flex uk-flex-between">
                <div>
                  <ul data-uk-tab>
                    <li
                      className={classnames({
                        "uk-active": criteria.status === "all",
                      })}
                      onClick={() => {
                        updateCriteria("status", {
                          target: { value: null },
                        });
                      }}
                    >
                      <Link href="#">All</Link>
                    </li>
                    <li
                      className={classnames({
                        "uk-active": criteria.status === "new",
                      })}
                      onClick={() => {
                        updateCriteria("status", {
                          target: { value: "new" },
                        });
                      }}
                    >
                      <Link href="#">NEW</Link>
                    </li>
                    <li
                      className={classnames({
                        "uk-active": criteria.status === "pending",
                      })}
                      onClick={() => {
                        updateCriteria("status", {
                          target: { value: "pending" },
                        });
                      }}
                    >
                      <Link href="#">Pending Fulfillment</Link>
                    </li>
                    <li
                      className={classnames({
                        "uk-active": criteria.status === "partial",
                      })}
                      onClick={() => {
                        updateCriteria("status", {
                          target: { value: "partial" },
                        });
                      }}
                    >
                      <Link href="#">Partially Fulfilled</Link>
                    </li>
                    <li
                      className={classnames({
                        "uk-active": criteria.status === "fulfilled",
                      })}
                      onClick={() => {
                        updateCriteria("status", {
                          target: { value: "fulfilled" },
                        });
                      }}
                    >
                      <Link href="#">Fulfilled</Link>
                    </li>
                  </ul>
                </div>

                <div>
                  {/*
                  <div className="hms-sortby-btn">
                    <button className="uk-button" type="button">
                      SORT BY<span uk-icon="icon: triangle-down"></span>
                    </button>
                    <div uk-dropdown="mode: click; offset: 0; animation: uk-animation-slide-top-small; duration: 400">
                      <ul className="uk-nav uk-dropdown-nav">
                        <li
                          className={classnames({
                            "uk-active": sorter === "created_at",
                          })}
                          onClick={() =>
                            updateCriteria("created_at", {
                              target: { value: "created_at" },
                            })
                          }
                        >
                          <Link href="#">Created At</Link>
                        </li>
                        <li
                          className={classnames({
                            "uk-active": sorter === "order_number",
                          })}
                          onClick={() =>
                            updateCriteria("created_at", {
                              target: { value: "created_at" },
                            })
                          }
                        >
                          <Link href="#">Order Number</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  */}
                </div>
              </div>
              <table className="uk-table uk-table-hover uk-table-divider hms-table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Awaiting Response</th>
                    <th>Date/Status</th>
                    <th className="uk-table-expand">
                      CHECK-IN & OUT/ NAME/EMAIL/PHONE
                    </th>
                    <th className="uk-table-expand">Property ID/Address</th>
                    <th className="number">Items</th>
                    <th className="number">Total</th>
                    <th>Payment Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {orders.map((order) => (
                    <tr>
                      <td>{order.order_number}</td>
                      <td style={{ width: "85px" }} className="uk-text-center">
                        <div className="autosend-checkbox">
                          <label
                            htmlFor={`order-waiting-flag-${order.id}`}
                            className="setting-switch"
                          >
                            <input
                              type="checkbox"
                              id={`order-waiting-flag-${order.id}`}
                              onChange={() => toggleResponse(order)}
                              checked={isWaiting(order)}
                            />
                            <span className="setting-slider setting-round"></span>
                          </label>
                        </div>

                        {/*<input
                          type="checkbox"
                          className="uk-checkbox"
                          onChange={() => toggleResponse(order)}
                          checked={isWaiting(order)}
                        />*/}
                      </td>
                      <td>
                        <div>
                          <div>
                            {moment
                              .utc(order.created_at)
                              .local()
                              .format("MM/DD/YYYY hh:mm A")}
                          </div>
                          <div className="secondary">
                            {dateGap(order.created_at)}
                          </div>
                          <div className="hms-booking-tag uk-margin-small-top">
                            {status(order) || ""}
                          </div>
                        </div>
                      </td>
                      <td className="hms-guests-column">
                        <div className="guests-secondary">
                          {`${moment.utc(order.guest_reservation.check_in).format("MMM DD, YYYY")} - ${moment.utc(order.guest_reservation.check_out).format("MMM DD, YYYY")}`}
                        </div>
                        <div className="semi-bold-font">{`${order.guest_reservation.first_name} ${order.guest_reservation.last_name}`}</div>
                        <div className="secondary">
                          {order.guest_reservation.phone}
                        </div>
                        <div className="secondary">
                          {order.guest_reservation.email}
                        </div>
                      </td>
                      <td>
                        <div>{order?.rental?.pms_id || ""}</div>
                        <div className="secondary">
                          {order?.rental?.address || ""}
                        </div>
                      </td>
                      <td className="number">{count(order)}</td>
                      <td className="number">{price(order)}</td>
                      <td>
                        <div className="hms-booking-tag">
                          {payment_status(order) || ""}
                        </div>
                      </td>
                      <td class="hms-action-btn">
                        <div className="uk-flex uk-flex-top">
                          <div className="uk-inline">
                            <a
                              href={`/orders/${order.order_number}`}
                              target="_blank"
                            >
                              <button
                                className="uk-button"
                                uk-tooltip="Order Details"
                                title=""
                                aria-describedby="uk-tooltip-757"
                              >
                                <img
                                  data-src="/images/detail.svg"
                                  data-uk-img=""
                                  loading="lazy"
                                  src="/images/detail.svg"
                                />
                              </button>
                            </a>
                          </div>
                          <div class="uk-inline">
                            <button
                              className="uk-button"
                              onClick={() => {
                                openMessage(order);
                              }}
                              uk-tooltip="Send Text"
                              uk-toggle="target: #modal-guest-messages"
                              title=""
                              aria-describedby="uk-tooltip-193"
                              aria-expanded="false"
                            >
                              <img
                                data-src="/images/sms.svg"
                                data-uk-img=""
                                loading="lazy"
                                src="/images/sms.svg"
                              />
                            </button>
                          </div>
                          {isCancellable(order) ? (
                            <div className="uk-inline">
                              <button
                                className="uk-button"
                                onClick={() => {
                                  openCancel(order);
                                }}
                                uk-tooltip="Cancel Order"
                                uk-toggle="target: #modal-accessory-item-delete"
                                title=""
                                aria-describedby="uk-tooltip-760"
                                aria-expanded="false"
                              >
                                <img
                                  data-src="/images/cancel.svg"
                                  data-uk-img=""
                                  loading="lazy"
                                  src="/images/cancel.svg"
                                />
                              </button>
                            </div>
                          ) : null}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <Flex alignment="between top" className="guest-form-top-filters">
            <Pagination
              count={pageCount}
              index={criteria.page || 0}
              onIndexChanged={handlePageChange}
            />

            <RecordsPerPage />
          </Flex>
        </div>
      </section>

      <MessageForm reservation={reservation} />
      <CancelModal onCancel={cancelOrder} />
    </>
  );
};

export default OrderList;
