import { useState, useContext, useCallback, useEffect } from "react";
import { Flex } from "uikit-react";
import { useForm } from "react-hook-form";
import moment from "moment";

import { AppContexts } from "../../../providers";
import { useDebounce } from "../../../hook";

const FilterPanel = ({
  onSubmit = () => {},
  onFilterChange = () => {},
  searchTermPlaceholder,
}) => {
  const [filter, setFilter] = useState("");
  const ReportsContext = useContext(AppContexts.ReportsContext);
  const { register, handleSubmit, setValue } = useForm();
  const debouncedFilter = useDebounce(filter, 300);

  const handleRegister = useCallback((data) => {
    ReportsContext.updateRange(data.start, data.end);
    onSubmit(data.start, data.end);
  }, []);

  const handleFilterChange = useCallback((e) => setFilter(e.target.value), []);

  useEffect(
    () =>
      setValue("start", moment(ReportsContext.startDate).format("YYYY-MM-DD")),
    [ReportsContext.startDate]
  );
  useEffect(
    () => setValue("end", moment(ReportsContext.endDate).format("YYYY-MM-DD")),
    [ReportsContext.endDate]
  );
  useEffect(() => onFilterChange(debouncedFilter), [debouncedFilter]);

  return (
    <form onSubmit={handleSubmit(handleRegister)}>
      <div className="uk-flex uk-flex-between">
        <div className="uk-flex">
          <div className="uk-form-controls">
            <div className="hms-date-picker">
              <input
                className="uk-input"
                type="date"
                max={moment().format("YYYY-MM-DD")}
                defaultValue={moment().subtract(7, "day").format("YYYY-MM-DD")}
                {...register("start")}
              />
            </div>
          </div>
          <div className="uk-form-controls uk-margin-small-left">
            <div className="hms-date-picker">
              <input
                className="uk-input"
                type="date"
                max={moment().format("YYYY-MM-DD")}
                defaultValue={moment().format("YYYY-MM-DD")}
                {...register("end")}
              />
            </div>
          </div>
          <div className="uk-margin-small-left">
            <button
              className="uk-button hms-btn hms-red-btn hms-go-btn"
              type="submit"
            >
              Go
            </button>
          </div>
        </div>
        {searchTermPlaceholder ? (
          <div className="w-50 uk-margin-large-left">
            <div className="uk-search uk-search-default w-100">
              <span className="uk-search-icon-flip" uk-search-icon="true" />
              <input
                className="uk-search-input"
                type="search"
                value={filter}
                placeholder={searchTermPlaceholder}
                onChange={handleFilterChange}
              />
            </div>
          </div>
        ) : null}
      </div>
    </form>
  );
};

export default FilterPanel;
