import { useCallback, useMemo, useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import UIkit from "uikit";
import { InputContainer } from "uikit-react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import classnames from "classnames";

import { AppContexts } from "../../../providers";
import { InputRadio, Form, FormRow, FormInputWrapper } from "../../../ui";
import SMSEmailInputbox from "../../common/sms-email-inputbox";
import ConvertUTCDateToLocalDate from "../../common/ConvertUTCDateToLocalDate";

// Constants
const folderOptions = {
  scheduledMsg: "Scheduler",
  alert: "Alerts",
  scheduleAhead: "Schedule Ahead",
  extendYourStay: "Extend Guest Stays",
};

const schema = yup.object().shape({
  name: yup.string().required("Name is required"),
  folder: yup.string().required("Folder is required"),
  body: yup.string().required("Body is required"),
});

const defaultValues = {
  name: "",
  subject: "",
  folder: "",
  body: "",
  // type: "sms",
};

const SavedTemplateAdd = () => {
  const {
    templatesCtx: { msgTemplate, isNew, set },
  } = useContext(AppContexts.TemplatesContext);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
    clearErrors,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  // Helper to reset or set form values
  const setFormValues = useCallback(
    (template = {}) => {
      Object.entries(defaultValues).forEach(([key, defaultValue]) => {
        const value =
          key === "folder"
            ? folderOptions[template[key]] || template[key]
            : template[key];
        setValue(key, value || defaultValue);
      });
    },
    [setValue],
  );

  useEffect(() => {
    clearErrors();
    setFormValues(msgTemplate);
  }, [msgTemplate, setFormValues, clearErrors]);

  // Handle form submission
  const handleRegister = async (formData) => {
    const payload = {
      msg_templates: [{ ...formData, id: isNew ? undefined : msgTemplate.id }],
    };

    try {
      await set(payload, "msg_templates");
      UIkit.modal("#message-templates-modal").hide();
      UIkit.notification(
        isNew ? "Message Template Added" : "Message Template Updated",
        "success",
      );
    } catch (error) {
      UIkit.notification("Failed to save the template", "error");
    }
  };

  const responseBody = useMemo(() => getValues("body"), [watch("body")]);
  // const sendType = useMemo(() => getValues("type"), [watch("type")]);

  return (
    <div
      id="message-templates-modal"
      className="uk-modal-full hms-modals uk-modal uk-open"
      uk-offcanvas="flip: true; overlay: true"
    >
      <div className="uk-offcanvas-bar">
        <div className="hms-modals-main">
          <button
            className="uk-offcanvas-close uk-close-large"
            type="button"
            uk-close="true"
          />
          <div className="uk-modal-header">
            <h2 className="uk-modal-title">
              {isNew ? "Add a" : "Update"} Message Template
            </h2>
          </div>
          <div className="uk-modal-body uk-flex">
            <Form
              onSubmit={handleSubmit(handleRegister)}
              className="uk-grid-small"
              uk-grid="true"
            >
              {/* Name Input */}
              <FormRow>
                <FormInputWrapper label="Name*">
                  <InputContainer>
                    <input
                      className={classnames("uk-input", { error: errors.name })}
                      type="text"
                      {...register("name")}
                    />
                  </InputContainer>
                </FormInputWrapper>
              </FormRow>

              {/* Folder Select */}
              <FormRow>
                <div className="uk-width-1-2">
                  <label className="uk-form-label">Folder</label>
                  <div className="uk-form-controls uk-width-1-1">
                    <div
                      uk-form-custom="target: > * > span:first-child"
                      className={classnames("uk-form-custom", {
                        select_error: errors.folder,
                      })}
                    >
                      <select {...register("folder")} className="uk-select">
                        <option value="">Select a folder</option>
                        {Object.values(folderOptions).map((folder, index) => (
                          <option key={index} value={folder}>
                            {folder}
                          </option>
                        ))}
                      </select>
                      <button
                        className="uk-button uk-button-default"
                        type="button"
                        tabIndex="-1"
                      >
                        <span>{getValues("folder") || "Select a folder"}</span>
                        <span
                          uk-icon="icon: chevron-down"
                          className="hms-align-right"
                        ></span>
                      </button>
                    </div>
                  </div>
                </div>
              </FormRow>

              {/* Type Radio Input */}
              {/*
              <FormRow>
                <div className="uk-width-1-1">
                  <div className="uk-form-label">Type</div>
                  <div className="uk-grid uk-form-controls">
                    <label className="uk-margin-right">
                      <input
                        type="radio"
                        className="uk-radio"
                        {...register("type")}
                        value="sms"
                      />{" "}
                      SMS
                    </label>
                    <label>
                      <input
                        type="radio"
                        className="uk-radio"
                        {...register("type")}
                        value="email"
                      />{" "}
                      Email
                    </label>
                  </div>
                </div>
              </FormRow>
              */}
              {/* Subject Input */}
              <FormRow>
                <FormInputWrapper label="Subject">
                  <InputContainer>
                    <input
                      className={classnames("uk-input", {
                        error: errors.subject,
                      })}
                      type="text"
                      {...register("subject")}
                    />
                  </InputContainer>
                </FormInputWrapper>
              </FormRow>

              {/* Body Input */}
              <FormRow>
                <div className="uk-width-1-1">
                  <SMSEmailInputbox
                    value={responseBody || ""}
                    error={errors.body}
                    macrosOrResponse={true}
                    onChange={(e) => setValue("body", e.target.value)}
                  />
                </div>
              </FormRow>

              {/* Submit and Cancel Buttons */}
              <div>
                <button className="uk-button hms-btn hms-red-btn" type="submit">
                  Save
                </button>
                <button
                  className="uk-button hms-btn uk-offcanvas-close"
                  type="button"
                >
                  Cancel
                </button>
              </div>

              {/* Last Updated */}
              {!isNew && (
                <div className="s-msg-last-updated">
                  <ConvertUTCDateToLocalDate
                    label="Last Updated: "
                    date={new Date(msgTemplate?.updated_at)}
                  />
                </div>
              )}
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SavedTemplateAdd;
