// Import package components
import { useContext, useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment";
import classnames from "classnames";
import { Flex, Image } from "uikit-react";
import { useCookies } from "react-cookie";

import TeamAdd from "./add";
import TeamDelete from "./delete";
import TableLoading from "../common/Loading/table_loading";
import { Link } from "../../ui";
import { AppContexts } from "../../providers";
import Pagination from "../common/Pagination";
import RecordsPerPage from "../common/RecordsPerPage";

const Team = () => {
  const [criteria, setCriteria] = useState({
    page: 0,
    search_term: "",
    sort: "updated_on",
  });
  const { set, settings, loading, active } = useContext(
    AppContexts.SettingsContext,
  );
  const inboxCtx = useContext(AppContexts.InboxContext);

  const [dirty, setDirty] = useState(false);
  const [rec, setRec] = useState(null);
  const [inbox, setInbox] = useState(null);

  const [edits, setEdits] = useState({});
  const [deletes, setDeletes] = useState({});
  const [cookies] = useCookies(["records-per-page"]);
  const { "records-per-page": recordsPerPage } = cookies;

  useEffect(() => {
    document.title = "Team - RueBaRue";
    if (!!inboxCtx.settings) {
      setInbox(inboxCtx.settings);
    }
  }, [inboxCtx.settings, inboxCtx.loading]);

  useEffect(() => {
    inboxCtx.get();
  }, []);

  const recipients = useMemo(() => {
    if (!inbox) {
      return [];
    } else {
      if (!inbox.recipients) {
        return [];
      } else {
        return inbox.recipients
          .filter((recipient) => {
            if (
              [recipient.first_name, recipient.last_name]
                .join(" ")
                .toLowerCase()
                .search(criteria.search_term.toLowerCase()) >= 0 &&
              !recipient.deleted
            )
              return true;
            return false;
          })
          .sort((prev, next) => {
            if (criteria.sort === "updated_on")
              return next.updated_at?.localeCompare(prev.updated_at);
            if (criteria.sort === "first_name")
              return prev.first_name?.localeCompare(next.first_name);
            if (criteria.sort === "last_name")
              return prev.last_name?.localeCompare(next.last_name);
            return true;
          });
      }
    }
  }, [inbox, criteria]);

  const pagedRecipients = useMemo(
    () =>
      recipients.slice(
        criteria.page * recordsPerPage,
        (criteria.page + 1) * recordsPerPage,
      ),
    [recipients, criteria, recordsPerPage],
  );

  // const convertUTCDateToLocalDate = (date) => {
  //   const newDate = new Date(
  //     date.getTime() - date.getTimezoneOffset() * 60 * 1000
  //   ).toLocaleString();

  //   return moment(newDate).format("YYYY-MM-DD HH:mm:ss");
  // };

  // let converted_recipients = useMemo(() => {
  //   return inbox?.recipients?.map((recipient) => {
  //     let schedule = recipient.schedule.map((item) => {
  //       let startTime = convertUTCDateToLocalDate(new Date(item.start_time));
  //       let endTime = convertUTCDateToLocalDate(new Date(item.end_time));
  //       return { ...item, start_time: startTime, end_time: endTime };
  //     });
  //     return { ...recipient, schedule };
  //   });
  // }, [inbox]);

  const getSchedule = (schedule, day) => {
    let dow = (schedule || []).filter((wd) => !!wd && wd.weekday === day)[0];

    if (day === 1) {
      console.log(dow);
    }

    if (!dow) {
      return "Muted";
    } else {
      let st = moment(dow.start_time.split(/[\s|T]/)[1], "HH:mm:ss").format(
        "h:mma",
      );
      let et = moment(dow.end_time.split(/[\s|T]/)[1], "HH:mm:ss").format(
        "h:mma",
      );

      return `${st} - ${et}`;
    }
  };

  const handleIndexChanged = useCallback((newPageIndex) => {
    handleCriteriaChange("page", newPageIndex);
  });

  const handleAdd = () => {
    inboxCtx.initRecipient();
    inboxCtx.setIsNew(true);
  };

  const handleEdit = (r) => {
    inboxCtx.setRecipient(JSON.parse(JSON.stringify(r)));
    inboxCtx.setIsNew(false);
  };

  const handleAlertToggle = (e, r) => {
    inboxCtx.updateRecipient({
      ...r,
      alerts: e.target.checked,
    });
  };

  const handleDelete = (r) => {
    inboxCtx.setDeletedRecipient(r);
  };

  const handleInvite = (r) => {
    setRec(r);
    inboxCtx.inviteRecipient(r);
  };

  const handleCriteriaChange = useCallback((key, value) => {
    setCriteria((criteria) => ({ ...criteria, [key]: value }));
  });

  const getTeamSchedule = (dow) => {
    let min = null;
    let max = null;

    (inbox?.recipients || []).map((r) => {
      if (r.deleted) {
        return;
      }

      (r.schedule || []).map((wd) => {
        if (wd?.weekday === dow) {
          let st = moment(wd.start_time.split(/[\s|T]/)[1], "HH:mm:ss");
          let et = moment(wd.end_time.split(/[\s|T]/)[1], "HH:mm:ss");

          if (!min || st.isBefore(min)) {
            min = st;
          }

          if (!max || et.isAfter(max)) {
            max = et;
          }
        }
      });
    });

    if (!!min && !!max) {
      return `${min.format("h:mma")} - ${max.format("h:mma")}`;
    } else {
      return "Muted";
    }
  };

  return (
    <>
      <section id="team-schedule-template">
        <div id="hms-page-title">
          <h1 className="uk-heading-small">Team</h1>
        </div>
        <div id="hms-main-body">
          <div className="uk-flex uk-flex-between uk-flex-top">
            <div className="hms-guest-btn">
              <button
                className="uk-button hms-btn hms-red-btn"
                uk-toggle="target: #team-add"
                onClick={handleAdd}
              >
                <span uk-icon="icon: plus; ratio: 0.7"></span> Invite User
              </button>
            </div>
          </div>
          <div className="uk-card uk-card-small uk-card-default hms-form-card card-without-filters">
            <div className="uk-overflow-auto">
              <Flex alignment="between top" className="guest-form-top-filters">
                <div>
                  <form
                    action=""
                    className="uk-form uk-flex uk-flex-middle hms-form"
                  >
                    <div className="uk-inline">
                      <div className="uk-search uk-search-default">
                        {criteria.search_term && criteria.search_term !== "" ? (
                          <a
                            className="uk-form-icon-flip uk-form-icon"
                            uk-icon="icon: close"
                            onClick={() =>
                              handleCriteriaChange("search_term", "")
                            }
                          ></a>
                        ) : (
                          <span
                            className="uk-search-icon-flip"
                            uk-search-icon="true"
                          />
                        )}
                        <input
                          className="uk-search-input"
                          type="search"
                          placeholder="Name"
                          value={criteria.search_term}
                          onChange={(event) =>
                            handleCriteriaChange(
                              "search_term",
                              event.target.value,
                            )
                          }
                        />
                      </div>
                    </div>
                  </form>
                </div>
                <div>
                  <div className="hms-sortby-btn">
                    <button className="uk-button" type="button">
                      SORT BY<span uk-icon="icon: triangle-down"></span>
                    </button>
                    <div uk-dropdown="mode: click; offset: 0; animation: uk-animation-slide-top-small; duration: 400">
                      <ul className="uk-nav uk-dropdown-nav">
                        <li
                          className={classnames({
                            "uk-active": criteria.sort === "updated_on",
                          })}
                        >
                          <Link
                            href="#"
                            onClick={() =>
                              handleCriteriaChange("sort", "updated_on")
                            }
                          >
                            Last Updated
                          </Link>
                        </li>
                        <li
                          className={classnames({
                            "uk-active": criteria.sort === "first_name",
                          })}
                        >
                          <Link
                            href="#"
                            onClick={() =>
                              handleCriteriaChange("sort", "first_name")
                            }
                          >
                            First Name
                          </Link>
                        </li>
                        <li
                          className={classnames({
                            "uk-active": criteria.sort === "last_name",
                          })}
                        >
                          <Link
                            href="#"
                            onClick={() =>
                              handleCriteriaChange("sort", "last_name")
                            }
                          >
                            Last Name
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Flex>
              <table className="uk-table uk-table-hover uk-table-divider hms-table hms-table-team">
                <thead>
                  <tr>
                    <th className="uk-table-expand name-field">User</th>
                    <th className="uk-table-expand">Notification Schedule</th>
                    <th className="uk-width-small">
                      Email Alerts{" "}
                      <img
                        className="info-icon"
                        src="/images/info-icon.svg"
                        alt="info"
                        uk-tooltip="Get email alerts for SMS delivery failures, guest messages when out-of-office, and missing home guides for upcoming bookings."
                      />
                    </th>
                    <th className="uk-width-medium">ACTIONS</th>
                  </tr>
                </thead>
                <tbody>
                  {inboxCtx.loading == true || !inbox
                    ? TableLoading(6)
                    : pagedRecipients.map((recipient) => {
                        let {
                          first_name,
                          last_name,
                          schedule,
                          primary_recipient,
                          email,
                          formatted_phone,
                          users,
                          user_id,
                          invitation,
                        } = recipient;

                        return (
                          <tr>
                            <td>
                              <div className="semi-bold-font">
                                {[first_name, last_name].join(" ")}{" "}
                              </div>
                              <div className="secondary">{email}</div>
                              {!!primary_recipient && (
                                <div className="hms-booking-tag">Admin</div>
                              )}
                              {!primary_recipient && !!+user_id && (
                                <span className="hms-booking-tag">User</span>
                              )}
                              {!+user_id && !invitation && (
                                <div
                                  className="hms-booking-tag"
                                  onClick={() => {
                                    handleInvite(recipient);
                                  }}
                                >
                                  Unregistered
                                </div>
                              )}
                              {!+user_id && !!invitation && (
                                <div className="hms-booking-tag">Invited</div>
                              )}
                              {!!users && (
                                <div className="secondary uk-margin-top">
                                  {"Last Login: " +
                                    moment(users[0].last_login_at).format(
                                      "MM/DD/YYYY hh:mm a",
                                    )}
                                </div>
                              )}
                            </td>
                            <td>
                              <div className="uk-flex uk-flex-wrap">
                                <div className="notification-schd-tag">
                                  <div>SUN</div>
                                  <div>{getSchedule(schedule, 1)}</div>
                                </div>
                                <div className="notification-schd-tag">
                                  <div>MON</div>
                                  <div>{getSchedule(schedule, 2)}</div>
                                </div>
                                <div className="notification-schd-tag">
                                  <div>TUES</div>
                                  <div>{getSchedule(schedule, 3)}</div>
                                </div>
                                <div className="notification-schd-tag">
                                  <div>WED</div>
                                  <div>{getSchedule(schedule, 4)}</div>
                                </div>
                                <div className="notification-schd-tag">
                                  <div>THURS</div>
                                  <div>{getSchedule(schedule, 5)}</div>
                                </div>
                                <div className="notification-schd-tag">
                                  <div>FRI</div>
                                  <div>{getSchedule(schedule, 6)}</div>
                                </div>
                                <div className="notification-schd-tag">
                                  <div>SAT</div>
                                  <div>{getSchedule(schedule, 7)}</div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="autosend-checkbox">
                                <label
                                  htmlFor={recipient.recipient_id}
                                  className="setting-switch"
                                >
                                  <input
                                    type="checkbox"
                                    id={recipient.recipient_id}
                                    onChange={(e) =>
                                      handleAlertToggle(e, recipient)
                                    }
                                    checked={recipient.alerts}
                                  />
                                  <span className="setting-slider setting-round"></span>
                                </label>
                              </div>
                            </td>
                            <td className="hms-action-btn">
                              <div className="uk-flex uk-flex-top uk-flex-between">
                                <div>
                                  {!+user_id &&
                                    !invitation &&
                                    !primary_recipient &&
                                    (!inboxCtx.invitePending ||
                                      recipient.recipient_id !==
                                        rec?.recipient_id) && (
                                      <span
                                        className="hms-team-action"
                                        onClick={() => {
                                          handleInvite(recipient);
                                        }}
                                      >
                                        Invite Again
                                      </span>
                                    )}
                                  {!+user_id &&
                                    !invitation &&
                                    inboxCtx.invitePending &&
                                    recipient.recipient_id ===
                                      rec?.recipient_id && (
                                      <span className="hms-team-action">
                                        Inviting...
                                      </span>
                                    )}
                                  {!+user_id && !!invitation && (
                                    <span>Invited</span>
                                  )}
                                </div>
                                <div className="uk-flex">
                                  <div>
                                    <button
                                      className="uk-button"
                                      uk-toggle="target: #team-add"
                                      onClick={() => handleEdit(recipient)}
                                      uk-tooltip="Edit"
                                    >
                                      <Image src="/images/edit.svg" />
                                    </button>
                                  </div>
                                  {!recipient.primary_recipient && (
                                    <div>
                                      <button
                                        className="uk-button"
                                        uk-toggle="target: #team-delete"
                                        onClick={() => handleDelete(recipient)}
                                        uk-tooltip="Delete"
                                      >
                                        <Image src="/images/delete.svg" />
                                      </button>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                </tbody>
              </table>
            </div>
          </div>
          <Flex alignment="between top" className="guest-form-top-filters">
            <Pagination
              count={recipients.length}
              index={criteria.page}
              onIndexChanged={handleIndexChanged}
            />
            <RecordsPerPage />
          </Flex>

          <div className="hms-small-heading">
            <h3>Team Availability</h3>
          </div>

          <div className="uk-card uk-margin-bottom">
            <div className="uk-overflow-auto">
              <div className="uk-flex uk-flex-middle uk-flex-center team-availability-wrapper">
                <div className="team-availability-time">
                  <div>SUNDAY</div>
                  <div>{getTeamSchedule(1)}</div>
                </div>
                <div className="team-availability-time">
                  <div>MONDAY</div>
                  <div>{getTeamSchedule(2)}</div>
                </div>
                <div className="team-availability-time">
                  <div>TUESDAY</div>
                  <div>{getTeamSchedule(3)}</div>
                </div>
                <div className="team-availability-time">
                  <div>WEDNESDAY</div>
                  <div>{getTeamSchedule(4)}</div>
                </div>
                <div className="team-availability-time">
                  <div>THURSDAY</div>
                  <div>{getTeamSchedule(5)}</div>
                </div>
                <div className="team-availability-time">
                  <div>FRIDAY</div>
                  <div>{getTeamSchedule(6)}</div>
                </div>
                <div className="team-availability-time">
                  <div>SATURDAY</div>
                  <div>{getTeamSchedule(7)}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <TeamAdd />
      <TeamDelete />
    </>
  );
};

export default Team;
