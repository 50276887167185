// Import package components
import { useContext, useState, useEffect, useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";

import { AppContexts } from "../../../providers";

import UIkit from "uikit";
import SMSEmailInputBox from "../../common/sms-email-inputbox";

import utils from "../../../utils/utils.js";

const defaultValues = {
  method: "sms",
  body: "",
  subject: "",
};

const RefundForm = ({ order }) => {
  const [loading, setLoading] = useState(false);
  const [refund, setRefund] = useState({
    order: order,
    items: [],
    reason: "",
  });

  const { createRefund } = useContext(AppContexts.StripeContext);

  const history = useHistory();

  useEffect(() => {
    setRefund({ ...refund, order: order });
  }, [order]);

  const refundableItems = useMemo(() => {
    let refundables = (order?.receipt?.items || [])
      .map((item) => {
        (order.refunds || []).map((r) => {
          let existing = r.items.find((i) => {
            return i.accessory_receipt_item_id === item.id;
          });

          if (!!existing) {
            item.quantity -= existing.quantity;
          }
        });

        return item;
      })
      .filter((item) => {
        return item.quantity > 0;
      })
      .filter((item) => {
        return item.price > 0;
      });

    return refundables;
  }, [order]);

  const orderTotal = useMemo(() => {
    return refundableItems.reduce((a, c) => {
      return a + +c.price * +c.quantity;
    }, 0);
  }, [refundableItems]);

  const handleCreateRefund = async (e) => {
    e.preventDefault();

    if (loading) {
      return;
    }

    setLoading(true);
    let success = await createRefund(refund);
    if (success) {
      setLoading(false);
      UIkit.modal("#modal-refund-order").hide();
      UIkit.notification("Refund has been processed", "success");
      setRefund({
        order: order,
        items: [],
        reason: "",
      });
    }
  };

  const isSelected = (item) => {
    return !!refund.items.find((r) => r.id === item.id);
  };

  const toggleItem = (item) => {
    let existing = refund.items.find((r) => r.id === item.id);

    if (!!existing) {
      refund.items = refund.items.filter((r) => r.id !== item.id);
    } else {
      refund.items.push(item);
    }

    setRefund({ ...refund, items: [...refund.items] });
  };

  const updateReason = (e) => {
    setRefund({ ...refund, reason: e.target.value });
  };

  const handleClose = useCallback(() => {
    UIkit.modal("#modal-refund-order").hide();

    setRefund({
      order: order,
      items: [],
      reason: "",
    });
  }, []);

  return (
    <div
      id="modal-refund-order"
      className="uk-modal-full hms-modals uk-modal uk-open uk-dark"
      uk-offcanvas="flip: true; overlay: true"
    >
      <div className="uk-offcanvas-bar">
        <div className="hms-modals-main">
          <button
            className="uk-offcanvas-close uk-close-large"
            type="button"
            uk-close="true"
          ></button>
          <div className="uk-modal-header">
            <h2 className="uk-modal-title">Issue Refund</h2>
          </div>
          <div className="uk-modal-body">
            <div className="add-contact-form">
              <form
                className="uk-grid-small"
                uk-grid="true"
                onSubmit={handleCreateRefund}
              >
                <div className="uk-width-1-1">
                  <table class="uk-table">
                    <thead>
                      <tr>
                        <th>
                          <input class="uk-checkbox" type="checkbox" disabled />
                        </th>
                        <th>Item</th>
                        <th>Price</th>
                        <th>Quantity</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {refundableItems.length === 0 ? (
                        <tr>
                          <td colspan="5">No refundable items in this order</td>
                        </tr>
                      ) : (
                        (refundableItems || []).map((ri) => {
                          console.log(ri);
                          return (
                            <tr>
                              <td>
                                <input
                                  class="uk-checkbox"
                                  type="checkbox"
                                  checked={isSelected(ri)}
                                  onChange={() => {
                                    toggleItem(ri);
                                  }}
                                />
                              </td>
                              <td>{ri.invoice_item.label}</td>
                              <td>${utils.to_price(ri.price)}</td>
                              <td>{ri.quantity}</td>
                              <td>
                                ${utils.to_price(+ri.quantity * +ri.price)}
                              </td>
                            </tr>
                          );
                        })
                      )}

                      <tr>
                        <td colspan="4" className="total">
                          Total
                        </td>
                        {/*<td className="number">{price(order)}</td>*/}
                        <td colspan="1">${utils.to_price(orderTotal)}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="uk-input-wrapper uk-width-1-1">
                  <label className="uk-label">
                    Reason for Refund<super>*</super>
                  </label>
                  <textarea
                    class="uk-textarea uk-width-1-1"
                    value={refund.reason}
                    rows="5"
                    onInput={updateReason}
                  ></textarea>
                </div>

                <div className="uk-width-1-1 uk-margin-top uk-flex">
                  <button
                    className="uk-button hms-btn hms-red-btn"
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? "Sending..." : "Send"}
                  </button>
                  <button
                    className="uk-button hms-btn uk-offcanvas-close"
                    onClick={handleClose}
                    type="button"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RefundForm;
